import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Modal,
  Form,
  LinkButton,
  Button,
  withModal,
  Message,
  ModalProps,
} from 'components/semantic';
import { toast } from 'components/Toast';
import request, { API_VERSION } from 'utils/request';
import { ClusterInfo, RuntimeType } from './types';
import { useQuota } from './quota';
import styles from './index.module.scss';

interface BaseProps<T extends RuntimeType> {
  onUpdated: (id: number, payload: Partial<ClusterInfo<T>>) => void;
  instanceData: ClusterInfo<T>;
  isTest?: boolean;
}

type Props = BaseProps<'redis'> | BaseProps<'mongo'> | BaseProps<'es'>;

const FORM_ID = 'db';

export const ScaleModal = withModal<Props>()((props) => {
  const { instanceData, onUpdated, isTest = false, close } = props;
  const { runtime } = instanceData;
  const { t } = useTranslation();
  const [quota, { standardQuotaRadioGroup, testQuotaRadioGroup, testQuotas }] = useQuota(
    instanceData.runtime,
    instanceData.nodeQuota
  );
  const isES = runtime === 'es';
  const isRedis = runtime === 'redis';

  const testEnabled = isES ? isTest : true;
  const standEnabled = isES ? !isTest : true;

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      if (quota === undefined) {
        throw new Error('nodeQuota is required');
      }
      setLoading(true);
      const clusterInfo = await request(
        `/${API_VERSION}/leandb/clusters/${instanceData.id}/quota`,
        {
          method: 'PUT',
          body: { nodeQuota: quota },
        }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onUpdated && onUpdated(instanceData.id, clusterInfo as any);
      setLoading(false);
      toast.success(t('db.redis.resizeCapacity.successfully'));
      close();
    } catch (error) {
      toast.error(t('db.redis.resizeCapacity.failed'), error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal.Header
        content={
          <Trans
            values={{
              type: t(`db.${instanceData.runtime}`),
            }}
            i18nKey={'db.redis.resizeCapacity'}
          />
        }
      />
      <Modal.Content scrolling>
        <Form id={FORM_ID} onSubmit={onSubmit}>
          <Form.Field>
            <label>{t('label.name')}</label>
            <code className={styles.instanceName}>{instanceData.name}</code>
          </Form.Field>
          {!!testQuotas.length && (
            <Form.Field disabled={!testEnabled}>
              <label htmlFor="max_test_memory">{t('db.quota.test')}</label>
              {testQuotaRadioGroup}
            </Form.Field>
          )}

          <Form.Field required={isRedis} disabled={!standEnabled}>
            <label htmlFor="max_memory">
              {isRedis ? t('db.redis.instanceCapacity') : t('db.quota.standard')}
            </label>
            {standardQuotaRadioGroup}
          </Form.Field>
        </Form>

        <Message warning>
          <Message.Content>
            <Message.List>
              {isES && <Message.Item content={t('db.scale.hint.es')} />}
              <Message.Item
                content={t(isRedis ? `db.scaleHint.redis.caution` : `db.scaleHint.caution`)}
              />
              <Message.Item content={t(`db.scaleHint.${runtime}`)} />
              {runtime !== 'redis' && <Message.Item content={t('db.instance.quotaHint')} />}
            </Message.List>
          </Message.Content>
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton onClick={close} content={t('action.cancel')} />
        <Button
          primary
          type="submit"
          form={FORM_ID}
          content={t(`action.confirmBilling`)}
          loading={loading}
        />
      </Modal.Actions>
    </>
  );
});

export const ScaleModalWithTrigger: React.FC<Props & { modalProps?: ModalProps }> = ({
  modalProps,
  ...rest
}) => {
  const { t } = useTranslation();
  const { instanceData } = rest;
  const isRunning = instanceData.status === 'running';
  const trigger = <Button disabled={!isRunning} content={t('action.scale')} size="mini" />;
  return <ScaleModal {...rest} modalProps={{ trigger, ...modalProps }} />;
};

export default ScaleModalWithTrigger;
