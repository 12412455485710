import _ from 'lodash';
import { TimeSerialData } from 'components/StatisticView';
import { RequestOptions, API_VERSION } from 'utils/request';
import { useAPI } from 'utils/use-api';
import { useAppId } from '../..';

export interface MetricData {
  tags: {
    status?: string;
    instanceName?: string;
    functionName?: string;
  };
  dps: {
    [key: string | number]: number | null;
  };
}

export const transformMetricData = (
  metricData?: MetricData[],
  valueTransform?: (value: number | null) => number | null,
  groupBy: keyof MetricData['tags'] = 'status'
): TimeSerialData[] => {
  if (!metricData) {
    return [];
  }
  const data: {
    [key: string]: {
      [key: string]: number | null;
    };
  } = {};
  metricData.forEach(({ tags, dps }) => {
    Object.entries(dps).forEach(([key, value]) => {
      _.setWith(
        data,
        [key, tags[groupBy] || 'DEFAULT'],
        valueTransform ? valueTransform(value) : value,
        Object
      );
    });
  });
  const now = Date.now();
  return Object.entries(data).map(([key, metrics]) => {
    const timestamp = Number(key) * 1000;
    const time = new Date(timestamp);
    return [time, timestamp >= now ? {} : metrics];
  });
};

export const useMetric = ({
  groupName,
  metric,
  start,
  end,
  query,
}: {
  groupName: string;
  metric: string;
  start?: Date;
  end?: Date;
  query?: RequestOptions['query'];
}) => {
  const appId = useAppId();
  return useAPI<MetricData[]>(
    `/${API_VERSION}/engine/stats/${metric}`,
    {
      query: {
        start,
        end,
        groupName,
        ...query,
      },
      appId,
    },
    [appId, start, end, groupName, query],
    !!start && !!end
  );
};

export interface GatewayData {
  tags: {
    metric: 'tx' | 'rx' | 'connected' | 'disconnected' | 'active';
    instanceName?: string;
  };
  dps: {
    [key: string]: number | null;
  };
}

export enum GatewayMetricName {
  'tcpTraffic' = 'tcp-traffic',
  'tcpConnections' = 'tcp-connections',
  'tcpConcurrency' = 'tcp-concurrency',
  'udpTraffic' = 'udp-traffic',
  'udpDatagrams' = 'udp-datagrams',
  'udpSessions' = 'udp-sessions',
  'udpConcurrency' = 'udp-concurrency',
}

export const useGatewayMetric = ({
  groupName,
  name,
  from,
  to,
  query,
}: {
  groupName: string;
  name: GatewayMetricName;
  from?: Date;
  to?: Date;
  query?: RequestOptions['query'];
}) => {
  const appId = useAppId();
  return useAPI<GatewayData[]>(
    `/${API_VERSION}/engine/Gateway/metrics/${name}`,
    {
      query: {
        from,
        to,
        groupName,
        ...query,
      },
      appId,
    },
    [appId, from, to, groupName, name, query],
    !!from && !!to
  );
};
