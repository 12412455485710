import { createLazyResourceStore } from '@leancloud/use-resource';
import { useAPI, initialResource } from 'utils/use-api';
import { useUser } from './User';
import { BILLING_CENTER_PREFIX } from 'utils/request';

type AccountMoney = {
  // new billing center
  amount: number;
  balance: number;
  client_id: number;
  currency: 'CNY' | 'USD';
  flags: number;
  gift: number;
  id: number;
  updated: { __type: 'Date'; iso: string };
};

const { useResource: useAccountMoney, Provider } = createLazyResourceStore(
  ({ useTriggered }) =>
    () => {
      const [user] = useUser(false);
      return useAPI<AccountMoney>(
        `${BILLING_CENTER_PREFIX}/payment/money-account`,
        undefined,
        [user],
        useTriggered() && user !== undefined
      );
    },
  initialResource
);
export { useAccountMoney, Provider };
