import { ComponentType, FunctionComponent, ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Component = ComponentType | [ComponentType<any>, { [key: string]: any }];

const composeNodes = (components: Component[], children: ReactNode) =>
  [...components].reverse().reduce((acc, curr) => {
    const [Comp, props] = Array.isArray(curr) ? [curr[0], curr[1]] : [curr, {}];
    return <Comp {...props}>{acc}</Comp>;
  }, children);

export const compose = (components: Component[]): FunctionComponent =>
  function ComposedComponent({ children }) {
    return <>{composeNodes(components, children)}</>;
  };

export const Compose: FunctionComponent<{
  components: Component[];
}> = ({ components, children }) => {
  return <>{composeNodes(components, children)}</>;
};
