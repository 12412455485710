import React, { useCallback, useEffect, useMemo, useState, useRef, KeyboardEvent } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {
  DateInput as OriginalDateInput,
  DateInputProps as OriginalDateInputProps,
  DateTimeInput as OriginalDateTimeInput,
  TimeInput as OriginalTimeInput,
  TimeInputProps as OriginalTimeInputProps,
} from 'semantic-ui-calendar-react';
import { formatTime, getCurrentUtcOffset, MomentTimeFormat, TimeFormat } from 'utils';
import { Icon, Input, InputProps } from '../semantic';
import styles from './index.module.scss';

const isInvalidDate = (date: Date) => isNaN(date.getTime());

const useDateInput = ({
  format = TimeFormat.EDITABLE,
  id,
  value,
  required,
  onChange,
  placeholder,
  clearable,
  minDate,
  maxDate,
}: {
  format?: MomentTimeFormat;
  id?: string;
  value?: Date;
  required?: boolean;
  onChange?: (data: { value?: Date }) => void;
  placeholder?: string;
  clearable?: boolean;
} & Pick<OriginalDateInputProps, 'maxDate' | 'minDate'>) => {
  const formatFn = useCallback((time?: Date) => (time ? formatTime(time, format) : ''), [format]);
  const [inputValue, setInputValue] = useState(formatFn(value));
  const [active, setActive] = useState(false);

  const node = useRef<HTMLDivElement>(null);
  const handleClick: EventListener = (e) => {
    if (node.current && e.target && !node.current.contains(e.target as Element)) {
      setActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    setInputValue(formatFn(value));
  }, [value, formatFn]);

  const max = maxDate ? moment(maxDate).toDate() : undefined;
  const min = minDate ? moment(minDate).toDate() : undefined;

  const onFocus = useCallback(() => setActive(true), []);
  const onInputChange: InputProps['onChange'] = useCallback(
    (e, data) => setInputValue(data.value),
    []
  );
  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.relatedTarget) {
        setActive(false);
      }
      let newValue;
      if (e.target.value.trim() === '') {
        onChange?.({ value: undefined });
        return;
      }
      newValue = moment(e.target.value, format).toDate();
      if (isInvalidDate(newValue)) {
        newValue = new Date(Number(e.target.value));
      }
      if (isInvalidDate(newValue)) {
        return setInputValue(formatFn(value));
      } else {
        if (value && newValue.getTime() === value.getTime()) {
          return;
        }
        if (max && max < newValue) {
          newValue = max;
        }
        if (min && min > newValue) {
          newValue = min;
        }
        setInputValue(formatFn(newValue));
        onChange?.({ value: newValue });
      }
    },
    [format, formatFn, max, min, onChange, value]
  );
  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 13 && active) {
        e.stopPropagation();
        e.preventDefault();
        setActive(false);
        (e.target as HTMLInputElement).blur();
      }
    },
    [active]
  );

  const clearIcon = useMemo(
    () => (
      <Icon
        name="remove"
        link
        color="black"
        onClick={() =>
          onChange?.({
            value: undefined,
          })
        }
      />
    ),
    [onChange]
  );

  const inputProps = {
    id,
    value: inputValue,
    required,
    onFocus,
    onChange: onInputChange,
    onBlur,
    onKeyDown,
    className: active ? 'focus' : '',
    icon: clearable && value ? clearIcon : 'calendar alternate outline',
    placeholder,
  };

  const dateInputDefaultProps = {
    marked: [new Date()],
    markColor: 'blue' as const,
    localization: 'zh-cn',
  };

  const onDateInputChange: OriginalDateInputProps['onChange'] = useCallback(
    (e, data) => {
      const newValue = moment(data.value, format).toDate();
      setInputValue(formatFn(newValue));
      setActive(false);
      if (onChange) {
        onChange({ value: newValue });
      }
    },
    [format, formatFn, onChange]
  );

  const dateInputInternalProps = {
    inline: true,
    dateFormat: TimeFormat.EDITABLE,
    value: formatFn(value),
    onChange: onDateInputChange,
  };

  return [
    node,
    active,
    inputProps,
    dateInputDefaultProps,
    dateInputInternalProps,
    [formatFn, [inputValue, setInputValue], [active, setActive]],
  ] as const;
};

const useTimeInput = ({
  id,
  value,
  required,
  onChange: propsOnChange,
  placeholder,
  clearable,
}: {
  id?: string;
  value?: Date;
  required?: boolean;
  onChange?: (data: { value?: Date }) => void;
  placeholder?: string;
  clearable?: boolean;
}) => {
  const format = TimeFormat.EDITABLE_TIME;
  const onChange = useCallback(
    (data: { value?: Date }) => {
      if (!propsOnChange) {
        return;
      }
      if (!data.value) {
        propsOnChange(data);
      } else {
        const newValue = moment(value)
          .utcOffset(getCurrentUtcOffset())
          .set({
            hour: data.value.getHours(),
            minute: data.value.getMinutes(),
            second: data.value.getSeconds(),
          })
          .toDate();
        propsOnChange({
          value: newValue,
        });
      }
    },
    [propsOnChange, value]
  );

  const [
    node,
    active,
    inputProps,
    ,
    dateInputInternalProps,
    [formatFn, [, setInputValue], [, setActive]],
  ] = useDateInput({ format, id, value, required, onChange, clearable, placeholder });

  const timeInputClickCounter = useRef(0);
  useEffect(() => {
    if (!active) {
      timeInputClickCounter.current = 0;
    }
  }, [active]);

  const timeInputDefaultProps = {
    localization: 'zh-cn',
  };

  const onTimeInputChange: OriginalTimeInputProps['onChange'] = (e, data) => {
    const newValue = moment(data.value, format).toDate();
    setInputValue(formatFn(newValue));
    timeInputClickCounter.current++;
    if (timeInputClickCounter.current % 2 === 0) {
      setActive(false);
    }
    if (onChange) {
      onChange({ value: newValue });
    }
  };

  const timeInputInternalProps = {
    ...dateInputInternalProps,
    onChange: onTimeInputChange,
  };

  return [
    node,
    active,
    {
      ...inputProps,
      icon: typeof inputProps.icon === 'string' ? 'time' : inputProps.icon,
    },
    timeInputDefaultProps,
    timeInputInternalProps,
  ] as const;
};

interface DateInputProps
  extends Pick<OriginalDateInputProps, 'maxDate' | 'minDate' | 'disable' | 'modes'> {
  id?: string;
  value?: Date;
  required?: boolean;
  onChange?: (data: { value?: Date }) => void;
  placeholder?: string;
  clearable?: boolean;
  rightAligned?: boolean;
  format?: MomentTimeFormat;
}

export function DateInput({
  id,
  value,
  onChange,
  required,
  placeholder,
  clearable,
  rightAligned,
  format,
  maxDate,
  minDate,
  ...bypassedProps
}: DateInputProps) {
  const [node, active, inputProps, dateInputDefaultProps, dateInputInternalProps] = useDateInput({
    format,
    id,
    value,
    required,
    onChange,
    placeholder,
    clearable,
    minDate,
    maxDate,
  });

  return (
    <div ref={node} className={styles.dateTimeInputWrapper}>
      <Input {...inputProps} />
      {active && (
        <div
          className={classNames(styles.dateTimeInputSelector, {
            [styles.rightAligned]: rightAligned,
          })}
        >
          <OriginalDateInput
            {...dateInputDefaultProps}
            {...bypassedProps}
            minDate={minDate}
            maxDate={maxDate}
            {...dateInputInternalProps}
          />
        </div>
      )}
    </div>
  );
}

export function DateTimeInput({
  id,
  value,
  required,
  onChange,
  placeholder,
  clearable,
  rightAligned,
  ...bypassedProps
}: DateInputProps) {
  const [node, active, inputProps, dateInputDefaultProps, dateInputInternalProps] = useDateInput({
    format: TimeFormat.EDITABLE_DATETIME,
    id,
    value,
    required,
    onChange,
    placeholder,
    clearable,
  });

  return (
    <div ref={node} className={styles.dateTimeInputWrapper}>
      <Input {...inputProps} />
      {active && (
        <div
          className={classNames(styles.dateTimeInputSelector, {
            [styles.rightAligned]: rightAligned,
          })}
        >
          <OriginalDateTimeInput
            {...dateInputDefaultProps}
            {...bypassedProps}
            {...dateInputInternalProps}
          />
        </div>
      )}
    </div>
  );
}

interface TimeInputProps {
  id?: string;
  value?: Date;
  required?: boolean;
  onChange?: (data: { value?: Date }) => void;
  placeholder?: string;
  clearable?: boolean;
  rightAligned?: boolean;
  className?: string;
}
export function TimeInput({
  id,
  value,
  required,
  onChange,
  placeholder,
  clearable,
  rightAligned,
  className,
  ...bypassedProps
}: TimeInputProps) {
  const [node, active, inputProps, timeInputDefaultProps, timeInputInternalProps] = useTimeInput({
    id,
    value,
    required,
    onChange,
    placeholder,
    clearable,
  });
  return (
    <div ref={node} className={classNames(styles.dateTimeInputWrapper, className)}>
      <Input {...inputProps} />
      {active && (
        <div
          className={classNames(styles.dateTimeInputSelector, {
            [styles.rightAligned]: rightAligned,
          })}
        >
          <OriginalTimeInput
            {...timeInputDefaultProps}
            {...bypassedProps}
            {...timeInputInternalProps}
          />
        </div>
      )}
    </div>
  );
}
