import React from 'react';
import { TooltipProps } from 'recharts';
import { numberToStr } from 'utils';

const defaultFormat = (value: number) => {
  const result = numberToStr(value);
  return typeof result === 'number' ? `${result}` : result;
};
export function useTooltipLabel({
  sum,
  format = defaultFormat,
}: {
  sum?: number;
  format?: (value: number) => string;
}) {
  return ({ active, payload }: TooltipProps) => {
    if (!active || !payload || payload.length === 0) {
      return null;
    }
    const name = payload[0].payload.name;
    const value = Number(payload[0].payload.value);
    const percent = sum && ((value / sum) * 100).toFixed(2);
    const fill = payload[0].fill || payload[0].payload?.fill;
    return (
      <div
        className="recharts-default-tooltip"
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          whiteSpace: 'nowrap',
        }}
      >
        <p className="recharts-tooltip-label" style={{ margin: 0 }}>
          {name}
        </p>
        <ul className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0 }}>
          <li
            className="recharts-tooltip-item"
            style={{
              display: 'block',
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <span
              className="recharts-tooltip-item-value"
              style={{
                fontSize: '1.6rem',
                color: fill,
              }}
            >
              {format(value)}
            </span>
            {percent !== undefined && <span> ({percent}%)</span>}
          </li>
        </ul>
      </div>
    );
  };
}
