import React, { useEffect, useCallback, FunctionComponent } from 'react';
import { createLazyResourceStore, useTransform } from '@leancloud/use-resource';
import { verifiedClientEnabled } from 'config/feature-flags';
import { TDS } from 'env';
import { noop } from 'lodash';
import { resetCSRFToken, resetXSRFToken, CLIENT_CENTER_VERSION } from 'utils/request';
import { useAPI, initialResource, useLocalState } from 'utils/use-api';

export enum VerifyState {
  NONE = -1,
  PENDING = 0,
  VERIFIED = 1,
  REJECTED = 2,
}

export interface User {
  acceptEmail: boolean;
  clientVerification: VerifyState;
  company: string | null;
  created: Date;
  email: string;
  flags: string[];
  id: number;
  phone: string;
  thirdParty: null;
  thirdpartyProvider: null;
  thirdpartyUid: null;
  title: null;
  username: string;
  internal?: boolean;

  // extend
  verifiedClient?: boolean;
  verifiedEmail?: boolean;
  verifiedPhone?: boolean;
}

const [, extra] = initialResource;

const parseUserStatus = (user?: User) => {
  if (!user) {
    return;
  }
  return {
    ...user,
    verifiedClient: verifiedClientEnabled ? user.clientVerification === 1 : true,
    verifiedEmail: user.flags.includes('client-email-verified'),
    verifiedPhone: user.phone && user.flags.includes('client-phone-verified'),
  } as User;
};

const {
  useResource: useUser,
  reset: resetUser,
  Provider,
} = createLazyResourceStore(
  ({ useTriggered }) =>
    () => {
      const triggered = useTriggered();
      // TDS should alaways get the user from the login request initialized in Routes.TDS.tsx
      const pending = !triggered || TDS;
      const [user, { setData, ...userExtra }] = useLocalState(
        useTransform(
          useAPI<User>(
            pending ? undefined : `/client-center/${CLIENT_CENTER_VERSION}/clients/self`
          ),
          parseUserStatus
        )
      );
      const update = useCallback(
        (value: Partial<User>) => {
          setData((preUser) => {
            if (!preUser) {
              return;
            }
            return {
              ...preUser,
              ...value,
            };
          });
        },
        [setData]
      );
      return [
        user,
        {
          update,
          setData,
          ...userExtra,
        },
      ] as const;
    },
  [undefined, { ...extra, setData: noop, update: noop }]
);

const XSRFTokenRefresher = () => {
  const [user] = useUser(false);
  const id = user?.id;
  useEffect(() => {
    if (id) {
      resetCSRFToken();
      resetXSRFToken();
    }
  }, [id]);
  return null;
};

const UserProvider: FunctionComponent = ({ children }) => {
  return (
    <Provider>
      {!TDS && <XSRFTokenRefresher />}
      {children}
    </Provider>
  );
};

export { useUser, resetUser, UserProvider as Provider };
