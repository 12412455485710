import React from 'react';
import { PopupProps } from 'semantic-ui-react';
import { Icon, Popup, SemanticCOLORS, SemanticICONS } from '../semantic';
import styles from './index.module.scss';

export const HINT_ICON = 'question circle';

const Hint = ({
  icon = HINT_ICON,
  color,
  trigger = <Icon name={icon} color={color} />,
  content,
  position,
  hoverable,
}: {
  icon?: SemanticICONS;
  color?: SemanticCOLORS;
  trigger?: React.ReactElement;
  content: React.ReactNode;
  position?: PopupProps['position'];
  hoverable?: PopupProps['hoverable'];
}) => <Popup trigger={trigger} content={content} position={position} hoverable={hoverable} wide />;

export default Hint;

const HintedContent = ({
  trigger,
  content,
  inline,
  ...props
}: PopupProps & {
  inline?: boolean;
}) =>
  content ? (
    <Popup
      trigger={<span className={inline ? styles.inline : styles.block}>{trigger}</span>}
      content={content}
      {...props}
    />
  ) : (
    <span className={inline ? styles.inline : styles.block}>{trigger}</span>
  );

export { HintedContent };
