import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';
import { formatTime, TimeFormat } from 'utils';
import NoData from 'components/NoData';
import Placeholder from 'components/Placeholder';
import { withModal, Modal, Button, Table, Popup } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { API_VERSION } from 'utils/request';
import { useAPI, useSmoothReload } from 'utils/use-api';
import styles from './index.module.scss';
import { ClusterInfo } from './types';
import { useInterval } from 'react-use';
import RelativeTime from 'components/RelativeTime';

type ExportStatus =
  | {
      status: 'failed';
      message: string;
    }
  | {
      status: 'success';
      finishedAt: string;
      url: string;
    }
  | {
      status: 'running';
    };

type ExportData = {
  createdAt: string;
  runtime: 'mongo' | 'redis' | 'mysql' | 'udb';
  name: string;
} & ExportStatus;

export const ExportLeanDB = withModal<{ instance: ClusterInfo }>()(({ close, instance }) => {
  const { t } = useTranslation();
  const [exportDataList, { reload, loading }] = useSmoothReload(
    useAPI<ExportData[]>(`/${API_VERSION}/leandb/clusters/${instance.id}/export`)
  );

  const exportDB = useCallback(() => {
    request<void>(`/${API_VERSION}/leandb/clusters/${instance.id}/export`, {
      method: 'POST',
    })
      .then(() => {
        reload();
      })
      .catch((err) => {
        toast.error(t('action.export.failed'), err);
      });
  }, [instance.id, reload, t]);

  const hasExporting = useMemo(
    () => exportDataList && exportDataList.filter((data) => data.status === 'running').length > 0,
    [exportDataList]
  );

  useInterval(reload, hasExporting ? 1000 * 5 : null);

  return (
    <>
      <Modal.Header
        content={
          <Trans
            i18nKey="db.instance.export"
            values={{
              name: instance.name,
            }}
            components={{
              code: <code />,
            }}
          />
        }
      />
      <Modal.Content scrolling>
        {loading && !exportDataList && <Placeholder line={4} />}
        {exportDataList &&
          (exportDataList.length ? (
            <>
              <Table borderless loading={loading} className={styles.table}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell content={t('db.instance.export.createdAt')} />
                    <Table.HeaderCell content={t('label.status')} />
                    <Table.HeaderCell content={t('label.operation')} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {exportDataList.reverse().map((data) => {
                    return (
                      <Table.Row key={`${data.name}-${data.createdAt}`}>
                        <Table.Cell>
                          {formatTime(data.createdAt, TimeFormat.DISPLAY_DATETIME)}
                        </Table.Cell>
                        <Table.Cell>
                          {data.status === 'running' && (
                            <Label basic color="orange" content={t('action.export.running')} />
                          )}
                          {data.status === 'failed' && (
                            <>
                              <Label basic color="red" content={t('action.export.failed')} />
                              <p className="help-block text-danger">{data.message}</p>
                            </>
                          )}
                          {data.status === 'success' && (
                            <>
                              <Label
                                basic
                                color="green"
                                content={t('action.export.successfully')}
                              />
                              <p className="help-block">
                                {t('db.instance.export.finishAt')}{' '}
                                <Popup
                                  hoverable
                                  content={formatTime(data.finishedAt, TimeFormat.DISPLAY_DATETIME)}
                                  trigger={
                                    <span>
                                      <RelativeTime value={data.finishedAt} />
                                    </span>
                                  }
                                />
                              </p>
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            as="a"
                            icon="download"
                            size="small"
                            disabled={data.status !== 'success'}
                            content={t('action.download')}
                            href={data.status === 'success' ? data.url : ''}
                            target="_blank"
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              <p className="help-block">{t('db.instance.export.tip')}</p>
            </>
          ) : (
            <NoData
              icon="cloud download alternate"
              header={
                <Trans
                  i18nKey="db.instance.noExport"
                  values={{
                    name: instance.name,
                  }}
                  components={{
                    code: <code />,
                  }}
                />
              }
              extra={<Button content={t('action.export')} onClick={exportDB} primary />}
            />
          ))}
      </Modal.Content>
      <Modal.Actions>
        {exportDataList && exportDataList.length > 0 && (
          <Button content={t('action.reExport')} floated="left" onClick={exportDB} />
        )}
        <Button type="button" content={t('action.close')} onClick={close} />
      </Modal.Actions>
    </>
  );
});
