import Loadable from 'react-loadable';
import Loading from './Loading';

export default <T>(
  loader: () => Promise<{ default: React.ComponentType<T> }>,
  loading = Loading,
  timeout = 10000
) =>
  Loadable<T, object>({
    loader,
    loading,
    timeout,
  });
