import TDSClient from '@taptap/tds-msg/es/client';
import { Action } from 'history';

const TDS_DOMAIN = process.env.REACT_APP_TDS_DOMAIN;
if (!TDS_DOMAIN) {
  throw new Error('Failed to init TDSClient: TDS_DOMAIN not configured.');
}
const client = new TDSClient('*');
const changeDCRoute = (path: string, action: Action) => {
  client.sendMsg({
    path,
    action,
  });
};

export { client, changeDCRoute };
