import { API_VERSION } from 'utils/request';
import { createLazyResourceStore, useTransform } from '@leancloud/use-resource';
import _ from 'lodash';
import { useAPI, initialResource } from 'utils/use-api';
import { useAppId } from '.';
import { IM_HOOKS, VERIFY_HOOKS_TYPE, VERIFY_HOOKS } from './Engine/Deploy/constant';

interface HookRaw {
  cloudFunction: string;
  groupName: string;
  prod: 0 | 1;
}

export interface Hook extends HookRaw {
  type: 'custom' | 'IM' | 'class' | 'verify';
  hookName: string;
  className?: string;
}

const decodeHook = (name: string): Pick<Hook, 'className' | 'type' | 'hookName'> => {
  if (IM_HOOKS.includes(name)) {
    return {
      hookName: name,
      type: 'IM',
    };
  }
  if (name.startsWith('__')) {
    if (name.includes('_for_')) {
      const tmpArray = name.split('_for_');
      return {
        hookName: _.camelCase(tmpArray[0]),
        className: tmpArray[1],
        type: 'class',
      };
    }
    if (name === '__on_login__User') {
      // 按照 _User 表的一个 普通 hook 处理
      return {
        type: 'class',
        hookName: 'onLogin',
        className: '_User',
      };
    }
    if (name === '__on_authdata__User') {
      // 按照 _User 表的一个 普通 hook 处理
      return {
        type: 'class',
        hookName: 'onAuthData',
        className: '_User',
      };
    }
  }

  const tmpName = _.camelCase(name);
  const verifyHookType = VERIFY_HOOKS_TYPE.find((type) => {
    return `${VERIFY_HOOKS}${_.capitalize(type)}` === tmpName;
  });

  if (verifyHookType) {
    return {
      type: 'verify',
      hookName: _.camelCase(tmpName),
    };
  }

  return {
    hookName: name,
    type: 'custom',
  };
};

const transformHook = (hooks?: HookRaw[]) => {
  if (!hooks) {
    return [];
  }
  return hooks.map(
    (hook) =>
      ({
        ...hook,
        ...decodeHook(hook.cloudFunction),
      } as Hook)
  );
};

const { useResource: useEngineHooks, Provider } = createLazyResourceStore(
  ({ useTriggered }) =>
    () => {
      const appId = useAppId();
      return useTransform(
        useAPI<HookRaw[]>(
          useTriggered() ? `/${API_VERSION}/engine/cloudFunctions` : undefined,
          {
            appId,
          },
          [appId]
        ),
        transformHook
      );
    },
  [[], initialResource[1]]
);

export { useEngineHooks, Provider };
