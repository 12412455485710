import { SemanticCOLORS } from 'components/semantic';
type RawDate = string;
export type MaxMemoryPolicy =
  | 'noeviction'
  | 'allkeys-lru'
  | 'volatile-lru'
  | 'allkeys-random'
  | 'volatile-random'
  | 'volatile-ttl';
export interface RedisOptions {
  maxMemoryPolicy: MaxMemoryPolicy;
}
export interface MongoOptions {}
export interface ESOptions {
  dataNodes: number;
}
export interface UDBOptions {}

type ClusterOptions = {
  redis: RedisOptions;
  mongo: MongoOptions;
  es: ESOptions;
  mysql: UDBOptions;
};

export type RuntimeType = keyof ClusterOptions;

export type ClusterInfo<T extends RuntimeType = RuntimeType> = (T extends keyof ClusterOptions
  ? ClusterOptions[T]
  : never) & {
  id: number;
  appId: string;
  name: string;
  runtime: T;
  nodeQuota: string;
  storageQuota: string;
  status:
    | 'initializing'
    | 'starting'
    | 'running'
    | 'updating'
    | 'recovering'
    | 'stopped'
    | 'failed';
  authUser: string;
  authPassword: string;
  proxyHost: string;
  proxyPort: number;
  createdAt: RawDate;
  updatedAt: RawDate;
  versionTag: string;
};

export function isType<T extends RuntimeType>(type: T) {
  return function (cluster: ClusterInfo<RuntimeType>): cluster is ClusterInfo<T> {
    return cluster.runtime === type;
  };
}

export function isTypes<T extends RuntimeType[]>(types: T) {
  return function (cluster: ClusterInfo<RuntimeType>): cluster is ClusterInfo<T[number]> {
    return types.includes(cluster.runtime);
  };
}

const OPERATIONAL = 'green';
const WARNING = 'orange';
const ERROR = 'red';

export const CLUSTER_STATUS_COLOR: {
  [key in ClusterInfo['status']]: SemanticCOLORS;
} = {
  initializing: WARNING,
  starting: WARNING,
  running: OPERATIONAL,
  updating: WARNING,
  recovering: ERROR,
  stopped: ERROR,
  failed: ERROR,
};

type NodeQuotaOptions = {
  redis: {};
  mongo: {
    cpuShares: number;
  };
  es: {
    dataNodes: number;
  };
  mysql: {};
};

export type NodeQuotaInfos<T extends RuntimeType = RuntimeType> = NodeQuotaOptions[T] & {
  id: string;
  basePrice: number;
  memory: number;
  availableStorageQuotas: string[];
  forDevUse: boolean;
};

export interface StorageQuotaInfo {
  id: string;
  storage: number;
  basePrice: number;
  // type: 'hdd'; // only for mongo
}

export interface RuntimeInfo<T extends RuntimeType> {
  nodeQuotas: Array<NodeQuotaInfos<T>>;
  storageQuotas: StorageQuotaInfo[];
  availableQuotas: Array<string>;
  availableVersions: string[];
}

export type RuntimeInfos = {
  [type in RuntimeType]: RuntimeInfo<type>;
};

export interface MetricData<T = string> {
  tags: {
    metric: T;
  };
  dps: {
    [key: string]: number | null;
  };
}
