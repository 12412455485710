import React from 'react';
import { TDSStyle } from 'env';
import { Loader } from 'components/semantic';
import { ReactComponent as Logo } from './logo.svg';
import styles from './styles.module.scss';

export default () => (
  <div className={styles.loadingPage}>
    {/*
     * As a part of the splash, the SVG logo is embedded on purpose.
     * In development build, You may see a styleless splash screen
     * as the styles are injected asynchronously, which is not the case in production build.
     */}
    {!TDSStyle && <Logo className={styles.logo} />}
    <Loader active size="small" inline />
  </div>
);
