import React, { useContext, useCallback } from 'react';
import { createLazyResourceStore } from '@leancloud/use-resource';
import _ from 'lodash';
import { initialResource, useTransform, useSmoothReload, Resource } from 'utils/use-api';
import { useUser } from 'App/User';
import { API_VERSION } from 'utils/request';
import { EngineGroup, GroupExtended, DeployMode, EngineImage } from './Engine/types';
import { useEngineAPI } from './Engine/utils';

const groupsTransform = (groups?: EngineGroup[]) => {
  if (!groups) {
    return groups;
  }

  return _(groups)
    .filter((group) => !(group.groupName === 'staging' && group.prod === 0))
    .sortBy((group) => group.groupName)
    .value();
};

const { useResource: useAllGroups, Provider } = createLazyResourceStore(
  ({ useTriggered }) =>
    () => {
      const [user] = useUser();
      return useTransform(
        useSmoothReload(
          useEngineAPI<EngineGroup[]>(
            `/${API_VERSION}/engine/groups?all=true`,
            undefined,
            [user],
            useTriggered() && user !== undefined
          )
        ),
        groupsTransform
      );
    },
  initialResource
);

const useGroups = (includeInternal = false) =>
  useTransform(
    useAllGroups(),
    useCallback(
      (groups) =>
        includeInternal ? groups : groups?.filter((group) => !group.groupName.startsWith('_')),
      [includeInternal]
    )
  );

export { useGroups, Provider };

export function getDeployModeByImage(image: EngineImage): DeployMode {
  const { projectType, version } = image;
  if (projectType === 'snippet') {
    return 'online';
  }
  if (version && version.indexOf('local') > -1) {
    return 'cli';
  } else {
    return 'git';
  }
}

export const useGroup = (groupName: string) => {
  return useTransform(
    useGroups(true),
    useCallback(
      (groups) => {
        let currentGroup = groups?.find((item) => item.groupName === groupName) as
          | GroupExtended
          | undefined;
        if (currentGroup && currentGroup.production && currentGroup.staging) {
          let deployMode: DeployMode | undefined;
          const {
            production: { version: prodVersion },
            staging: { version: stgVersion },
          } = currentGroup;
          if (prodVersion) {
            deployMode = getDeployModeByImage(prodVersion);
          } else {
            if (stgVersion) {
              deployMode = getDeployModeByImage(stgVersion);
            }
          }
          currentGroup = {
            ...currentGroup,
            deployMode,
          };
        }
        return currentGroup;
      },
      [groupName]
    )
  );
};

const CurrentGroupContext = React.createContext<Resource<GroupExtended>>(
  initialResource as unknown as Resource<GroupExtended>
);
export const CurrentGroupProvider = CurrentGroupContext.Provider;
export const useCurrentGroup = () => useContext(CurrentGroupContext);
