import React, { forwardRef, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { flatten } from 'lodash';
import { ErrorBoundary } from 'components/ErrorBoundary';
import styles from './index.module.scss';

export const Page = forwardRef<
  HTMLDivElement,
  {
    className?: string;
    title?: string | string[];
    children?: ReactNode;
  }
>(({ children, className, title }, forwardedRef) => {
  const clasName = classNames(styles.page, className);
  return (
    <ErrorBoundary>
      {title && (
        <Helmet>
          <title>{flatten([title]).join(' · ')}</title>
        </Helmet>
      )}
      <div className={clasName} ref={forwardedRef}>
        {children}
      </div>
    </ErrorBoundary>
  );
});

// const getBreadcrumb = (breadcrumb?: string | string[]) =>
//   breadcrumb ? (
//     <Breadcrumb
//       size="tiny"
//       sections={flatten([breadcrumb]).map(text => ({
//         key: text,
//         content: text,
//       }))}
//     />
//   ) : (
//     undefined
//   );

const PageHeader: React.FunctionComponent<{
  title: string;
  titleNode?: ReactNode;
  breadcrumb?: string | string[];
  subtitle?: ReactNode;
  meta?: ReactNode;
}> = React.memo(({ title, titleNode = title, breadcrumb, subtitle, meta }) => {
  const documentTitleParts = [title];
  if (breadcrumb) {
    const root = flatten([breadcrumb])[0];
    if (root) {
      documentTitleParts.push(root);
    }
  }
  const documentTitle = documentTitleParts.join(' · ');
  return (
    <header className={styles.header}>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <div className={styles.info}>
        {subtitle && <div className={styles.subTitle}>{subtitle}</div>}
        <div>
          <h2 className={styles.title}>{titleNode}</h2>
          {meta && <span className={styles.meta}>{meta}</span>}
        </div>
      </div>
    </header>
  );
});

const PageContainer = forwardRef<
  HTMLDivElement,
  {
    fixed?: boolean;
    className?: string;
    children?: React.ReactNode;
  }
>(({ children, className, fixed }, forwardedRef) => {
  const clasName = classNames(styles.container, className, {
    [styles.fixed]: fixed,
  });
  return (
    <div className={clasName} ref={forwardedRef}>
      {children}
    </div>
  );
});

export const ErrorPage = ({
  code,
  icon,
  message,
  explaination,
  operations,
  centered = true,
  className,
}: {
  code?: string;
  icon?: React.ReactNode;
  message: string;
  explaination?: React.ReactNode;
  operations?: React.ReactNode;
  centered?: boolean;
  className?: string;
}) => {
  return (
    <Page title={message} className={className}>
      <PageContainer
        fixed
        className={classNames(styles.errorPage, { [styles.centered]: centered })}
      >
        {code && <div className={classNames(styles.img, 'font-logo')}>{code}</div>}
        {icon && <div className={classNames(styles.icon)}>{icon}</div>}
        <h1 className={styles.title}>{message}</h1>
        {explaination && <div className={styles.explaination}>{explaination}</div>}
        {operations && <div className={styles.operations}>{operations}</div>}
      </PageContainer>
    </Page>
  );
};

export { Page as default, PageHeader, PageContainer };
