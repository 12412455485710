import { Moment } from 'moment';
import { Formatters } from './format';
import { YAxisProps } from 'recharts';
export * from './format';

export const X_DATA_KEY = '__ts';

interface DataPayload {
  [key: string]: number | null;
}
export type TimeSerialData = readonly [Date | Moment | string | number, DataPayload];

export interface DualYAxis extends YAxisProps {
  relatedkeys?: string[];
  formatters?: Formatters;
}

export interface TimeSerialDataViewProps {
  data: TimeSerialData[];
  colors?: string[];
  daily?: boolean;
  unit?: string;
  formatters?: Formatters;
  names?:
    | {
        [key: string]: string;
      }
    | ((key: string) => string);
  height?: number;
  syncId?: string | number;
  omitDataKey?: boolean;
  dualYAxis?: DualYAxis;
}
export interface TimeSerialDataAreaViewProps extends TimeSerialDataViewProps {
  dot?: boolean;
}
export interface TimeSerialDataLineViewProps extends TimeSerialDataViewProps {
  dot?: boolean;
  allowDecimals?: boolean;
}
export interface TimeSerialDataBarViewProps extends TimeSerialDataViewProps {
  maxWidth?: number;
  stackId?: string;
  maxBarSize?: number;
}
