import React from 'react';
import {
  Slide,
  toast as RToast,
  ToastContainer as RToastContainer,
  ToastOptions,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uniqueId } from 'lodash';
import { Message, MessageProps } from 'components/semantic';
import styles from './index.module.scss';
import { ErrorDetails, StructuredError } from 'utils/structured-error';

type ToastActions = 'info' | 'success' | 'warning' | 'error';

class Toast {
  public success(
    title: string | React.ReactNode,
    description?: string | React.ReactNode,
    options?: ToastOptions
  ) {
    this.toast(
      'success',
      {
        header: title,
        content: description,
        success: true,
      },
      options
    );
  }

  public error(
    title: string | React.ReactNode,
    error?: StructuredError | Error,
    options?: ToastOptions
  ): void;
  public error(
    title: string | React.ReactNode,
    description?: string | React.ReactNode,
    options?: ToastOptions
  ): void;
  public error(
    title: string | React.ReactNode,
    error?: string | React.ReactNode | StructuredError | Error,
    options?: ToastOptions
  ) {
    const content =
      error instanceof StructuredError ? (
        <>
          <p>{error.rawMessage}</p>
          <ErrorDetails error={error} />
        </>
      ) : error instanceof Error ? (
        error.message
      ) : (
        error
      );

    this.toast(
      'error',
      {
        header: title,
        content: content,
        error: true,
      },
      {
        autoClose: false,
        closeOnClick: false,
        ...options,
      }
    );
  }

  public warning(
    title: string | React.ReactNode,
    description?: string | React.ReactNode,
    options?: ToastOptions
  ) {
    this.toast(
      'warn',
      {
        header: title,
        content: description,
        warning: true,
      },
      options
    );
  }

  public info(
    title: string | React.ReactNode,
    description?: string | React.ReactNode,
    options?: ToastOptions
  ) {
    this.toast(
      'info',
      {
        header: title,
        content: description,
        info: true,
      },
      options
    );
  }

  public loading(
    title: string | React.ReactNode,
    description?: string | React.ReactNode,
    options?: ToastOptions
  ) {
    return this.toast(
      'info',
      {
        header: title,
        content: description,
        info: true,
      },
      {
        autoClose: false,
        ...options,
      }
    );
  }

  public update(
    tostId: string,
    {
      title,
      type,
      description,
      delay,
    }: {
      title: string | React.ReactNode;
      type?: ToastActions;
      description?: string | React.ReactNode;
      delay?: number;
    }
  ) {
    const options = {
      header: title,
      content: description,
      [type || 'info']: true,
    };
    RToast.update(tostId, {
      render: ({ closeToast }: { closeToast: () => void }) => (
        <Message onDismiss={() => closeToast()} {...options} />
      ),
      type: type || 'info',
      autoClose: delay || 2000,
    });
  }

  public dismiss(tostId: string) {
    RToast.dismiss(tostId);
  }

  private toast(type: ToastActions | 'warn', options: MessageProps, toastoptions?: ToastOptions) {
    const toastId = uniqueId('toast');
    RToast[type](
      ({ closeToast }: { closeToast: () => void }) => (
        <Message onDismiss={() => closeToast()} {...options} />
      ),
      {
        toastId,
        ...toastoptions,
      }
    );
    return toastId;
  }
}

export const toast = new Toast();
export function ToastContainer() {
  return (
    <RToastContainer
      className={styles.container}
      position="top-right"
      autoClose={10000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      draggable={false}
      pauseOnHover
      transition={Slide}
      closeButton={false}
    />
  );
}
