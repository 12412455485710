import md5 from 'blueimp-md5';
import { consoleAPIServer } from 'config';
import { getLocale } from 'i18n';
import * as Cookies from 'js-cookie';
import { createNanoEvents } from 'nanoevents';
import qs from 'qs';
import { verifyClient2FA, verify2FA } from 'components/2FA';
import { setStorage, getStorage } from 'utils';
import { newBillingCenterEnabled, storageCsfrTokenEnabled } from 'config/feature-flags';
import { StructuredError } from './structured-error';

// 各模块版本号
export const API_VERSION = '1.1';
export const CLIENT_CENTER_VERSION = '2';
export const BILLING_CENTER_VERSION = '2';
export const DATALAKE_VERSION = 'v1';
export const RTC_VERSION = 'v1';

type FriendVersion = 'v1' | 'v2';
type RTMVersion = '1.1' | '1.2';

type URLVersionPrefix =
  | `/client-center/${typeof CLIENT_CENTER_VERSION}`
  | `/billing-center/${typeof BILLING_CENTER_VERSION}`
  | `/datalake/${typeof DATALAKE_VERSION}`
  | `/rtc/${typeof RTC_VERSION}`
  | `/friend/${FriendVersion}`
  | `/${RTMVersion}/rtm/${string}`
  | `/storage/${typeof API_VERSION}`
  | `/notify/${typeof API_VERSION}`
  | `/${typeof API_VERSION}`;

export type RequestURL = `${URLVersionPrefix}${string}`;

export const BILLING_CENTER_PREFIX: URLVersionPrefix = newBillingCenterEnabled
  ? `/billing-center/${BILLING_CENTER_VERSION}`
  : `/${API_VERSION}`;

export const XSRFTokenStatus = {
  init: Symbol('init'),
  pending: Symbol('pending'),
  done: Symbol('do'),
};

// REMOVE 有些模块还有依赖，暂时不能移除
const XSRFTOKEN_KEY = 'X-XSRF-TOKEN';
let XSRFToken: symbol = XSRFTokenStatus.init;
let XSRFPromise: Promise<string> | null = null;
const fetchXSRFToken = async () => {
  const { 'xsrf-token': token } = (await (
    await enhancedFetch(`${consoleAPIServer}/${API_VERSION}/xsrf-token`)
  ).json()) as {
    'xsrf-token': string;
    ttl: number;
  };
  if (token) {
    Cookies.set('XSRF-TOKEN', token, { path: '/classic' });
    XSRFToken = XSRFTokenStatus.done;
    storageCsfrTokenEnabled && setStorage(token, XSRFTOKEN_KEY);
  }
  return token;
};
const getXSRFToken = async () => {
  if (XSRFToken === XSRFTokenStatus.init) return null;
  if (XSRFToken === XSRFTokenStatus.pending) {
    return XSRFPromise || (XSRFPromise = fetchXSRFToken());
  }
  if (XSRFToken === XSRFTokenStatus.done) {
    return storageCsfrTokenEnabled ? getStorage(XSRFTOKEN_KEY) : XSRFPromise;
  }
  return null;
};
export const resetXSRFToken = (status = XSRFTokenStatus.pending) => {
  XSRFToken = status;
  XSRFPromise = null;
};

export interface ExtendedFetchOptions {
  method?: RequestInit['method'];
  query?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  body?:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      }
    | FormData
    | string;
  headers?: RequestInit['headers'];
}

const sign = function sign(key: string, isMasterKey = false) {
  var now = new Date().getTime();
  var signature = md5(now + key);
  if (isMasterKey) {
    return signature + ',' + now + ',master';
  }
  return signature + ',' + now;
};

export async function enhancedFetch(
  url: string,
  { method, query, body, headers }: ExtendedFetchOptions = {},
  init?: RequestInit
) {
  const { headers: initHeaders, ...restInit } = init || {};
  const defaultHeaders = {
    Accept: 'application/json',
  };
  defaultHeaders['Accept-Language'] = getLocale();
  const defaultInit: RequestInit = {
    method,
    credentials: 'include',
    headers: {
      ...defaultHeaders,
      ...headers,
      ...initHeaders,
    },
  };
  if (body) {
    defaultInit.body =
      typeof body === 'string' || body instanceof FormData ? body : JSON.stringify(body);
  }
  if (query) {
    const queryString = query
      ? qs.stringify(query, {
          addQueryPrefix: true,
        })
      : '';
    url = `${url}${queryString}`;
  }
  const newInit = { ...defaultInit, ...restInit };
  if (newInit.method && ['PATCH', 'POST', 'PUT', 'DELETE'].includes(newInit.method)) {
    if (!(newInit.body instanceof FormData)) {
      newInit.headers = {
        'Content-Type': 'application/json; charset=utf-8',
        ...newInit.headers,
      };
    }
  }

  return fetch(url, newInit);
}

const getResponseData = (response: Response) => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return response.json();
  }
  return response.text();
};

export const CSRFTokenStatus = {
  init: Symbol('init'),
  pending: Symbol('pending'),
  done: Symbol('done'),
};

const CSRFTOKEN_KEY = 'X-CSRF-TOKEN';
let CSRFToken: symbol = CSRFTokenStatus.init;
let CSRFPromise: Promise<string> | null = null;
const fetchCsrfXSRFToken = async () => {
  const { 'csrf-token': token } = (await (
    await enhancedFetch(`${consoleAPIServer}/client-center/${CLIENT_CENTER_VERSION}/csrf-token`)
  ).json()) as {
    'csrf-token': string;
    ttl: number;
  };
  if (token) {
    Cookies.set('CSRF-TOKEN', token, { path: '/classic' });
    CSRFToken = CSRFTokenStatus.done;
    storageCsfrTokenEnabled && setStorage(token, CSRFTOKEN_KEY);
  }
  return token;
};
const getCSRFToken = async () => {
  if (CSRFToken === CSRFTokenStatus.init) return null;
  if (CSRFToken === CSRFTokenStatus.pending) {
    return CSRFPromise || (CSRFPromise = fetchCsrfXSRFToken());
  }
  if (CSRFToken === CSRFTokenStatus.done) {
    return storageCsfrTokenEnabled ? getStorage(CSRFTOKEN_KEY) : CSRFPromise;
  }
  return null;
};
export const resetCSRFToken = (status = CSRFTokenStatus.pending) => {
  CSRFToken = status;
  CSRFPromise = null;
};

export enum RequestEvent {
  Unauthornized = 'unauthornized',
}
export const eventEmitter = createNanoEvents<{
  [RequestEvent.Unauthornized]: () => void;
}>();
/**
 * @param  {string} url  The URL we want to request
 * @param  {object} [options]
 * @param  {object} [options.query] will be stringified to queryString
 * @param  {object} [init] The options we want to pass to "fetch"
 * @return {object} An object containing either "data" or "err"
 */
async function _request<T>(
  url: string,
  options: ExtendedFetchOptions = {},
  init: RequestInit = {}
): Promise<T> {
  let response: Response;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let json: any;
  let statusCode: number;
  const createRequestError = (message: string, code?: string | number) => {
    const details = `${statusCode || 'N/A'} ${options.method || init.method || 'GET'} ${url}`;
    return new StructuredError(message, code, details);
  };
  try {
    response = await enhancedFetch(url, options, init);
    // 2FA required
    if (response.status === 401) {
      const { pathname } = new URL(url);
      json = await response.json();
      if (json && json.token) {
        // twofa of signin handle as special case
        if (pathname === `/client-center/${CLIENT_CENTER_VERSION}/signin`) {
          throw new Error('need twofa');
        }
        // client-center
        if (url.includes(`/client-center`) || json.version === 2) {
          [response, json] = await verifyClient2FA({ url, options, init });
        } else {
          // Uluru
          [response, json] = await verify2FA(json.token);
        }
      } else {
        eventEmitter.emit(RequestEvent.Unauthornized);
      }
    }
    statusCode = response.status;
    // 204 No Content
    if (statusCode === 204) {
      return undefined as unknown as T;
    }

    if (json === undefined) {
      json = await getResponseData(response);
    }
  } catch (error) {
    const requestError = createRequestError(error.message);
    throw requestError;
  }
  if (response.ok) {
    return json;
  }
  if (json && (json.error || json.code !== undefined)) {
    const { error: errorMessage, code, ...rest } = json;
    const message = errorMessage || `Unknown Error (code: ${code})`;
    const error = createRequestError(message, code);
    Object.assign(error, rest);
    Object.assign(error, { response });
    throw error;
  }
  throw Object.assign(createRequestError(response.statusText), { response });
}

export interface RequestOptions extends ExtendedFetchOptions {
  appId?: string;
  masterKey?: string;
}
/**
 * Requests a LeanCloud dashboard endpoint, returning a promise.
 * @param  {string} url  The URL we want to request
 * @param  {object} [options]
 * @param  {string} [options.version] default to '/1.1'. set it to '' if the resource is not part of dashboard REST APIs.
 * @param  {object} [options.query] will be stringified to queryString
 * @param  {object} [init] The options we want to pass to "fetch"
 * @return {object} An object containing either "data" or "err"
 */
export default async function request<T>(
  url: RequestURL,
  { appId, masterKey, headers, ...fetchOptions }: RequestOptions = {},
  init: RequestInit = {}
) {
  const defaultHeaders = {};
  const csrfToken = await getCSRFToken();

  // REMOVE 有些模块还有依赖，暂时不能移除
  const xsfrToken = await getXSRFToken();
  if (xsfrToken && typeof xsfrToken === 'string') {
    defaultHeaders['X-XSRF-TOKEN'] = xsfrToken;
  }

  if (csrfToken && typeof csrfToken === 'string') {
    defaultHeaders['X-CSRF-TOKEN'] = csrfToken;
  }
  if (appId) {
    defaultHeaders['X-LC-ID'] = appId;
  }
  if (masterKey) {
    defaultHeaders['X-LC-SIGN'] = sign(masterKey, true);
  }
  const newHeaders = {
    ...defaultHeaders,
    ...headers,
  };
  const newUrl = `${consoleAPIServer}${url}`;
  return _request<T>(newUrl, { headers: newHeaders, ...fetchOptions }, init);
}

export const abortableRequest =
  typeof AbortController === 'undefined'
    ? <T>(url: RequestURL, options?: RequestOptions, init?: RequestInit) => ({
        promise: request<T>(url, options, init),
        abort: () => console.warn('AbortController not supported'),
      })
    : <T>(url: RequestURL, options?: RequestOptions, init?: RequestInit) => {
        const abortController = new AbortController();
        const { signal, abort } = abortController;
        const promise = request<T>(url, options, {
          signal,
          ...init,
        });
        return {
          promise,
          abort: abort.bind(abortController),
        };
      };

const get = <T>(url: RequestURL, query?: object, init?: RequestInit, options?: RequestOptions) =>
  request<T>(url, { query, ...options }, init);

const post = <T>(
  url: RequestURL,
  body?: object | FormData,
  init: RequestInit = {},
  options?: RequestOptions
) => {
  if (body) {
    init.body = body instanceof FormData ? body : JSON.stringify(body);
  }
  init.method = 'POST';
  return request<T>(url, options, init);
};

const put = <T>(
  url: RequestURL,
  body?: object | FormData,
  init: RequestInit = {},
  options?: RequestOptions
) => {
  if (body) {
    init.body = body instanceof FormData ? body : JSON.stringify(body);
  }
  init.method = 'PUT';
  return request<T>(url, options, init);
};

const _delete = <T>(url: RequestURL, init: RequestInit = {}, options?: RequestOptions) => {
  init.method = 'DELETE';
  return request<T>(url, options, init);
};

export { get, post, put, _delete };
