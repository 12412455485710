import ReactGA from 'react-ga4';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/react';
import i18next, { Modules } from 'i18next';
import moment from 'moment';
import { getStorage, setStorage } from 'utils';
import 'moment/locale/zh-cn';

export enum Locale {
  en = 'en',
  zh = 'zh',
}

const MOMENT_LOCALE: {
  [locale in Locale]: string;
} = {
  zh: 'zh-cn',
  en: 'en',
};

const FILE_NAMES: Partial<Record<Locale, string>> = {
  zh: 'zh_CN',
};

const STORAGE_LOCALE_KEY = 'locale';
const ALLOWED_LOCALES = Object.values(Locale);
const DEFAULT_NAME_SPACE = 'lc';

const getDefaultLocale = () => {
  const { languages } = window.navigator;
  const locales = languages.map((language) => language.substr(0, 2) as Locale);
  const locale = locales.find((localeItem) => ALLOWED_LOCALES.includes(localeItem));
  return locale || Locale.zh;
};

const detectLocale = () => {
  const persistedLocale = getStorage(STORAGE_LOCALE_KEY) as Locale;
  return persistedLocale && ALLOWED_LOCALES.includes(persistedLocale)
    ? persistedLocale
    : getDefaultLocale();
};
let locale: Locale = detectLocale();

const changeLocale = async (newLocale: Locale) => {
  moment.locale(MOMENT_LOCALE[newLocale]);
  await i18next.changeLanguage(newLocale);
  ReactGA.set({
    dimension1: newLocale,
  });
};

export const setLocale = async (newLocale: Locale) => {
  locale = newLocale;
  setStorage(locale, STORAGE_LOCALE_KEY);
  await changeLocale(locale);
};
export const getLocale = () => locale;

export const webpackBackend: Modules['backend'] = {
  type: 'backend',
  init: function (services, backendOptions, i18nextOptions) {
    /* use services and options */
  },
  read: async function (language, namespace, callback) {
    const fileName = FILE_NAMES[language] || language;

    const pathPrefix = process.env.REACT_APP_TRANSLATION_PATCH;
    let patch = {};

    if (process.env.NODE_ENV !== 'production' && pathPrefix) {
      try {
        patch = await import(`./locales/${pathPrefix}/${fileName}.json`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }

    try {
      const prodResources = await import(`./locales/${fileName}.json`);
      callback(null, { ...prodResources, ...patch });
    } catch (error) {
      callback(error, false);
    }
  },
  create: function (languages, namespace, key, fallbackValue) {
    /* save the missing translation */
  },
};

const init = async () => {
  moment.locale(MOMENT_LOCALE[locale]);
  await i18next
    .use(webpackBackend)
    .use(initReactI18next)
    .init({
      debug: process.env.NODE_ENV !== 'production',
      defaultNS: DEFAULT_NAME_SPACE,
      lng: locale,
      keySeparator: false,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

init().catch(Sentry.captureException);
