import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useCallback,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import _, { noop } from 'lodash';
import * as SUI from 'semantic-ui-react';

import { SemanticICONS as ExtentedSemanticICONS } from './icon-name';
import styles from './semantic.module.scss';

export * from 'semantic-ui-react';

type Noop = typeof noop;

export class Label extends SUI.Label {
  public static defaultProps: typeof SUI.Label.defaultProps = {
    ...SUI.Label.defaultProps,
    basic: true,
  };
}

export class Dropdown extends SUI.Dropdown {
  public static defaultProps: typeof SUI.Dropdown.defaultProps = {
    ...SUI.Dropdown.defaultProps,
    selectOnBlur: false,
  };
}

export function Popup({
  children,
  position = 'top center',
  inverted = true,
  size = 'tiny',
  popperModifiers = [],
  ...props
}: SUI.PopupProps) {
  const modifiers = [
    {
      name: 'preventOverflow',
      enabled: true,
      options: { padding: 4 },
    },
    ...popperModifiers,
  ];
  return (
    <SUI.Popup
      position={position}
      inverted={inverted}
      size={size}
      popperModifiers={modifiers}
      {...props}
    >
      {children}
    </SUI.Popup>
  );
}
Popup.Content = SUI.Popup.Content;
Popup.Header = SUI.Popup.Header;

export const ControlledDropdown = memo(
  ({
    children,
    className,
    inverted = false,
    on = ['click', 'focus'],
    ...popupProps
  }: SUI.StrictPopupProps & {
    children:
      | SUI.StrictPopupProps['children']
      | ((props: { close: Noop }) => SUI.StrictPopupProps['children']);
  }) => {
    const [open, setOpen] = useState(false);
    const onOpen = useCallback(() => setOpen(true), []);
    const close = useCallback(() => setOpen(false), []);
    return (
      <Popup
        className={classnames(styles.controlledDropdown, 'ui dropdown', className)}
        inverted={inverted}
        on={on}
        open={open}
        onOpen={onOpen}
        onClose={close}
        children={
          _.isFunction(children)
            ? {
                close,
              }
            : children
        }
        {...popupProps}
      />
    );
  }
);

export type TableProps = SUI.StrictTableProps & {
  loading?: boolean;
  borderless?: boolean;
  fillParent?: boolean;
  nested?: boolean | 2;
};
function _Table(props: TableProps) {
  const {
    collapsing,
    loading,
    borderless = false,
    singleLine,
    unstackable = singleLine,
    fillParent,
    nested,
    className,
    ...restProps
  } = props;
  return (
    <div
      className={classnames(
        {
          collapsing,
          [styles.loading]: loading,
          [styles.singleLine]: singleLine,
          [styles.fillParent]: fillParent,
          [styles.tableBorder]: !borderless,
        },
        styles.loaderContainer
      )}
    >
      <div className={styles.tableContainer}>
        {SUI.Table({
          ...restProps,
          className: classnames(
            {
              [styles.nestedTableLv1]: nested === true,
              [styles.nestedTableLv2]: nested === 2,
            },
            className
          ),
          singleLine,
          unstackable,
          collapsing,
        })}
      </div>
      {loading && (
        <div className={styles.loader}>
          <SUI.Loader active inline />
        </div>
      )}
    </div>
  );
}

export const Table = Object.assign(_Table, SUI.Table);

const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  e.stopPropagation();
};

export interface StrictModalContentProps extends SUI.StrictModalContentProps {
  attached?: boolean;
}

export const ModalContent: React.StatelessComponent<StrictModalContentProps> = ({
  attached,
  className,
  ...props
}) => (
  <SUI.Modal.Content
    className={classnames({ [styles.attachedModalContent]: attached }, className)}
    {...props}
  />
);

export interface ModalProps extends Omit<SUI.StrictModalProps, 'header' | 'content' | 'actions'> {
  onOpen?: Noop;
  onClose?: Noop;
  children?: ReactNode | ((params: { close: Noop }) => ReactNode);
}

export class Modal extends React.Component<ModalProps> {
  public static Header = SUI.Modal.Header;
  public static Content = ModalContent;
  public static Description = SUI.Modal.Description;
  public static Actions = SUI.Modal.Actions;

  public state = {
    open: false,
  };

  public triggerRef = React.createRef<HTMLElement>();
  public contentRef = React.createRef<HTMLDivElement>();

  public open = () => {
    this.setState({ open: true });
    this.props.onOpen?.();
  };
  public close = () => {
    this.setState({ open: false });
    this.triggerRef.current?.focus();
    this.props.onClose?.();
  };

  public render() {
    const { children, ...modalProps } = this.props;
    return (
      <SUI.Modal
        open={this.state.open}
        centered={false}
        size="small"
        {...modalProps}
        onOpen={this.open}
        onClose={this.close}
        onMount={() =>
          setTimeout(() => {
            const content = this.contentRef.current;
            if (content && content.querySelector && content.querySelector('[autoFocus]') !== null) {
              content.setAttribute('tabindex', '0');
              content.focus();
              content.removeAttribute('tabindex');
            }
          }, 0)
        }
        triggerRef={this.triggerRef}
        // All events should be trapped inside the Modal
        // https://github.com/Semantic-Org/Semantic-UI-React/issues/3174
        onClick={stopPropagation}
        onDoubleClick={stopPropagation}
      >
        <SUI.Ref innerRef={this.contentRef}>
          <>
            {_.isFunction(children)
              ? children({
                  close: this.close,
                })
              : children}
          </>
        </SUI.Ref>
      </SUI.Modal>
    );
  }
}

type CompositeComponent<P> = React.ComponentClass<P> | React.FunctionComponent<P>;
export function withModal<P>(staticModalProps?: ModalProps) {
  return (Component: CompositeComponent<P & { close: () => void }>) =>
    (props: P & { modalProps?: ModalProps }) =>
      (
        <Modal {...staticModalProps} {...props.modalProps}>
          {({ close }) => <Component {...props} close={close} modalProps={undefined} />}
        </Modal>
      );
}

export interface ButtonProps extends Omit<SUI.StrictButtonProps, 'onClick'> {
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: ButtonProps
  ) => unknown | Promise<unknown>;
  [key: string]: unknown;
}

export const Button = ({ onClick, children, ...props }: ButtonProps) => {
  const [loading, setLoading] = useState(false);

  const unmounted = useRef(false);
  useEffect(
    () => () => {
      unmounted.current = true;
    },
    []
  );

  return (
    <SUI.Button
      loading={loading}
      disabled={loading}
      {...props}
      onClick={
        onClick
          ? (event, data) => {
              const result = onClick(event, data);
              if (result) {
                setLoading(true);
                Promise.resolve(result).finally(() => {
                  if (!unmounted.current) {
                    setLoading(false);
                  }
                });
              }
            }
          : undefined
      }
    >
      {children}
    </SUI.Button>
  );
};

Button.Group = SUI.Button.Group;
Button.Content = SUI.Button.Content;
export interface LinkButtonProps extends ButtonProps {
  inline?: boolean;
  muted?: boolean;
}

export const LinkButton: FunctionComponent<LinkButtonProps> = ({
  className = '',
  inline = false,
  muted = false,
  children,
  ...props
}) => {
  return (
    <Button
      {...props}
      className={classnames(className, styles.linkButton, {
        [styles.inline]: inline,
        [styles.muted]: muted,
      })}
    >
      {children}
    </Button>
  );
};

export type NativeSelectOption<T extends string = string> =
  | T
  | readonly [T, string]
  | readonly [T, string, { disabled?: boolean; label?: string; group?: string }];
export interface NativeSelectProps<T extends string = string> {
  id?: string;
  name?: string;
  options: Array<NativeSelectOption<T>>;
  placeholder?: string;
  value?: T;
  defaultValue?: T;
  required?: boolean;
  disabled?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLSelectElement>,
    data: {
      value: T;
    }
  ) => void;
  inline?: boolean;
  size?: SUI.SemanticSIZES;
  className?: string;
}
export const NativeSelect = <T extends string>(props: NativeSelectProps<T>) => {
  const { t } = useTranslation();
  const options = props.options.map((option) =>
    Array.isArray(option) ? option : ([option, option] as const)
  );
  const { undefined: ungroupOptions, ...optionGroups } = _.groupBy(
    options,
    (option) => option[2]?.group
  );
  const valueIncluded = options.some(([key]) => key === props.value);
  const { placeholder = t('action.select'), required, inline } = props;
  const classes = ['ui input caret', props.size, props.className];
  if (inline) {
    classes.push(styles.inlineSelect);
  }
  const select = (
    <select
      id={props.id}
      name={props.name}
      defaultValue={props.defaultValue}
      required={required}
      disabled={props.disabled}
      value={props.value}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e, {
            value: e.target.value as T,
          });
        }
      }}
    >
      {props.defaultValue === undefined && !valueIncluded && (
        <option key="" value="">
          {placeholder}
        </option>
      )}
      {ungroupOptions?.map(([value, text, config]) => (
        <option key={value} value={value} {...config}>
          {text}
        </option>
      ))}
      {Object.entries(optionGroups).map(([groupName, opts]) => (
        <optgroup label={groupName} key={groupName}>
          {opts.map(([value, text, config]) => (
            <option key={value} value={value} {...config}>
              {text}
            </option>
          ))}
        </optgroup>
      ))}
    </select>
  );

  return <span className={classnames(classes)}>{select}</span>;
};

export interface FormNativeSelectProps<T extends string = string> extends NativeSelectProps<T> {
  label?: React.ReactNode;
  hint?: ReactNode;
  inline?: boolean;
  as?: SUI.FormFieldProps['as'];
}
const FormNativeSelect = <T extends string>(props: FormNativeSelectProps<T>) => {
  const { as, id, label, hint, required, ...restProps } = props;
  return (
    <Form.Field as={as} id={id} required={required}>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <NativeSelect {...restProps} id={id} required={required} />
      {hint && <p className="help-block">{hint}</p>}
    </Form.Field>
  );
};
export const Form = Object.assign(SUI.Form, {
  NativeSelect: FormNativeSelect,
});

interface FileInputBaseProps<T> extends Pick<SUI.ButtonProps, 'size' | 'icon' | 'primary'> {
  id?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  accept?: string;
  hint?: string;
  name?: string;
  onChange?: (files: T, event: React.ChangeEvent<HTMLInputElement>) => void;
}
export type FileInputProps = FileInputBaseProps<File | undefined> & {
  file?: File;
};
export const FileInput = ({
  id = _.uniqueId(),
  file,
  className,
  disabled,
  hint,
  icon = 'file outline',
  size,
  primary,
  onChange,
  ...rest
}: FileInputProps) => {
  const { t } = useTranslation();
  if (hint === undefined) {
    hint = t('action.selectFile');
  }
  return (
    <div className={classnames(styles.fileWrapper, className)}>
      <input
        type="file"
        id={id}
        onChange={(e) => {
          if (onChange) {
            if (e.target && e.target.files) {
              onChange(e.target.files[0], e);
            }
          }
        }}
        className={styles.fileInput}
        disabled={disabled}
        {...rest}
      />
      <SUI.Button
        as="label"
        htmlFor={id}
        content={file ? file.name : hint}
        disabled={disabled}
        size={size}
        primary={primary}
        className={styles.fileControl}
        {...(icon ? { icon, basic: true, labelPosition: 'left' } : undefined)}
      />
    </div>
  );
};
export type MultipleFileInputProps = FileInputBaseProps<FileList> & {
  files?: FileList;
};
export const MultipleFileInput = ({
  id = _.uniqueId(),
  files,
  className,
  disabled,
  hint,
  icon = 'copy',
  size,
  primary,
  onChange,
  ...rest
}: MultipleFileInputProps) => {
  const { t } = useTranslation();
  if (hint === undefined) {
    hint = t('action.selectFile');
  }
  return (
    <span className={classnames(styles.fileWrapper, className)}>
      <input
        type="file"
        multiple
        id={id}
        onChange={(e) => {
          if (onChange) {
            if (e.target && e.target.files) {
              onChange(e.target.files, e);
            }
          }
        }}
        disabled={disabled}
        className={styles.fileInput}
        {...rest}
      />
      <span>
        <SUI.Button
          as="label"
          htmlFor={id}
          content={files ? _.map(files, (f) => f.name).join(', ') : hint}
          disabled={disabled}
          size={size}
          primary={primary}
          className={styles.fileControl}
          {...(icon ? { icon, basic: true, labelPosition: 'left' } : undefined)}
        />
      </span>
    </span>
  );
};

export interface IconProps extends Omit<SUI.IconProps, 'name'> {
  name?: ExtentedSemanticICONS;
}
class NewIcon extends React.PureComponent<IconProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static propTypes?: any;
  static Group: typeof SUI.IconGroup;
}
export type SemanticICONS = ExtentedSemanticICONS;
export const Icon = SUI.Icon as typeof NewIcon;
// eslint-disable-next-line react/forbid-foreign-prop-types
delete Icon.propTypes.name;

export const InlineDivider = () => <div className={styles.inlineDivider} />;
