import React, {
  memo,
  useMemo,
  useState,
  useCallback,
  useEffect,
  FormEvent,
  forwardRef,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import Mousetrap from 'mousetrap';
import { PropsType } from 'utils';
import NoData from 'components/NoData';
import { Dropdown, ControlledDropdown, Input, Label, Loader } from 'components/semantic';
import { useFilterApps, useRecentlyApps } from 'App/AppList/AppList';
import { useApps } from 'App/Apps';
import { Application } from 'App/types';
import styles from './header.module.scss';

const App = memo<{ app: Application; active?: boolean; className?: string }>(
  ({ app, active, className }) => {
    const { t } = useTranslation();

    return (
      <div className={classNames(styles.app, 'fill-space', className)}>
        <span className={styles.appName}>{app.appName}</span>
        <span className="space" />
        {active && <kbd>↵</kbd>}
        {app.isDedicatedDeploy ? (
          <Label color="orange" size="tiny">
            {t('label.plan.enterprise.abbr')}
          </Label>
        ) : app.isDev ? (
          <Label color="grey" size="tiny">
            {t('label.plan.developer.abbr')}
          </Label>
        ) : (
          <Label color="green" size="tiny">
            {t('label.plan.business.abbr')}
          </Label>
        )}
      </div>
    );
  }
);

interface AppLinkProps extends Omit<PropsType<Link>, 'to' | 'children'> {
  app: Application;
  active?: boolean;
}
const AppLink = memo(
  forwardRef<HTMLElement, AppLinkProps>(({ app, active, ...linkProps }, ref) => (
    <Dropdown.Item
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      as={(itemProps: any) => (
        <Link
          {...itemProps}
          {...linkProps}
          ref={ref}
          className={classNames(itemProps.className, linkProps.className, {
            [styles.active]: active,
          })}
          to={`/apps/${app.appId}`}
          children={<App app={app} active={active} />}
        />
      )}
    />
  ))
);

function useKeepInView() {
  const currentElement = useRef<HTMLElement>(null);
  const keepInView = useCallback(() => {
    const item = currentElement.current;
    if (item) {
      const menu = item.parentElement!;
      const isOutOfUpperView = item.offsetTop < menu.scrollTop;
      const isOutOfLowerView =
        item.offsetTop + item.clientHeight > menu.scrollTop + menu.clientHeight;
      if (isOutOfUpperView) {
        menu.scrollTop = item.offsetTop;
      } else if (isOutOfLowerView) {
        menu.scrollTop = item.offsetTop + item.clientHeight - menu.clientHeight;
      }
    }
  }, []);
  return [currentElement, keepInView] as const;
}

export default memo(() => {
  const { t } = useTranslation();
  const [apps, { loading: appsLoading }] = useApps();
  const { appId } = useParams<{ appId?: string }>();
  const currentApp = useMemo(() => apps?.find((app) => app.appId === appId), [appId, apps]);
  const [results, { filter: query, setFilter }] = useFilterApps(apps);
  const [allRecentlyUsedAppIds] = useRecentlyApps(apps);
  //Exclude current app
  const recentlyUsedApps = allRecentlyUsedAppIds.filter((app) => app.appId !== appId);

  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);

  useEffect(() => setFilter(''), [open, setFilter]);
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => setActiveIndex(0), [query, open]);

  useEffect(() => {
    Mousetrap.bind('/', () => {
      onOpen();
      return false;
    });
    return () => {
      Mousetrap.unbind('/');
    };
  }, [onOpen]);

  const [appLinkRef, keepInView] = useKeepInView();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(keepInView, [activeIndex]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // XXX: 升级到 react@17 后就可以删掉了, 详情: https://reactjs.org/docs/legacy-event-pooling.html
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (event as any).persist?.();

      if (!apps) return;
      if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
        event.preventDefault();
        setActiveIndex((index) => Math.max(index - 1, 0));
        return;
      }
      if (event.key === 'ArrowDown' || event.key === 'Tab') {
        event.preventDefault();
        const maxIndex = (query ? results.length : recentlyUsedApps.length + apps.length) - 1;
        setActiveIndex((index) => Math.min(index + 1, maxIndex));
        return;
      }
    },
    [apps, recentlyUsedApps.length, results.length, query]
  );
  const history = useHistory();
  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!apps) return;
      let app_id = '';

      if (query) {
        app_id = results[activeIndex]?.appId;
      } else {
        app_id = [...recentlyUsedApps, ...apps][activeIndex]?.appId;
      }

      if (app_id) {
        app_id && history.push(`/apps/${app_id}`);
        close();
      }
    },
    [activeIndex, apps, close, history, recentlyUsedApps, results, query]
  );

  return (
    <ControlledDropdown
      className={styles.menuItem}
      trigger={
        <span className={classNames(styles.menuItemTrigger, styles.dropdown)}>
          {appsLoading ? (
            <Loader active inline size="tiny" />
          ) : currentApp ? (
            <App app={currentApp} className={styles.currentApp} />
          ) : (
            t('header.applications')
          )}
        </span>
      }
      open={open}
      onOpen={onOpen}
      onClose={close}
      position="bottom left"
    >
      <form onSubmit={handleSubmit}>
        <Dropdown.Menu className={styles.appSelect}>
          {apps && apps.length > 0 && (
            <>
              <Input
                icon="search"
                value={query}
                onChange={(e, { value }) => setFilter(value)}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              {query !== '' ? (
                results.length ? (
                  results.map((app, index) => (
                    <AppLink
                      key={app.appId}
                      app={app}
                      active={index === activeIndex}
                      ref={index === activeIndex ? appLinkRef : undefined}
                      onClick={close}
                    />
                  ))
                ) : (
                  <NoData header={t('header.applications.noMatches')} />
                )
              ) : (
                <>
                  {recentlyUsedApps.length > 0 && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Header>{t('header.applications.recentlyUsed')}</Dropdown.Header>
                      {recentlyUsedApps.map((app, index) => (
                        <AppLink
                          key={app.appId}
                          app={app}
                          active={index === activeIndex}
                          ref={index === activeIndex ? appLinkRef : undefined}
                          onClick={close}
                        />
                      ))}
                    </>
                  )}
                  <Dropdown.Divider />
                  <Dropdown.Header>{t('header.applications.all')}</Dropdown.Header>
                  {apps.map((app, index) => (
                    <AppLink
                      key={app.appId}
                      app={app}
                      active={index + recentlyUsedApps.length === activeIndex}
                      ref={index + recentlyUsedApps.length === activeIndex ? appLinkRef : undefined}
                      onClick={close}
                    />
                  ))}
                </>
              )}
            </>
          )}
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/apps" onClick={close}>
            {t('header.applications.seeAll')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </form>
    </ControlledDropdown>
  );
});
