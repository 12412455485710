import React, { useMemo, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currency } from 'config';
import _ from 'lodash';
import { formatBytesStructured } from 'utils';
import Placeholder from 'components/Placeholder';
import { RadioGroup } from 'components/Radio';
import { usePricing } from 'App/Pricing';
import { useRuntime } from '.';
import styles from './index.module.scss';
import { RuntimeType } from './types';

const QuotaItem = memo<{
  storage?: number;
  memory: number;
  type: RuntimeType;
  dataNodes?: number;
}>(({ storage, memory, type, dataNodes }) => {
  const { t } = useTranslation();
  const [SKUs, { loading }] = usePricing();

  const [memorySize, memoryUnit] = useMemo(
    () => formatBytesStructured(memory * 1024 * 1024),
    [memory]
  );

  const matchedSKU = useMemo(() => {
    const item = `${type}-${memory}`;
    return SKUs?.find((SKU) => SKU.service === 'LeanDB' && SKU.item === item);
  }, [SKUs, memory, type]);
  return (
    <div className={styles.radioItem}>
      <div>
        <span className="spec-value">{memorySize}</span> {memoryUnit} {t('label.ram')}
      </div>
      {storage !== undefined && (
        <div>
          <span className="spec-value">{storage}</span> GB {t('label.rom')}
        </div>
      )}
      {dataNodes && (
        <div>
          <span className="spec-value">{dataNodes}</span> {t('db.scale.dataNodes')}
        </div>
      )}
      <div>
        <span className="spec-value">{loading ? '?' : matchedSKU?.price ?? 'unknown'}</span>{' '}
        {`${currency} / ${t('label.day')}`}
      </div>
    </div>
  );
});

export const useQuota = (type: RuntimeType, initQuota?: string) => {
  const [quota, setQuota] = useState(initQuota);
  const [runtimeInfo] = useRuntime(type);
  const [testQuotas, standardQuotas] = useMemo(() => {
    if (!runtimeInfo) {
      return [[], []];
    }
    const { availableQuotas, nodeQuotaMap } = runtimeInfo;
    return _(availableQuotas)
      .partition((item) => nodeQuotaMap[item].forDevUse)
      .valueOf();
  }, [runtimeInfo]);

  const standardQuotaRadioGroup = runtimeInfo ? (
    <RadioGroup
      required
      name="max_memory"
      value={quota}
      radios={standardQuotas.map((value) => {
        const { storageQuotaMap, nodeQuotaMap } = runtimeInfo;
        const nodeQuota = nodeQuotaMap[value];
        const storage =
          type === 'redis'
            ? undefined
            : storageQuotaMap[nodeQuota.availableStorageQuotas[0]].storage;

        return {
          value,
          label: (
            <QuotaItem
              storage={storage}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              dataNodes={(nodeQuota as any).dataNodes}
              memory={nodeQuota.memory}
              type={type}
            />
          ),
        };
      })}
      onChange={(e, data) => setQuota(data.value)}
    />
  ) : (
    <Placeholder />
  );

  const testQuotaRadioGroup = runtimeInfo ? (
    <RadioGroup
      required
      name="max_memory"
      value={quota}
      radios={testQuotas.map((value) => {
        const { storageQuotaMap, nodeQuotaMap } = runtimeInfo;
        const nodeQuota = nodeQuotaMap[value];
        return {
          value,
          label: (
            <QuotaItem
              type={type}
              storage={storageQuotaMap[nodeQuota.availableStorageQuotas[0]].storage}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              dataNodes={(nodeQuota as any).dataNodes}
              memory={nodeQuota.memory}
            />
          ),
        };
      })}
      onChange={(e, data) => setQuota(data.value)}
    />
  ) : (
    <Placeholder />
  );
  return [
    quota,
    {
      standardQuotaRadioGroup,
      testQuotaRadioGroup,
      testQuotas,
      standardQuotas,
    },
  ] as const;
};
