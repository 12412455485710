import React from 'react';
import styles from './structured-error.module.scss';

export class StructuredError extends Error {
  constructor(public rawMessage: string, public code?: number | string, public details?: string) {
    super(
      [
        rawMessage,
        code === undefined ? undefined : ` (${code})`,
        details === undefined ? undefined : ` [${details}]`,
      ].join('')
    );
  }
}

export function ErrorDetails({ error }: { error: StructuredError }) {
  return (
    <p className={styles.details}>
      {error.details}
      {error.code !== undefined && <span> (code: {error.code})</span>}
    </p>
  );
}
