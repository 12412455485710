import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch, generatePath, useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { TDSStyle } from 'env';
import { Menu, StrictTabProps, Tab as SUITab } from 'components/semantic';
import useURLSearchParam, { useApplyChanges, generateChange } from 'utils/use-url-search-param';
import styles from './index.module.scss';

export interface IndexedPane {
  id: string;
  menuItem: React.ReactNode;
  content?: React.ReactNode;
  fillParent?: boolean;
}

export interface TabProps {
  panes: IndexedPane[];
  className?: string;
  borderless?: boolean;
  padding?: boolean;
  sticky?: boolean;
  defaultActiveIndex?: number;
}

export const ControlledTab = ({
  className,
  borderless,
  padding = !borderless,
  sticky,
  panes,
  activeId,
  defaultActiveIndex = 0,
  onTabChange,
}: TabProps & { activeId?: string; onTabChange?: (data: { activeId: string }) => void }) => {
  const activeIndex = useMemo(
    () => (activeId ? panes.findIndex((pane) => pane.id === activeId) : defaultActiveIndex),
    [activeId, defaultActiveIndex, panes]
  );
  const tabPanes = useMemo(() => {
    return panes.map((item) => ({
      menuItem: <Menu.Item key={item.id}>{item.menuItem}</Menu.Item>,
      render: () => <SUITab.Pane attached={!borderless}>{item.content}</SUITab.Pane>,
    }));
  }, [panes, borderless]);

  const handleTabChange = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, { activeIndex: index }: StrictTabProps) => {
      if (typeof index !== 'number' || index === activeIndex) {
        return;
      }
      onTabChange?.({ activeId: panes[index].id });
    },
    [activeIndex, onTabChange, panes]
  );

  const archorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (borderless) {
      if (archorRef.current) {
        archorRef.current.nextElementSibling?.querySelector('.menu .active.item')?.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }
  }, [activeId, borderless]);
  const firstRender = useRef(true);
  useEffect(() => {
    if (sticky && !TDSStyle) {
      if (archorRef.current) {
        if (!firstRender.current) {
          archorRef.current.scrollIntoView();
        }
      }
    }
    firstRender.current = false;
  }, [activeId, borderless, sticky]);

  return (
    <>
      {sticky && <div ref={archorRef} />}
      <SUITab
        className={classNames(
          'tab_container',
          {
            [styles.borderless]: borderless,
            [styles.padding]: padding,
            [styles.sticky]: sticky,
            [styles.fillParent]: panes[activeIndex]?.fillParent,
          },
          className
        )}
        menu={borderless ? { secondary: true, pointing: true } : undefined}
        panes={tabPanes}
        activeIndex={activeIndex}
        onTabChange={handleTabChange}
        renderActiveOnly
      />
    </>
  );
};

export const Tab = ({
  defaultActiveId,
  ...props
}: TabProps & {
  defaultActiveId?: string;
}) => {
  const [activeId, setActiveId] = useState(defaultActiveId);

  const handleTabChange = useCallback(({ activeId: newActiveId }: { activeId: string }) => {
    setActiveId(newActiveId);
  }, []);

  return <ControlledTab activeId={activeId} onTabChange={handleTabChange} {...props} />;
};

export const TabWithSearch = ({
  searchKey = 'tab',
  borderless,
  sticky = borderless,
  resetSearchKeys = [],
  ...props
}: TabProps & { searchKey?: string; resetSearchKeys?: string[] }) => {
  const applyChanges = useApplyChanges();
  const [searchValue, , changeSearchValue] = useURLSearchParam(searchKey);

  return (
    <ControlledTab
      borderless={borderless}
      sticky={sticky}
      onTabChange={({ activeId }) =>
        applyChanges([
          changeSearchValue(activeId),
          ...resetSearchKeys.map((key) => generateChange(key, undefined)),
        ])
      }
      activeId={searchValue}
      {...props}
    />
  );
};

/**
 * Lagecy TabWithSearch
 */
export default ({
  asNav,
  ...props
}: TabProps & { searchKey?: string; syncWithSearch?: boolean; asNav?: boolean }) => {
  const { searchKey, syncWithSearch = asNav || searchKey } = props;
  return syncWithSearch ? (
    <TabWithSearch borderless={asNav} padding={asNav} {...props} />
  ) : (
    <Tab borderless={asNav} padding={asNav} {...props} />
  );
};

const TAB_KEY = 'key';

export function TabWithPath({
  basePath,
  panes,
  borderless = true,
  sticky = borderless,
  padding = true,
  ...props
}: TabProps & {
  basePath: string;
}) {
  const path = `${basePath}/:${TAB_KEY}`;
  const match = useRouteMatch(path);
  const history = useHistory();
  const key = match?.params[TAB_KEY];
  return (
    <ControlledTab
      {...props}
      panes={panes}
      activeId={key}
      onTabChange={({ activeId }) => {
        const pathname = generatePath(path, { [TAB_KEY]: activeId });
        history.push({ pathname });
      }}
      borderless={borderless}
      sticky={sticky}
      padding={padding}
    />
  );
}

export function TabWithRawPath({
  basePath,
  panes,
  borderless = true,
  sticky = borderless,
  padding = true,
  ...props
}: TabProps & {
  basePath: string;
}) {
  const { pathname } = useLocation();
  const history = useHistory();
  // Sort panes configs desc by the length of ID.
  // The longer the ID is, the more specific the URL will be.
  const sortedPanes = useMemo(() => [...panes].sort((a, b) => b.id.length - a.id.length), [panes]);
  const key = sortedPanes.find((pane) => pathname.indexOf(basePath + pane.id) === 0)?.id;
  return (
    <ControlledTab
      {...props}
      panes={panes}
      activeId={key}
      onTabChange={({ activeId }) => {
        const newPathname = basePath + activeId;
        history.push({ pathname: newPathname });
      }}
      borderless={borderless}
      sticky={sticky}
      padding={padding}
    />
  );
}
