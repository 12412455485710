import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Page, { PageContainer } from 'components/Page';
import { Breadcrumb, Button } from 'components/semantic';
import { LineView, bytesFormatters } from 'components/StatisticView';
import { withTracker } from 'utils/tracker';
import { useTransform } from 'utils/use-api';
import { useDisplayMode } from '../../Stats/parts';
import { useDateTimeRange, useDateTimeRangeAndSelect } from '../../use-date-range';
import { StatContainer, transformMetricData, useMetrics } from '../utils';
import { useInstanceId, useMySQL } from './';
import ChartContainer from '../ChartContainer';

import styles from './index.module.scss';

type Metric = 'cpu' | 'disk' | 'memory' | 'connections' | 'qps' | 'tps';

const useMetricsForSelectedRange = (type: string) => {
  const [from, to] = useDateTimeRange();
  return useMetrics<Metric>(useInstanceId(), type, from, to);
};

const commonChartProps = {
  omitDataKey: true,
  dot: false,
  height: 280,
  syncId: 'leanRDB',
};

const useCPU = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('udb/cpu'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.cpu')}>
      <LineView
        data={chartData}
        names={{
          cpu: t(`db.udb.stat.cpu`),
        }}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useDisk = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('udb/disk'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.disk')}>
      <LineView
        data={chartData}
        unit="%"
        names={{
          disk: t('db.udb.stat.disk'),
        }}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useMemory = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('udb/memory'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.memory')}>
      <LineView
        data={chartData}
        names={{
          memory: t('db.udb.stat.memory'),
        }}
        formatters={bytesFormatters}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useQPS = () => {
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('udb/qps'),
    transformMetricData
  );
  const node = (
    <ChartContainer title="QPS">
      <LineView
        data={chartData}
        names={(key) => key.toUpperCase()}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useTPS = () => {
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('udb/tps'),
    transformMetricData
  );
  const node = (
    <ChartContainer title="TPS">
      <LineView
        data={chartData}
        names={(key) => key.toUpperCase()}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useConnections = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('udb/connections'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.connections')}>
      <LineView
        data={chartData}
        names={(key) => t(`db.udb.stat.${key.toLowerCase()}`)}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

export default withTracker(() => {
  const { t } = useTranslation();
  const id = useInstanceId();
  const [appInstances] = useMySQL();
  const [displayMode, displayModeSwitch] = useDisplayMode();
  const [CPUNode, CPUReload] = useCPU();
  const [diskNode, diskReload] = useDisk();
  const [memoryNode, memoryReload] = useMemory();
  const [QPSNode, QPSReload] = useQPS();
  const [TPSNode, TPSReload] = useTPS();
  const [connectionsNode, connectionsReload] = useConnections();
  const [, , selectNode] = useDateTimeRangeAndSelect();

  const instanceContent = useMemo(() => {
    const currentInstance = appInstances.find((instance) => instance.id === Number(id));
    return currentInstance ? currentInstance.name : id;
  }, [appInstances, id]);

  const reload = useCallback(() => {
    CPUReload();
    diskReload();
    memoryReload();
    QPSReload();
    TPSReload();
    connectionsReload();
  }, [CPUReload, diskReload, memoryReload, connectionsReload, QPSReload, TPSReload]);
  return (
    <Page title={[t('label.stat'), t('db.udb'), t('engine')]}>
      <PageContainer>
        <Breadcrumb
          className={styles.breadcrumb}
          sections={[
            { key: 'mysql', content: <Link to="../mysql">MySQL</Link> },
            { key: 'instance', content: instanceContent, active: true },
          ]}
        />
        <div className={classnames('fill-space', styles.statToolbar)}>
          <Button onClick={reload} icon="refresh" />
          {displayModeSwitch}
          <span className="space" />
          {selectNode}
        </div>
        <StatContainer displayMode={displayMode}>
          {CPUNode}
          {memoryNode}
          {diskNode}
          {connectionsNode}
          {QPSNode}
          {TPSNode}
        </StatContainer>
      </PageContainer>
    </Page>
  );
});
