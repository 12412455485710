import { useMemo, useCallback } from 'react';
import { createGlobalState } from 'react-use';
import { createLazyResourceStore, useTransform, useLocalState } from '@leancloud/use-resource';
import { checkAppOwnerByFlag } from 'config/feature-flags';
import { noop } from 'lodash';
import { getStorage, setStorage } from 'utils';
import { CLIENT_CENTER_VERSION } from 'utils/request';
import { useAPI, initialResource } from 'utils/use-api';
import { Application, AppStatus } from './types';
import { useUser } from './User';

const [, initialExtra] = initialResource;
const initialActions = {
  delete: noop,
  update: noop,
};

export const parseAppData = (app: Application): Application => ({
  ...app,
  created: new Date(app.created),
  updated: new Date(app.created),
  isOwner:
    app.appRelation === 'creator' || (checkAppOwnerByFlag && !!app.flags?.includes('tds-app')),
  isDev: app.bizType === 'dev',
  appStatus: getAppStatus(app),
});

const parseApps = (apps?: Application[]) => apps?.map(parseAppData);
const sortApps = (apps?: Application[]) =>
  apps
    ? [...apps].sort((preApp, nextApp) => {
        // 按照应用状态排序
        if (preApp.appStatus !== nextApp.appStatus) {
          return preApp.appStatus - nextApp.appStatus;
        }
        // 想通状态按照应用类型
        if (preApp.isDev !== nextApp.isDev) {
          return preApp.isDev ? 1 : -1;
        }
        if (preApp.isDedicatedDeploy !== nextApp.isDedicatedDeploy) {
          return preApp.isDedicatedDeploy ? -1 : 1;
        }
        // 按照应用名称排序
        return preApp.appName.toLocaleUpperCase() > nextApp.appName.toLocaleUpperCase() ? 1 : -1;
      })
    : undefined;

const { useResource: useApps, Provider } = createLazyResourceStore(
  ({ useTriggered }) =>
    () => {
      const [user] = useUser();
      const [apps, { setData, ...extra }] = useLocalState(
        useTransform(
          useTransform(
            useAPI<Application[]>(
              `/client-center/${CLIENT_CENTER_VERSION}/clients/self/apps`,
              undefined,
              [user],
              useTriggered() && user !== undefined
            ),
            parseApps
          ),
          sortApps
        )
      );

      const _delete = useCallback(
        (appId: string) =>
          setData((preApps) => {
            if (!preApps) {
              return;
            }
            return preApps.filter((app) => app.appId !== appId);
          }),
        [setData]
      );

      const update = useCallback(
        (appId: string, payload: Partial<Application>) => {
          setData((preApps) => {
            if (!preApps) {
              return;
            }
            return preApps.map((app) =>
              app.appId === appId
                ? parseAppData({
                    ...app,
                    ...payload,
                  })
                : app
            );
          });
        },
        [setData]
      );

      return [
        apps,
        {
          ...extra,
          update,
          delete: _delete,
        },
      ] as const;
    },
  [undefined, { ...initialExtra, ...initialActions }]
);

export { useApps, Provider };

const LRU_APPS_KEY = 'LRUApps';
const useRawLRUApps = createGlobalState<string | undefined>(getStorage(LRU_APPS_KEY));
export const useLRUApps = () => {
  const [LRUApps, setLRUApps] = useRawLRUApps();
  const recentlyUsedApps = useMemo(() => (LRUApps?.split(',') ?? []).map(Number), [LRUApps]);
  const setRecentlyUsedApps = useCallback(
    (apps: number[]) => {
      const newValue = apps.join(',');
      setLRUApps(newValue);
      setStorage(newValue, LRU_APPS_KEY);
    },
    [setLRUApps]
  );
  return [recentlyUsedApps, setRecentlyUsedApps] as const;
};

function getAppStatus(app: Application) {
  const { flags, archiveStatus } = app;
  if (flags && flags.includes('banned-app')) {
    return AppStatus.banned;
  }
  if (flags && flags.includes('disabled-app')) {
    return AppStatus.suspendedDueToInsufficientFunds;
  }
  if (archiveStatus === 1) {
    return AppStatus.unarchiving;
  }
  if (archiveStatus === 2) {
    return AppStatus.archived;
  }
  return AppStatus.normal;
}
