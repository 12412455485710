import { memo, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { formatBytesStructured } from 'utils';
import Hint, { HintedContent } from 'components/Hint';
import Page, { PageContainer } from 'components/Page';
import Panel from 'components/Panel';
import Placeholder from 'components/Placeholder';
import { Button, Card, Icon } from 'components/semantic';
import { Item } from 'components/Summary';
import { withTracker } from 'utils/tracker';
import { APIError, useTransform } from 'utils/use-api';
import { useAppId, useCurrentApp } from 'App/Application';
import { useRuntime, useClusters } from '..';
import InstanceCard from '../InstanceCard';
import { ClusterInfo, isType } from '../types';
import InstanceModal from './InstanceModal';
import MongoStats from './InstanceStats';
import ScaleModal from '../ScaleModal';

export type MongoDBClusterInfo = ClusterInfo<'mongo'>;

export const useInstanceId = () => {
  return useParams<{ id: string }>().id;
};

export const useMongo = () =>
  useTransform(
    useClusters(),
    useCallback((clusters) => (clusters ? clusters.filter(isType('mongo')) : []), [])
  );

const MongoDBInstanceCard = memo(
  ({
    instance,
    onDeleted,
    onUpdated,
  }: {
    instance: MongoDBClusterInfo;
    onDeleted: (id: number) => void;
    onUpdated: (id: number, payload: Partial<MongoDBClusterInfo>) => void;
  }) => {
    const { t } = useTranslation();
    const [currentApp] = useCurrentApp();
    const { name, nodeQuota, storageQuota } = instance;
    const [runtimeInfos] = useRuntime('mongo');
    const [size, unit] = useMemo(() => {
      const quota = nodeQuota.split('-')[1];
      return formatBytesStructured(Number(quota) * 1024 * 1024);
    }, [nodeQuota]);
    const isTest = runtimeInfos?.nodeQuotaMap[nodeQuota].forDevUse;
    const shared = currentApp && instance.appId !== currentApp.appId;
    return (
      <InstanceCard
        quota={isTest ? 'test' : 'standard'}
        instance={instance}
        onDeleted={onDeleted}
        extraInfo={
          <p>
            <Icon name="info" color="green" />
            {t('db.url')}: <span>MONGODB_URL_{name}</span>{' '}
            <Hint content={t('db.url.hint', { db: 'Mongo' })} />
          </p>
        }
      >
        <Item name={t('db.quota.memory')} value={size} suffix={unit} />
        <Item
          name={t('db.storage')}
          value={storageQuota.replace('H', '')}
          suffix={<>GB {!shared && <ScaleModal instanceData={instance} onUpdated={onUpdated} />}</>}
        />
      </InstanceCard>
    );
  }
);

const MongoList = withTracker(
  memo(() => {
    const appId = useAppId();
    const { t } = useTranslation();
    const [currentApp] = useCurrentApp();
    const [instances, { error, loading, reload, delete: _delete, update: _update }] = useMongo();
    const currentAppInstance = useMemo(
      () => instances.filter((instance) => instance.appId === appId),
      [appId, instances]
    );
    const otherAppInstance = useMemo(
      () => instances.filter((instance) => instance.appId !== appId),
      [appId, instances]
    );

    return (
      <Page title={[t('db.mongo'), t('engine')]}>
        <PageContainer>
          <APIError message={t('db.mongo.fetchError')} error={error} retry={reload} />
          <Panel title={t('db.instance.currentAppInstance')}>
            {loading ? (
              <Placeholder line={4} />
            ) : (
              <>
                {currentAppInstance.length > 0 && (
                  <Card.Group>
                    {currentAppInstance.map((instance) => (
                      <MongoDBInstanceCard
                        instance={instance}
                        key={instance.id}
                        onDeleted={_delete}
                        onUpdated={_update}
                      />
                    ))}
                  </Card.Group>
                )}
                {currentAppInstance.length === 0 && <p>{t('label.none')}</p>}
                <p>
                  {currentApp?.isOwner ? (
                    <InstanceModal
                      onCreated={reload}
                      modalProps={{
                        trigger: <Button content={t('db.instance.create')} />,
                      }}
                    />
                  ) : (
                    <HintedContent
                      inline
                      position="top left"
                      content={t('label.onlyOwner.create')}
                      trigger={<Button disabled content={t('db.instance.create')} />}
                    />
                  )}
                </p>
              </>
            )}
          </Panel>
          <Panel title={t('db.instance.otherAppInstance')}>
            {loading ? (
              <Placeholder line={4} />
            ) : otherAppInstance.length === 0 ? (
              <p>{t('label.none')}</p>
            ) : (
              <Card.Group>
                {otherAppInstance.map((instance) => (
                  <MongoDBInstanceCard
                    instance={instance}
                    key={instance.id}
                    onDeleted={_delete}
                    onUpdated={_update}
                  />
                ))}
              </Card.Group>
            )}
          </Panel>
        </PageContainer>
      </Page>
    );
  })
);

export default () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:id`} component={MongoStats} exact />
      <Route component={MongoList} exact />
    </Switch>
  );
};
