import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import TDSClient from '@taptap/tds-msg/es/client';
import { TdsMsg, ERROR_CODE } from '@taptap/tds-msg/es/common';
import { Locale, setLocale } from 'i18n';
import { Message } from 'components/semantic';
import Splash from 'components/Splash';
import request, {
  eventEmitter as requestEE,
  RequestEvent,
  resetCSRFToken,
  resetXSRFToken,
  CSRFTokenStatus,
  XSRFTokenStatus,
  CLIENT_CENTER_VERSION,
} from 'utils/request';
import Application from './Application';
import NotFound from './Others/NotFound';
import { client, changeDCRoute } from './TDSClient';
import { useUser, User, resetUser } from './User';
import { setCurrentUtcOffset } from 'utils';

export const Routes = () => {
  const history = useHistory();
  useEffect(() => {
    history.block((tx, action) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((tx as any).state?.['fromParent']) return undefined;
      changeDCRoute(tx.pathname + tx.search, action);
      return false;
    });
  }, [history]);

  const [ticket, setTicket] = useState<string>();

  const [loginError, setLoginError] = useState<Error>();
  const [user, { error: userError, setData: setUser }] = useUser(false);

  const error = loginError || userError;

  const login = useCallback(
    async (newTicket: string) => {
      try {
        setUser(undefined);
        resetCSRFToken(CSRFTokenStatus.init);
        resetXSRFToken(XSRFTokenStatus.init);
        const newUser = await request<User>(`/client-center/${CLIENT_CENTER_VERSION}/tds/signin`, {
          method: 'POST',
          body: { clientSecret: newTicket },
        });
        resetCSRFToken();
        resetXSRFToken();
        setUser(newUser);
      } catch (err) {
        setLoginError(err);
        client.setError(ERROR_CODE.TICKET_EXPIRED);
      }
    },
    [setUser]
  );

  useEffect(() => {
    const go = (message: TdsMsg<{ locale: Locale; utcOffset?: number }>) => {
      const { path, payload: { locale, utcOffset } = {} } = message;
      if (message.ticket) {
        setTicket(message.ticket);
      }
      if (path) {
        history.push(path, { fromParent: true });
      }
      if (locale) {
        setLocale(locale);
      }
      setCurrentUtcOffset(utcOffset === undefined ? utcOffset : -utcOffset);
    };
    client.on(TDSClient.ClientEventGo, go);
    client.setReady();
    if (process.env.NODE_ENV !== 'production') {
      window['go'] = go;
    }
    return () => {
      client.off(TDSClient.ClientEventGo, go);
    };
  }, [history]);

  useEffect(() => {
    if (ticket) login(ticket);
  }, [login, ticket]);

  useEffect(
    () =>
      requestEE.on(RequestEvent.Unauthornized, () => {
        resetUser();
        client.refreshTicket();
      }),
    []
  );

  if (user) {
    return (
      <Switch>
        <Route path="/" exact component={Splash} />
        <Route path="/apps/:appId" component={Application} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  if (error) {
    return (
      <div className="splash">
        <Message error icon="dizzy outline" content={error.message} />
      </div>
    );
  }

  return <Splash />;
};

const NotFoundPage = () => {
  const location = useLocation();

  useEffect(() => {
    client.setError(404, { path: location.pathname + location.search });
  });

  return <NotFound />;
};
