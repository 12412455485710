import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import { RouteComponentProps } from 'react-router-dom';

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  function Tracker(props: P) {
    useEffect(() => {
      ReactGA.send({ hitType: 'pageview', page: props.match.path });
    }, [props.match.path]);

    return <WrappedComponent {...props} />;
  }

  Tracker.displayName = `withTracker(${
    WrappedComponent.displayName || WrappedComponent.name || 'Anonymous'
  })`;

  return Tracker;
};

type OptionalEventArgs = Omit<UaEventOptions, 'category' | 'action'>;
export const trackEvent = (category: string, action: string, args?: OptionalEventArgs) =>
  ReactGA.event({
    ...args,
    category,
    action,
  });
export const createEventTracker =
  (category: string) => (action: string, args?: OptionalEventArgs) =>
    trackEvent(category, action, args);
