import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { getRegionalLocaleResource } from 'utils';
import Hint from 'components/Hint';
import { RadioGroup } from 'components/Radio';
import { Modal, Form, LinkButton, Button, withModal, Input } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { API_VERSION } from 'utils/request';
import { useAppId } from 'App/Application';
import { useQuota } from '../quota';
import { MaxMemoryPolicy } from '../types';
import { RedisClusterInfo } from '.';

interface Props {
  onCreated?: (params: RedisClusterInfo) => void;
}

const FORM_ID = 'LeanCache';

const MAX_MEMORY_POLICIES: MaxMemoryPolicy[] = [
  'volatile-lru',
  'noeviction',
  'allkeys-lru',
  'allkeys-random',
  'volatile-random',
  'volatile-ttl',
];
const DEFAULT_MAX_MEMORY_POLICIES: MaxMemoryPolicy = 'volatile-lru';

export default withModal<Props>()(({ close, onCreated }) => {
  const { t } = useTranslation();
  const appId = useAppId();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [maxMemoryPolicy, setMaxMemoryPolicy] = useState<string>(DEFAULT_MAX_MEMORY_POLICIES);
  const [quota, { standardQuotaRadioGroup }] = useQuota('redis');
  const onSubmit = async () => {
    try {
      if (quota === undefined) {
        throw new Error('nodeQuota is required');
      }
      setLoading(true);
      const redisClusterInfo = await request<RedisClusterInfo>(
        `/${API_VERSION}/leandb/clusters?appId=${appId}`,
        {
          method: 'POST',
          body: {
            name,
            maxMemoryPolicy,
            nodeQuota: quota,
            storageQuota: quota,
            runtime: 'redis',
          },
        }
      );
      onCreated && onCreated(redisClusterInfo);
      setLoading(false);
      toast.success(t('action.create.successfully'));
      close();
    } catch (error) {
      toast.error(t('action.create.failed'), error.message);
      setLoading(false);
    }
  };
  return (
    <>
      <Modal.Header
        content={
          <Trans
            values={{
              type: t('db.redis'),
            }}
            i18nKey={'db.instance.createWithType'}
          />
        }
      />
      <Modal.Content scrolling>
        <Form id={FORM_ID} onSubmit={onSubmit}>
          <Form.Field required>
            <label htmlFor="instance">{t('label.name')}</label>
            <Input
              name="instance"
              id="instance"
              required
              autoFocus
              pattern="[a-zA-Z][a-zA-Z0-9_]{0,31}$"
              onChange={(e, { value }) => setName(value)}
            />
            <p className="help-block">{t('db.instance.nameHint')}</p>
          </Form.Field>

          <Form.Field required>
            <label htmlFor="max_memory_policy">
              {t('db.redis.evictionStrategy')}{' '}
              <Hint
                hoverable={true}
                content={
                  <Trans i18nKey="db.redis.evictionStrategyHint">
                    Which items are evicted when capacity is reached. See the
                    <a
                      href={getRegionalLocaleResource(
                        {
                          'cn-tds1':
                            // eslint-disable-next-line i18n/no-chinese-character
                            `${process.env.REACT_APP_TDS_DOMAIN}/docs/sdk/engine/database/redis/#数据删除策略`,
                          'ap-sg':
                            // eslint-disable-next-line i18n/no-chinese-character
                            `${process.env.REACT_APP_TDS_DOMAIN}/docs/sdk/engine/database/redis/#cache-eviction-strategy`,
                          'us-w1': {
                            // eslint-disable-next-line i18n/no-chinese-character
                            zh: 'https://docs.leancloud.cn/sdk/engine/database/redis/#数据删除策略',
                            en: 'https://docs.leancloud.cn/en/sdk/engine/database/redis/#cache-eviction-strategy',
                          },
                        },
                        // eslint-disable-next-line i18n/no-chinese-character
                        'https://docs.leancloud.cn/sdk/engine/database/redis/#数据删除策略'
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      documentation
                    </a>
                    for details.
                  </Trans>
                }
              />
            </label>
            <RadioGroup
              required
              name="max_memory_policy"
              value={maxMemoryPolicy}
              radios={MAX_MEMORY_POLICIES.map((policy) => ({
                value: policy,
                hint: t(`db.redis.${policy}`),
              }))}
              onChange={(e, data) => setMaxMemoryPolicy(data.value)}
            />
          </Form.Field>

          <Form.Field required>
            <label htmlFor="max_memory">{t('db.redis.instanceCapacity')}</label>
            {standardQuotaRadioGroup}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton onClick={close} content={t('action.cancel')} />
        <Button
          primary
          type="submit"
          form={FORM_ID}
          content={t(`action.confirmBilling`)}
          loading={loading}
        />
      </Modal.Actions>
    </>
  );
});
