import React from 'react';
import { StrictHeaderProps } from 'semantic-ui-react';
import { Header, Segment } from 'components/semantic';
import styles from './index.module.scss';
import classNames from 'classnames';

export default function Panel({
  title,
  children,
  headerAs = 'h4',
  className,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  headerAs?: StrictHeaderProps['as'];
  className?: string;
}) {
  return (
    <Segment vertical className={classNames(styles.section, className)}>
      {title && <Header as={headerAs}>{title}</Header>}
      <div className={styles.content}>{children}</div>
    </Segment>
  );
}
