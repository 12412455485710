/**
 * 公共验证的 pattern
 */
export const classNamePattern = '[a-zA-Z][a-zA-Z0-9_]{0,119}$';
export const envVariableNamePattern = '[a-zA-Z_][a-zA-Z0-9_]*$';
export const groupNamePattern = '[a-zA-Z][a-zA-Z0-9]{0,44}$';
export const leaderboardPattern = classNamePattern;
export const appNamePattern = '^\\S.{0,126}\\S$';

// form https://regexr.com/68ro8
export const domainPattern = '^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*.)+[a-zA-Z]{2,}$';
export const domainBodyPattern = '^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$';
export const wildcardDomainPattern = '^(\\*\\.)([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*.)+[a-zA-Z]{2,}$';
export const urlPattern = 'https?://.+';
export const objectIdRegexp = /^[a-f\d]{24}$/;
export const certificatePattern = classNamePattern;
