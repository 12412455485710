import { API_VERSION } from 'utils/request';
import React, { memo, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { TabWithPath } from 'components/Tab';
import _ from 'lodash';
import { Message } from 'components/semantic';
import {
  createLazyResourceStore,
  useAPI,
  initialResource,
  useTransform,
  useLocalState,
} from 'utils/use-api';
import { useAppId } from 'App/Application';
import Elasticsearch from './Elasticsearch';
import Mongo from './Mongo';
import MySQL from './MySQL';
import Redis from './Redis';
import { RuntimeInfos, ClusterInfo, RuntimeType, NodeQuotaInfos, StorageQuotaInfo } from './types';

const { useResource: useRuntimeResources, Provider: RuntimesProvider } = createLazyResourceStore(
  () => () =>
    useTransform(
      useAPI<RuntimeInfos>(`/${API_VERSION}/leandb/runtimes`),
      useCallback(
        (runtimes) =>
          runtimes
            ? _.mapValues(runtimes, (runtime) => ({
                ...runtime,
                nodeQuotaMap: _.keyBy(runtime.nodeQuotas, 'id'),
                storageQuotaMap: _.keyBy(runtime.storageQuotas, 'id'),
              }))
            : undefined,
        []
      )
    ),
  initialResource
);

const [, initialExtra] = initialResource;
const { useResource: useClusters, Provider: ClusterProvider } = createLazyResourceStore(
  ({ useTriggered }) =>
    () => {
      const appId = useAppId();
      const triggered = useTriggered();
      const [clusters, { setData, ...extra }] = useLocalState(
        useAPI<ClusterInfo[]>(
          triggered ? `/${API_VERSION}/leandb/apps/${appId}/clusters` : undefined
        )
      );
      const _delete = useCallback(
        (id: number) => {
          setData((preData) => {
            if (!preData) {
              return;
            }
            return preData.filter((cluster) => cluster.id !== id);
          });
        },
        [setData]
      );
      const update = useCallback(
        (id: number, payload: Partial<ClusterInfo>) => {
          setData((preData) => {
            if (!preData) {
              return;
            }
            return preData.map((cluster) =>
              cluster.id === id
                ? {
                    ...cluster,
                    ...payload,
                  }
                : cluster
            );
          });
        },
        [setData]
      );
      const add = useCallback(
        (cluster: ClusterInfo) => {
          setData((preData) => (preData ? [cluster, ...preData] : [cluster]));
        },
        [setData]
      );
      return [
        clusters,
        {
          delete: _delete,
          update,
          add,
          ...extra,
        },
      ] as const;
    },
  [
    [],
    {
      ...initialExtra,
      delete: _.noop,
      update: _.noop,
      add: _.noop,
    },
  ]
);

function useRuntime<T extends RuntimeType>(type: T) {
  return useTransform(
    useRuntimeResources(),
    useCallback(
      (runtimes) => {
        if (runtimes) {
          return runtimes[type] as unknown as RuntimeInfos[T] & {
            storageQuotaMap: _.Dictionary<StorageQuotaInfo>;
            nodeQuotaMap: _.Dictionary<NodeQuotaInfos<T>>;
          };
        }
        return;
      },
      [type]
    )
  );
}

export { useClusters, useRuntime, useRuntimeResources, RuntimesProvider };

const DBMenu = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const [runtimes] = useRuntimeResources();
  const panes = Object.keys(runtimes || []).map((module) => ({
    id: module,
    menuItem: t(`db.${module}`),
  }));

  return <TabWithPath basePath={match.url} panes={panes} defaultActiveIndex={-1} sticky={false} />;
};

export default memo(({ match }: RouteComponentProps) => {
  return (
    <ClusterProvider>
      <DBMenu />
      <Switch>
        <Route path={`${match.path}/redis`} component={Redis} />
        <Route path={[`${match.path}/mysql`, `${match.path}/udb`]} component={MySQL} />
        <Route path={`${match.path}/mongo`} component={Mongo} />
        <Route path={`${match.path}/es`} component={Elasticsearch} />
        <Redirect to={`${match.path}/redis`} />
      </Switch>
    </ClusterProvider>
  );
});

export const InstanceHint = () => {
  return <Message content={<Trans i18nKey="db.instance.quotaHint" />} warning />;
};
