import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { noop } from 'lodash';
import _ from 'lodash';
import request, { API_VERSION } from 'utils/request';
import { useAPI, useTransform, createLazyResourceHelper, ResourceExtra } from 'utils/use-api';
import { Class, DetailedClass, ClassSchema } from 'App/types';
import { useAppId } from '.';

type ClassExtra = {
  loading?: ResourceExtra['loading'];
  error?: ResourceExtra['error'];
  className: string;
};

type UpdateClassAction<T = Class | DetailedClass> = (clazz: T) => T;

const initValue: [
  Array<[Class | DetailedClass | undefined, ClassExtra]>,
  ResourceExtra & {
    update: (className: string, payload: Partial<DetailedClass> | UpdateClassAction) => void;
    add: (clazz: DetailedClass | Class) => void;
    remove: (className: string) => void;
    updatefriendshipACL: (payload: DetailedClass['friendshipACL']) => void;
    reloadClass: (className: string) => void;
    triggerClass: (className: string) => void;
  }
] = [
  [],
  {
    error: undefined,
    loading: false,
    reload: noop,
    abort: noop,
    update: noop,
    add: noop,
    remove: noop,
    updatefriendshipACL: noop,
    reloadClass: noop,
    triggerClass: noop,
  },
];

const Context = createContext<typeof initValue>(initValue);
const { useTriggerEffect, useTriggered } = createLazyResourceHelper();

function pickClassData(data: Class | DetailedClass): Class {
  return {
    _id: data._id,
    'class-type': data['class-type'],
    name: data.name,
    rows_count: data.rows_count,
    bind: data.bind,
  };
}

function pickDetailedClass(data?: Class | DetailedClass) {
  if (data && (data as DetailedClass).createdAt) {
    return data as DetailedClass;
  }
  return;
}

// classes 使用  transform 的原因
// https://github.com/leancloud/uluru-platform/issues/6467#issuecomment-696515048
function transformClasses(props: { results: Class[] } | undefined): Class[] | undefined {
  if (!props?.results) {
    return;
  }
  return _.sortBy(props.results, (klass) => klass.name.toLowerCase()).map(pickClassData);
}

function transformClass(clazz: DetailedClass) {
  const schema = clazz.schema;
  clazz.schema = Object.keys(schema)
    .sort()
    .reduce((previousValue, currentValue) => {
      if (currentValue === 'ACL' && !schema[currentValue].default) {
        const newDefault = clazz.at || {
          '*': {
            read: true,
            write: true,
          },
        };
        previousValue[currentValue] = {
          ...schema[currentValue],
          default: newDefault as unknown as ClassSchema<'ACL'>['default'],
        };
      } else {
        previousValue[currentValue] = schema[currentValue];
      }
      return previousValue;
    }, {} as DetailedClass['schema']);
  return clazz;
}

const Provider: React.FunctionComponent = ({ children }) => {
  const appId = useAppId();
  const trigger = useTriggered();
  const triggeredMap = useRef<{ [key: string]: boolean }>({});
  const [classList, setClassList] = useState<typeof initValue[0]>(initValue[0]);
  const [classes, extra] = useTransform(
    useAPI<{ results: Class[] }>(
      appId && trigger ? `/storage/${API_VERSION}/apps/${appId}/classes` : undefined
    ),
    transformClasses
  );

  useEffect(() => {
    triggeredMap.current = {};
    setClassList([]);
  }, [appId]);

  useEffect(() => {
    if (!classes) {
      return;
    }

    setClassList((preList) => {
      // reload 情况 避免重新请求 class
      return classes.map((clazz) => {
        const findClazz = preList.find(([, { className }]) => className === clazz.name);
        if (findClazz) {
          return [
            {
              ...(findClazz[0] || {}),
              ...clazz,
            },
            {
              ...findClazz[1],
              className: clazz.name,
            },
          ];
        }
        return [
          clazz,
          {
            className: clazz.name,
          },
        ];
      });
    });
  }, [classes]);

  const setClass = useCallback(
    (className: string, payloadExtra: ClassExtra, payload?: Class | DetailedClass) => {
      setClassList((preList) =>
        preList.map(([clazz, clazzExtra]) => {
          return clazzExtra.className === className
            ? [
                payload && clazz
                  ? {
                      ...clazz,
                      ...payload,
                    }
                  : clazz || payload,
                payloadExtra,
              ]
            : [clazz, clazzExtra];
        })
      );
    },
    [setClassList]
  );

  const checkClassStatus = useCallback(
    (checkClassName: string) => {
      const requested = triggeredMap.current[checkClassName];
      const tmpClass = classList.find(([, { className }]) => className === checkClassName);
      const isAdded = tmpClass !== undefined;
      return [requested, isAdded] as const;
    },
    [classList]
  );

  const triggerClass = useCallback(
    (className: string) => {
      const [requested, isAdded] = checkClassStatus(className);
      if (requested) {
        return;
      }
      triggeredMap.current[className] = true;
      if (isAdded) {
        setClass(className, {
          className: className,
          loading: true,
        });
      } else {
        setClassList((preList) => [
          ...preList,
          [
            undefined,
            {
              className: className,
              loading: true,
            },
          ],
        ]);
      }

      request<DetailedClass>(`/storage/${API_VERSION}/apps/${appId}/classes/${className}`)
        .then((data) => {
          setClass(
            className,
            {
              className: className,
              loading: false,
            },
            transformClass(data)
          );
        })
        .catch((err) => {
          setClass(className, {
            className: className,
            error: err,
            loading: false,
          });
        });
    },
    [appId, setClass, checkClassStatus]
  );

  const update = useCallback(
    (className: string, payload: Partial<DetailedClass> | UpdateClassAction) => {
      if (typeof payload === 'function') {
        setClassList((preList) =>
          preList.map(([clazz, clazzExtra]) => [
            clazz?.name === className ? payload(clazz) : clazz,
            clazzExtra,
          ])
        );
      } else {
        setClassList((preList) =>
          preList.map(([clazz, clazzExtra]) => [
            clazz?.name === className
              ? {
                  ...clazz,
                  ...payload,
                }
              : clazz,
            clazzExtra,
          ])
        );
      }
    },
    [setClassList]
  );

  const add = useCallback(
    (clazz: DetailedClass | Class) =>
      setClassList((preList) => [
        ...preList,
        [
          clazz,
          {
            className: clazz.name,
          },
        ],
      ]),
    [setClassList]
  );

  const remove = useCallback(
    (className: string) => {
      setClassList((preList) => preList.filter(([clazz]) => clazz && clazz.name !== className));
      triggeredMap.current[className] = false;
    },
    [setClassList]
  );

  const reloadClass = useCallback(
    (reloadClassName: string) => {
      triggeredMap.current[reloadClassName] = false;
      setClassList((preList) =>
        preList.map(([clazz, clazzExtra]) => {
          return [
            clazz && clazz.name === reloadClassName ? pickClassData(clazz) : clazz,
            clazzExtra,
          ];
        })
      );
    },
    [setClassList]
  );

  // 需要特殊处理 FriendshipACL 需要同时更新 2张表
  const updatefriendshipACL = useCallback(
    (payload: DetailedClass['friendshipACL']) => {
      setClassList((preList) =>
        preList.map(([clazz, clazzExtra]) => [
          clazz && (clazz.name === '_Follower' || clazz.name === '_Followee')
            ? {
                ...clazz,
                friendshipACL: payload,
              }
            : clazz,
          clazzExtra,
        ])
      );
    },
    [setClassList]
  );

  return (
    <Context.Provider
      value={[
        classList,
        {
          ...extra,
          update,
          add,
          remove,
          updatefriendshipACL,
          reloadClass,
          triggerClass,
        },
      ]}
    >
      {children}
    </Context.Provider>
  );
};

const useClassList = () => {
  useTriggerEffect();
  const [classList, { update, add: addClassList, remove, loading, error, reload }] =
    useContext(Context);

  const classes = useMemo(
    () =>
      classList.filter(([clazz]) => clazz !== undefined).map(([clazz]) => pickClassData(clazz!)),
    [classList]
  );

  const add = useCallback((clazz: Class) => addClassList(clazz), [addClassList]);
  return [classes, { loading, error, reload, update, add, remove }] as const;
};

const useClass = (className?: string) => {
  const [classList, { update: classListUpdate, updatefriendshipACL, reloadClass, triggerClass }] =
    useContext(Context);

  useEffect(() => {
    className && triggerClass(className);
  }, [className, triggerClass]);

  const [detailedClass, extra] = useMemo(() => {
    const tmpClass = classList.find(([clazz]) => clazz && clazz.name === className);
    if (tmpClass) {
      const [clazz, classExtra] = tmpClass;
      return [pickDetailedClass(clazz), classExtra];
    }
    return [undefined, { className, loading: false }];
  }, [classList, className]);

  const update = useCallback(
    (payload: Partial<DetailedClass>) => className && classListUpdate(className, payload),
    [className, classListUpdate]
  );

  const reload = useCallback(() => className && reloadClass(className), [className, reloadClass]);

  return [
    detailedClass,
    {
      ...extra,
      reload,
      update,
      updatefriendshipACL,
    },
  ] as const;
};

export { Provider, useClassList, useClass };
