import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import { SemanticICONS } from 'components/semantic';
import styles from './index.module.scss';
export { styles };

interface MenuItem {
  path: string;
  exact?: boolean;
  i18nKey?: string;
  disabled?: boolean;
  docs?: string;
}
export interface MenuItemConfig {
  path?: string;
  exact?: boolean;
  i18nKey?: string;
  disabled?: boolean;

  docs?: string;
  icon?: SemanticICONS;
  items?: Array<MenuItem | false>;
  component?: React.FunctionComponent<{ baseUrl: string }>;
  sub?: Array<MenuItemConfig | false>;
}

function render(
  config: (MenuItemConfig | false)[] | MenuItemConfig | false,
  options: {
    baseUrl: string;
  }
) {
  if (!config) {
    return null;
  }

  if (Array.isArray(config)) {
    return <ul>{config.map((child) => render(child, options))}</ul>;
  }

  const { items, component: Component, sub, path, exact, i18nKey, disabled } = config;
  const { baseUrl } = options;
  const url = exact && !path ? baseUrl : `${baseUrl}/${path}`;
  const title = i18nKey || path;

  if (sub) {
    return (
      <dl>
        {_.compact(sub).map((subMenu) => {
          return (
            <React.Fragment key={subMenu.i18nKey}>
              <dt>
                <Trans i18nKey={subMenu.i18nKey} />
              </dt>
              <dd>{render(subMenu, { baseUrl: url })}</dd>
            </React.Fragment>
          );
        })}
      </dl>
    );
  }
  if (!(items || Component)) {
    const className = classnames(styles.link, {
      [styles.disabled]: disabled,
    });
    const content = disabled ? (
      <span className={className}>
        <Trans i18nKey={title} />
      </span>
    ) : (
      <NavLink to={url} exact={exact} className={className} activeClassName={styles.active}>
        <Trans i18nKey={title} />
      </NavLink>
    );
    return <li key={url}>{content}</li>;
  }

  if (Component) {
    return <Component baseUrl={url} key={url} />;
  }

  return (
    <ul key={url}>
      {items?.map((child) =>
        render(child, {
          baseUrl: url,
        })
      )}
    </ul>
  );
}

export const useMenu = () => {
  useTranslation();
  return { render };
};
