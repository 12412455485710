import { useEffect, useState, useCallback, useRef } from 'react';
import { getGlobalConfig, _getRegionalConfig } from 'config';
import { Region } from 'env';
import { getLocale, Locale } from 'i18n';
import _ from 'lodash';
import { GeoPoint } from 'open-leancloud-storage';

export type Noop = typeof _.noop;
const STORAGE_SEPARATION = '&';
/**
 * example setStorage('value',['appid','test1','test2'])  === setStorage('value','appid&test1&test2')
 * @param value
 * @param keys
 */
export function setStorage(value: string, keys: string | string[]) {
  if (!window.localStorage) {
    return;
  }
  const key = typeof keys === 'string' ? keys : keys.join(STORAGE_SEPARATION);
  localStorage.setItem(key, value);
}
/**
 * example getStorage('value',['appid','test1','test2']) === getStorage('value','appid&test1&test2')
 * @param keys
 */
export function getStorage(keys: string | string[]) {
  if (!window.localStorage) {
    return;
  }
  const key = typeof keys === 'string' ? keys : keys.join(STORAGE_SEPARATION);
  const item = localStorage.getItem(key);
  if (!item) {
    return;
  }
  return item;
}

export function removeStorage(keys: string | string[]) {
  if (!window.localStorage) {
    return;
  }
  const key = typeof keys === 'string' ? keys : keys.join(STORAGE_SEPARATION);
  localStorage.removeItem(key);
}

export const useLocalStorage = (key: string) => {
  const [value, setValue] = useState(() => getStorage(key));
  const updateValueFromStorage = useCallback(() => {
    setValue(getStorage(key));
  }, [key]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateValueFromStorage, [key]);
  const proxedSetValue = useCallback(
    (newValue?: string) => {
      if (newValue === undefined) {
        removeStorage(key);
      } else {
        setStorage(newValue, key);
      }
      setValue(newValue);
    },
    [key]
  );
  return [value, proxedSetValue] as const;
};

/**
 * @deprecated use AV.GeoPoint with strict param check instead
 */
export function converGeoPoint(geoValue: {
  latitude?: number | string;
  longitude?: number | string;
}) {
  let { latitude, longitude } = geoValue;
  if (typeof latitude === 'undefined' && typeof longitude === 'undefined') {
    return null;
  } else {
    latitude = Number(latitude) ? Number(latitude) : 0;
    longitude = Number(longitude) ? Number(longitude) : 0;
    return new GeoPoint({
      latitude: _.clamp(latitude, -90, 90),
      longitude: _.clamp(longitude, -180, 180),
    });
  }
}

const numMap = [
  {
    value: -1,
    unit: '',
  },
  {
    value: 1000,
    unit: 'k',
  },
  {
    value: 1000000,
    unit: 'M',
  },
  {
    value: 1000000000,
    unit: 'G',
  },
];

export function numberToStr(num: number) {
  if (_.isNumber(num)) {
    let i;
    for (i = numMap.length - 1; i > 0; i--) {
      if (num >= numMap[i].value) {
        break;
      }
    }
    if (i > 0) {
      const value = (num / numMap[i].value).toString();
      const match = value.match(/^\d+(?:.\d{0,1})/);
      return `${match ? match[0].replace(/\.0$/, '') : value}${numMap[i].unit}`;
    }
    return num;
  } else {
    return -1;
  }
}

const numberFormat = new Intl.NumberFormat();
export const formatNumber = (num: number) => numberFormat.format(num);
export const formatMoney = (num: number) => {
  const tmp = num.toFixed(2);
  const tmpArray = tmp.split('.');
  return formatNumber(Number(tmpArray[0])) + '.' + tmpArray[1];
};

export function numberToPrecnetage(
  num: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) {
  if (_.isNumber(num)) {
    return new Intl.NumberFormat('default', {
      style: 'percent',
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(num);
  } else {
    return '';
  }
}

const SIZE_MAP = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const formatBytesStructured = (bytes: number, level?: number): [number, string, number] => {
  if (bytes <= 0) {
    return [0, SIZE_MAP[0], 0];
  }
  bytes = Math.ceil(bytes);
  const stage = 1024;
  const i = level ?? Math.floor(Math.log(bytes) / Math.log(stage));
  return [bytes / Math.pow(stage, i), SIZE_MAP[i], i];
};
export const formatBytes = (bytes: number, precision = 4) => {
  const [value, unit, level] = formatBytesStructured(bytes);
  const overPrecise = level === 0 || value >= Math.pow(10, precision);
  return `${overPrecise ? Math.round(value) : value.toPrecision(precision)} ${unit}`;
};

/**
 * 根据路径创建一个下载链接进行下载
 * @param href
 */
export const downloadFile = (href: string, filename?: string) => {
  const link = document.createElement('a');
  link.href = href;
  if (filename) {
    link.download = filename;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const stopPropagation = (e: Event) => e.stopPropagation();

export function useDefault<T>(value: T | undefined, defaultValue: T) {
  const ref = useRef(defaultValue);
  return value ?? ref.current;
}

type LocaleConfig = {
  [key in Locale]: string;
};
type LocaleResource = string | LocaleConfig;
const getLocaleValue = (resource: LocaleResource) => {
  if (typeof resource === 'string') {
    return resource;
  } else {
    const locale = getLocale();
    return resource[locale];
  }
};

export type GlobalLocaleResources = {
  [key in 'cn' | 'intl']: LocaleResource;
};
export const getGlobalLocaleResource = (resources: GlobalLocaleResources) =>
  getLocaleValue(getGlobalConfig(resources));

export type RegionalLocaleResources = {
  [key in Region]: LocaleResource;
};
export function getRegionalLocaleResource(resources: RegionalLocaleResources): string;
export function getRegionalLocaleResource(
  resources: Partial<RegionalLocaleResources>,
  defaultResource: LocaleResource
): string;
export function getRegionalLocaleResource(
  resources: Partial<RegionalLocaleResources>,
  defaultResource?: LocaleResource
): string {
  return getLocaleValue(_getRegionalConfig(resources, defaultResource));
}

export function downloadByData(data: string, fileName: string, mimeType = 'data:text/plain') {
  const a = document.createElement('a');
  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([data], {
        type: mimeType,
      }),
      fileName
    );
  } else if (URL && 'download' in a) {
    a.href = URL.createObjectURL(
      new Blob([data], {
        type: mimeType,
      })
    );
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    // ie 8 9
    window.location.href = 'data:application/octet-stream,' + encodeURIComponent(data);
  }
}

export class UserAbortError extends Error {}

export function convertFileToString(file: File, encoding = 'UTF-8') {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file, encoding);
    reader.onload = ({ target }) => {
      if (target && target.result) {
        const content = target.result.toString();
        resolve(content);
      } else {
        reject(new Error('File is empty'));
      }
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.onabort = () => {
      reject(new Error('abort'));
    };
  });
}

// helper type guard
// usage: values.filter(isNotUndefined)
export const isNotUndefined = <T>(value: T | undefined): value is T => !_.isUndefined(value);
export const isTruthy = <T>(value: T | false | undefined | null | 0): value is T => !!value;

export type PropsType<T> = T extends (props: infer P) => unknown ? P : T;

export * from './time';
