import React, { useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { useSet } from 'react-use';
import classNames from 'classnames';
import _ from 'lodash';
import { Noop } from 'utils';
import { HomeLink } from 'components/HomeLink';
import { useMenu, styles } from 'components/Menu';
import { Accordion, Icon, AccordionTitleProps } from 'components/semantic';
import config from './config';

const AppMenu = (
  props: RouteComponentProps & { foldable: boolean; folded: boolean; toggleFolded: Noop }
) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollIntoView = useCallback(
    (selector: string) =>
      setTimeout(() => {
        containerRef?.current?.querySelector(selector)?.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }, 500),
    []
  );

  const match = useRouteMatch<{ service: string }>(`${props.match.path}/:service`);
  const { service: matchedService } = match?.params || {};
  const [, { add: addService, has: hasService, toggle: toggleService }] = useSet(
    new Set([matchedService])
  );
  useEffect(() => {
    addService(matchedService);
    scrollIntoView(`.${styles.current}`);
  }, [addService, matchedService, scrollIntoView]);

  const handleTitleClick = useCallback<NonNullable<AccordionTitleProps['onClick']>>(
    (e, titleProps) => {
      const service = titleProps['data-service'];
      toggleService(service);
      scrollIntoView(`[data-service="${service}"]+.${styles.content}`);
    },
    [scrollIntoView, toggleService]
  );

  const isOverview = matchedService === undefined;
  // Trim the trailing slash for some pages (a.g. App Overview)
  const baseUrl = _.trimEnd(props.match.url, '/');

  const { foldable, folded, toggleFolded } = props;

  const { render: renderMenu } = useMenu();

  return (
    <div className={styles.menu}>
      <HomeLink />
      <div className={styles.accordionContainer} ref={containerRef}>
        <Accordion inverted exclusive={false} className={styles.accordion}>
          <div className={styles.item}>
            <Accordion.Title
              className={classNames(styles.title, { [styles.current]: isOverview })}
              as={Link}
              to={props.match.url}
            >
              <Icon name="home" className={styles.icon} />
              {t('appOverview')}
            </Accordion.Title>
          </div>
          {config.map((item) => {
            const { i18nKey, icon, path: service } = item;
            const title = i18nKey || service;
            const active = hasService(service);
            const current = matchedService === service;

            return (
              <div key={title} className={styles.item}>
                <Accordion.Title
                  as="button"
                  className={classNames(styles.title, {
                    [styles.current]: current,
                    [styles.active]: active,
                  })}
                  // service prop will be used in handleTitleClick
                  data-service={service}
                  onClick={handleTitleClick}
                >
                  {icon && <Icon name={icon} className={styles.icon} />}
                  {title && t(title)}
                  <span className={styles.space} />
                  <Icon name="angle down" className={styles.arrow} />
                </Accordion.Title>
                <Accordion.Content className={styles.content} active={active}>
                  {active && renderMenu(item, { baseUrl })}
                </Accordion.Content>
              </div>
            );
          })}
        </Accordion>
      </div>
      {foldable && (
        <button className={styles.foldButton} onClick={toggleFolded}>
          <Icon name={folded ? 'pin' : 'arrow to left'} />
        </button>
      )}
    </div>
  );
};

export default withRouter(AppMenu);
