import { GeoPoint } from 'open-leancloud-storage';
export enum AppStatus {
  normal = 1,
  unarchiving = 2,
  archived = 3,
  suspendedDueToInsufficientFunds = 4,
  banned = 5,
}
export interface Application {
  apiWhiteDomains: string[] | '';
  appId: string;
  appKey: string;
  appName: string;
  appRelation: 'creator' | 'cooperator';
  archiveStatus: 0 | 1 | 2;
  bizType: 'dev' | 'biz';
  clientId: number;
  clientUsername: string;
  created: Date;
  description: null | string;
  flags?: string[]; // todo flag enum ?
  hookKey: string;
  id: number;

  isDedicatedDeploy: boolean;

  masterKey: string;
  permission_modules:
    | {
        '*': boolean;
      }
    | {
        [key: string]: {
          [key: string]: boolean;
        };
      }; // module permission key keyof enum ?
  updated: Date;

  // extend properties
  isOwner: boolean;
  isDev: boolean;
  appStatus: AppStatus;
}

export interface PushConfigs {
  cert_file_path: null | {
    [key: string]: string;
  };
  chat_push_msg: null | string;
  ios_push_cert_file_download_url: string;
}
export interface BindData {
  app_id: string;
  class: string;
  delete: boolean;
  read: boolean;
  write: boolean;
}

export interface Class {
  _id: string;
  'class-type': 'normal' | 'log';
  name: string;
  rows_count: number;
  bind?: BindData;
}

export type BasicSchemaType =
  | 'ACL'
  | 'String'
  | 'Number'
  | 'Boolean'
  | 'Date'
  | 'File'
  | 'Array'
  | 'Object'
  | 'GeoPoint'
  | 'Pointer'
  | 'Relation'
  | 'Any';

export type SpecialSchemaType = 'push_deviceProfile';

export type SchemaType = BasicSchemaType | SpecialSchemaType;

export interface Pointer {
  __type: 'Pointer';
  className: string;
  objectId: string;
}

export interface ClassDataItemType {
  ACL: {
    [key: string]: {
      write?: boolean;
      read?: boolean;
    };
  };
  String: string | null;
  Number: number | null;
  Boolean: boolean;
  Date:
    | string
    | {
        __type: 'Date';
        iso: string;
      }
    | null;
  File: {
    url: string;
    name: string;
  } | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Array: any[] | null;
  Object: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  } | null;
  GeoPoint: GeoPoint | null;
  Pointer: Pointer | null;
  Relation: {
    objectId: string;
    className: string;
    __type: 'Relation';
  } | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // Any: any;
  Any: string | null;
}

export interface ClassSchema<T = SchemaType> {
  type: T;
  default?: Extract<T, ClassDataItemType>;
  comment?: string;
  hidden?: boolean;
  read_only?: boolean;
  required?: boolean;
  className?: string;
  auto_increment?: boolean;
  user_private?: boolean;
  v?: number;
}

export interface Schema {
  [key: string]: ClassSchema;
}

export interface DetailedClass extends Class {
  at: null | {
    [key: string]: {
      read: boolean;
      write: boolean;
    }; // key =  _owner | '*'
  };
  createdAt: Date;
  options: { 'row-cache': boolean }; // todo 不确定
  permissions: {
    [key: string]:
      | {
          [key: string]: boolean;
        }
      | {
          roles: string[] | string;
          users: string[] | string;
        };
  };
  friendshipACL?: {
    [key in '*' | 'user' | 'friend']: {
      read: boolean;
      write: boolean;
    };
  };
  schema: Schema;
  updatedAt: Date;
}

export interface ClassData {
  objectId: string;
  ACL: ClassDataItemType['ACL'];
  createdAt: string;
  updatedAt: string;
  [key: string]:
    | ClassDataItemType['String']
    | ClassDataItemType['Number']
    | ClassDataItemType['Boolean']
    | ClassDataItemType['Date']
    | ClassDataItemType['File']
    | ClassDataItemType['Array']
    | ClassDataItemType['Object']
    | ClassDataItemType['GeoPoint']
    | ClassDataItemType['Pointer']
    | ClassDataItemType['Relation']
    | ClassDataItemType['Any']
    | undefined;
}
