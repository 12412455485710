import { useTranslation } from 'react-i18next';
import { useRouteMatch, withRouter, RouteComponentProps } from 'react-router-dom';
import config from '../config';
import { Header } from './Header';

export const TDSEngineMenu = withRouter((props: RouteComponentProps) => {
  const { t } = useTranslation();
  const match = useRouteMatch<{
    service: string;
    subService: string;
    groupName: string;
  }>(`${props.match.path}/:service/:subService/:groupName`);
  const { service: matchedService, subService, groupName } = match?.params || {};
  const currentService = config.find((service) => service.path === matchedService);
  const currentSubService = currentService?.items?.find((item) => item && item.path === subService);

  if (!currentService) {
    return null;
  }
  if (subService === 'groups' && groupName !== 'overview') {
    return null;
  }

  let titleKey = currentService?.i18nKey || '';
  let doc = currentService.docs;

  if (currentSubService) {
    currentSubService.i18nKey && (titleKey = currentSubService.i18nKey);
    currentSubService.docs && (doc = currentSubService.docs);
  }

  return (
    <>
      <Header title={t(titleKey)} document={doc} />
    </>
  );
});
