import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

export const Item = memo(
  ({
    name,
    value,
    newValue,
    valueSuffix,
    suffix,
    className,
  }: {
    name?: React.ReactNode;
    value?: React.ReactNode;
    newValue?: React.ReactNode;
    valueSuffix?: React.ReactNode;
    suffix?: React.ReactNode;
    className?: string;
  }) => {
    return (
      <div className={classNames(styles.item, className)}>
        {name !== undefined && <div className={styles.meta}>{name}</div>}
        <div>
          <span className="spec-value">
            {newValue === value || newValue === undefined ? (
              value
            ) : (
              <>
                <span className={styles.outdatedValue}>{value} ➜ </span>
                {newValue}
              </>
            )}{' '}
            {valueSuffix}
          </span>{' '}
          {suffix}
        </div>
      </div>
    );
  }
);

export const dividedClassName = styles.devided;
