import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Header, Icon, SemanticICONS, Table, Button } from 'components/semantic';
import useURLSearchParam from 'utils/use-url-search-param';
import styles from './index.module.scss';

export interface NoDataProps {
  icon?: SemanticICONS;
  header?: ReactNode;
  extra?: ReactNode;
  className?: string;
}

const NoData = React.memo((props: NoDataProps) => {
  const { t } = useTranslation();
  const { icon, header = t('label.noData'), extra, className } = props;
  return (
    <div className={classNames(styles.noData, className)}>
      <Header icon={icon !== undefined} color="grey">
        {icon && <Icon name={icon} />}
        <Header.Content className={styles.noDataHeader}>{header}</Header.Content>
      </Header>
      {extra}
    </div>
  );
});
export default NoData;

export const NoDataRow = React.memo((props: NoDataProps) => (
  <Table.Row disabled>
    <Table.Cell colSpan="999">
      <NoData {...props} />
    </Table.Cell>
  </Table.Row>
));

export const NoDataWithQuery = React.memo((props: NoDataProps) => {
  const [query, setQuery] = useURLSearchParam('query');
  const { t } = useTranslation();
  return (
    <NoData
      icon="inbox"
      header={query ? t('dataGrid.noFilterData') : t('label.noData')}
      extra={
        query && <Button primary content={t('action.filter.clear')} onClick={() => setQuery()} />
      }
      {...props}
    />
  );
});
