import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { COLOR_DISABLED, DEFAULT_COLORS } from '..';
import { TimeSerialData } from '.';

const DISABLED_SUFFIX = '__DISABLED__';

export default (data: TimeSerialData[], colors: string[] = DEFAULT_COLORS) => {
  const [inactiveLines, setInactiveLines] = useState<{
    [key: string]: boolean;
  }>({});
  useEffect(() => setInactiveLines(() => _.mapValues((data[0] || [])[1], () => false)), [data]);
  const getInactiveProps = useCallback(
    (key: string, index: number) => {
      const disabled = inactiveLines[key];
      return disabled
        ? {
            dataKey: `${key}${DISABLED_SUFFIX}`,
            stroke: COLOR_DISABLED,
            fill: '#00000000',
            strokeWidth: 0,
            dot: false,
            activeDot: false,
          }
        : {
            dataKey: key,
            stroke: colors[index],
            fill: colors[index],
            strokeWidth: 1.5,
          };
    },
    [inactiveLines, colors]
  );
  const onLegendClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ dataKey }: any, index, event: MouseEvent) => {
      const key = dataKey.split(DISABLED_SUFFIX)[0];
      const currentLineInactive = inactiveLines[key];
      const toggleRest = event.altKey;
      if (!toggleRest) {
        return setInactiveLines({
          ...inactiveLines,
          [key]: !currentLineInactive,
        });
      }
      if (currentLineInactive) {
        return setInactiveLines({
          ..._.mapValues(inactiveLines, () => true),
          [key]: false,
        });
      }
      if (Object.values(inactiveLines).filter((inactive) => !inactive).length === 1) {
        return setInactiveLines(_.mapValues(inactiveLines, () => false));
      }
      return setInactiveLines({
        ..._.mapValues(inactiveLines, () => true),
        [key]: false,
      });
    },
    [inactiveLines]
  );
  return {
    inactiveLines,
    getInactiveProps,
    onLegendClick,
  };
};
