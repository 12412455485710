import { API_VERSION } from 'utils/request';
import React, { Children, useCallback } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import { useServerDateRange, Range } from 'components/DateRangeSelect';
import { TimeSerialData } from 'components/StatisticView';
import { useAPI, useTransform } from 'utils/use-api';
import { DISPLAY_MODE } from '../Stats/parts';
import styles from './index.module.scss';
import { MetricData } from './types';

export const StatContainer: React.FunctionComponent<{ displayMode: DISPLAY_MODE }> = ({
  displayMode,
  children,
}) => {
  return (
    <div
      className={classnames({
        [styles.grid]: displayMode === DISPLAY_MODE.grid,
        [styles.list]: displayMode === DISPLAY_MODE.list,
      })}
    >
      {Children.map(children, (childrenNode) => (
        <div className={styles.item}>{childrenNode}</div>
      ))}
    </div>
  );
};

export const transformMetricData = (
  metricData?: MetricData<string>[],
  valueTransform?: (value: number | null) => number | null,
  metricKey = 'metric'
): TimeSerialData[] => {
  if (!metricData) {
    return [];
  }
  const data: {
    [key: string]: {
      [key: string]: number | null;
    };
  } = {};
  metricData.forEach(({ tags, dps }) => {
    Object.entries(dps).forEach(([key, value]) => {
      _.setWith(
        data,
        [key, tags[metricKey]],
        valueTransform ? valueTransform(value) : value,
        Object
      );
    });
  });
  const now = Date.now();
  return Object.entries(data).map(([key, metrics]) => {
    const timestamp = Number(key) * 1000;
    const time = new Date(timestamp);
    return [time, timestamp >= now ? {} : metrics];
  });
};

export function useMetrics<T>(clusterId: string, type: string, from?: Date, to?: Date) {
  return useAPI<MetricData<T>[]>(
    `/${API_VERSION}/leandb/metrics/${clusterId}/${type}`,
    {
      query: {
        from,
        to,
      },
    },
    [from, to],
    !!from && !!to
  );
}

function pickTheLatestDPS<T>(data?: MetricData<T>) {
  return Object.entries(data ? data.dps : {})
    .filter(([ts, value]) => value !== null)
    .sort(([a], [b]) => Number(b) - Number(a))?.[0]?.[1];
}
const LAST_RANGE_OPTIONS: Range[] = ['Last 6 minutes so far'];
export function useTheLatestMetric<T>(clusterId: string, type: string, metric: T) {
  const [from, to] = useServerDateRange(LAST_RANGE_OPTIONS);
  return useTransform(
    useTransform(
      useMetrics<T>(clusterId, type, from, to),
      useCallback((metrics) => metrics?.find((data) => data.tags.metric === metric), [metric])
    ),
    pickTheLatestDPS
  );
}
