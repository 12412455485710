import React, { ReactNode, DetailedHTMLProps, AnchorHTMLAttributes } from 'react';
import _ from 'lodash';
import { Icon, IconProps } from 'components/semantic';
import styles from './index.module.scss';

export interface ExternalLinkProps
  extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  children?: ReactNode;
  iconProps?: (props: IconProps) => IconProps;
}

export default React.memo(
  ({ children, href, iconProps = _.identity, ...rest }: ExternalLinkProps) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}{' '}
        <Icon
          {...iconProps({
            name: 'external alternate',
            className: styles.icon,
          })}
        />
      </a>
    );
  }
);
