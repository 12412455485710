import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { TDS } from 'env';
import { ErrorPage } from 'components/Page';

const NotFound = ({
  operations,
  className,
}: {
  operations?: React.ReactNode;
  className?: string;
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <ErrorPage
      className={className}
      code="404"
      message={t('page.notFount')}
      explaination={
        TDS ? <p className="help-block">Error: {location.pathname + location.search}</p> : undefined
      }
      operations={operations}
    />
  );
};

export default NotFound;
