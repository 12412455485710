import { API_VERSION } from 'utils/request';
import React, { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createLazyResourceStore } from '@leancloud/use-resource';
import _ from 'lodash';
import { ConfirmProps, ControlledConfirm } from 'components/Confirm';
import { toast } from 'components/Toast';
import * as request from 'utils/request';
import { useAPI, useTransform, initialResource, useLocalState } from 'utils/use-api';
import { useAppId } from 'App/Application';

const reduceFlags = (flags: string[] = []): Record<string, boolean> =>
  flags.reduce(
    (result, flag) => ({
      ...result,
      [flag]: true,
    }),
    {}
  );
const [, initialExtra] = initialResource;

const { useResource: useRawFlags, Provider } = createLazyResourceStore(
  ({ useTriggered }) =>
    (props: { appId: string }) =>
      useLocalState(
        useTransform(
          useAPI<string[]>(
            useTriggered() ? `/${API_VERSION}/clients/self/apps/${props.appId}/flags` : undefined
          ),
          reduceFlags
        )
      ),
  [{}, { ...initialExtra, setData: _.noop }]
);

const useFlags = (
  confirmations: {
    [key: string]: ConfirmProps;
  } = {}
) => {
  const $config = useRef(confirmations);
  $config.current = confirmations;
  const appId = useAppId();
  const [readOnly, setReadOnly] = useState(false);
  const [confirmProps, setConfirmProps] = useState<ConfirmProps>({
    header: '',
  });
  const [flags, { setData: setFlags, error, loading, reload }] = useRawFlags();
  const { t } = useTranslation();
  const commitFlag = useCallback(
    async (checked: boolean, flag: string) =>
      request
        .put(
          `/${request.API_VERSION}/clients/self/apps/${appId}/${
            checked ? 'enable' : 'disable'
          }-flag`,
          {
            flag,
          }
        )
        .then(() => {
          setFlags((prevFlags) => ({
            ...prevFlags,
            [flag]: checked,
          }));
        }),
    [appId, setFlags]
  );

  const saveFlag = useCallback(
    async (checked: boolean, flag: string) => {
      setReadOnly(true);
      commitFlag(checked, flag)
        .then(() => {
          setReadOnly(false);
          toast.success(t('action.set.successfully'));
        })
        .catch((err) => {
          setReadOnly(false);
          toast.error(t('action.set.failed'), err);
        });
    },
    [t, commitFlag]
  );

  const setFlag = useCallback(
    (checked: boolean, flag: string) => {
      return new Promise<void>((resove, reject) => {
        const key = `${flag}:${checked}`;
        const confirmation = $config.current[key];
        if (confirmation) {
          setConfirmProps({
            open: true,
            ...confirmation,
            onConfirm: () => {
              saveFlag(checked, flag).then(resove).catch(reject);
              setConfirmProps({ ...confirmation, open: false });
            },
            onCancel: () => setConfirmProps({ ...confirmation, open: false }),
          });
        } else {
          saveFlag(checked, flag).then(resove).catch(reject);
        }
      });
    },
    [saveFlag]
  );
  return [
    flags,
    setFlag,
    {
      commitFlag,
      loading,
      readOnly,
      confirm: <ControlledConfirm {...confirmProps} />,
      error,
      reload,
    },
  ] as const;
};

export { Provider, useFlags };
