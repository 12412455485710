import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingComponentProps } from 'react-loadable';
import { Button, Loader, Message } from 'components/semantic';

import styles from './Loading.module.scss';

const reloadPage = () => window.location.reload();

export default ({ pastDelay, timedOut, error, retry }: LoadingComponentProps) => {
  const { t } = useTranslation();
  if (error) {
    // When the loader has errored
    return (
      <div className={styles.container}>
        <Message error>
          <Message.Header>{t('loadable.exception')}</Message.Header>
          <p>{error.message}</p>
          <Button onClick={reloadPage} content={t('action.reload')} />
        </Message>
      </div>
    );
  } else if (timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div className={styles.container}>
        <Loader active>
          {t('loadable.loading')} <Button onClick={retry} content={t('action.retry')} />
        </Loader>
      </div>
    );
  } else if (pastDelay) {
    // When the loader has taken longer than the delay
    return (
      <div className={styles.container}>
        <Loader active />
      </div>
    );
  } else {
    // When the loader has just started
    return null;
  }
};
