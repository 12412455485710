import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // 管理对文档头的所有更改
import { useTranslation } from 'react-i18next';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import { TDS } from 'env';
import { loadPerEnvMap } from 'perenv.macro';
import { ClientTwoFAContainer, TwoFAContainer } from 'components/2FA';
import { Compose } from 'components/Compose';
import { Provider as RelativeTimeProvider } from 'components/RelativeTime';
import Splash from 'components/Splash';
import { ToastContainer } from 'components/Toast';

import { Provider as AccountMoneyProvider } from './AccountMoney';
import { Provider as AppsProvider } from './Apps';
import { Provider as PricingProvider } from './Pricing';
import { Provider as UserProvider } from './User';
import './app.scss';
import { setSignUpSource } from './utils';

const RoutesModule = loadPerEnvMap(
  {
    true: './Routes.TDS',
    false: './Routes',
  },
  'TDS'
);
const Routes = RoutesModule.Routes;

const HeadManager = () => {
  const [, i18n] = useTranslation();
  return (
    <Helmet titleTemplate="%s · LeanCloud" defaultTitle="LeanCloud">
      <html lang={i18n.language} />
      <meta name="description" content="LeanCloud" />
    </Helmet>
  );
};

const App = () => {
  useEffect(() => {
    setSignUpSource();
  }, []);

  return (
    <Suspense fallback={<Splash />}>
      <Compose
        components={[
          RelativeTimeProvider,
          TDS ? MemoryRouter : BrowserRouter,
          PricingProvider,
          UserProvider,
          AccountMoneyProvider,
          AppsProvider,
        ]}
      >
        <HeadManager />
        <ToastContainer />
        <ClientTwoFAContainer />
        <TwoFAContainer />
        <Routes />
      </Compose>
    </Suspense>
  );
};
export default App;
