import React, { memo, FunctionComponent, DOMAttributes, useCallback, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { getRegionalConfig } from 'config';
import { region } from 'env';
import { getLocale, setLocale, Locale } from 'i18n';
import { getRegionalLocaleResource } from 'utils';
import ExternalLink, { ExternalLinkProps } from 'components/ExternalLink';
import { Avatar } from 'components/Gravatar';
import { Dropdown, Icon } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { CLIENT_CENTER_VERSION } from 'utils/request';
import { resetUser, User as IUser } from 'App/User';
import AppSelect from './AppSelect';
import styles from './header.module.scss';

export { styles };

const currentIndicator = <Icon name="check" className="right floated" />;

export const RegionSelect = memo(() => {
  const { t } = useTranslation();
  return (
    <Dropdown
      className={styles.menuItem}
      trigger={
        <span className={classNames(styles.menuItemTrigger, styles.dropdown)}>
          {getRegionalConfig(
            {
              'cn-n1': t('region.cn-n1'),
              'cn-e1': t('region.cn-e1'),
              'us-w1': t('region.us-w1'),
            },
            undefined
          )}
        </span>
      }
      floating
      icon={false}
      pointing="top left"
    >
      <Dropdown.Menu>
        <Dropdown.Item as="a" href="https://console.leancloud.cn/">
          {t('region.cn-n1')} {region === 'cn-n1' && currentIndicator}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as="a" href="https://console.leancloud.app/">
          {t('region.us-w1')} {region === 'us-w1' && currentIndicator}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
});
export const LocaleSelect = memo(() => {
  const { t } = useTranslation();

  const currentLocale = getLocale();

  const changeLocale = async (locale: Locale) => {
    try {
      await setLocale(locale);
    } catch (err) {
      toast.error(t('lang.switch.fail'), err);
    }
  };

  return (
    <Dropdown
      className={styles.menuItem}
      trigger={
        <span className={classNames(styles.menuItemTrigger, styles.dropdown)}>
          <Icon name="language" size="large" fitted />
        </span>
      }
      floating
      icon={false}
      pointing="top right"
    >
      <Dropdown.Menu>
        {
          // eslint-disable-next-line i18n/no-chinese-character
          <Dropdown.Item onClick={() => changeLocale(Locale.zh)}>
            中文 {currentLocale === Locale.zh && currentIndicator}
          </Dropdown.Item>
        }
        <Dropdown.Item onClick={() => changeLocale(Locale.en)}>
          English {currentLocale === Locale.en && currentIndicator}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
});
const ExternalLinkItem = ({ href, ...rest }: Omit<ExternalLinkProps, 'ref'> & { href: string }) => {
  return (
    <ExternalLink
      href={href}
      iconProps={({ className, ...props }) => ({
        ...props,
        className: classNames(className, 'right floated'),
      })}
      {...rest}
    />
  );
};
export const Help = memo(() => {
  const { t } = useTranslation();
  return (
    <Dropdown
      className={styles.menuItem}
      trigger={
        <span className={classNames(styles.menuItemTrigger, styles.dropdown)}>
          {t('label.help')}
        </span>
      }
      floating
      icon={false}
      pointing="top right"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          as={ExternalLinkItem}
          href={getRegionalLocaleResource(
            {
              'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/store/store-contact/`,
              'us-w1': 'https://www.leancloud.app/help/',
            },
            'https://www.leancloud.cn/support/'
          )}
        >
          {t('header.help.center')}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>{t('header.help.quickAccess')}</Dropdown.Header>
        <Dropdown.Item
          as={ExternalLinkItem}
          href={getRegionalLocaleResource(
            {
              'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/`,
              'us-w1': {
                zh: 'https://docs.leancloud.cn/',
                en: 'https://docs.leancloud.cn/en/',
              },
            },
            'https://docs.leancloud.cn/'
          )}
        >
          {t('header.help.documents')}
        </Dropdown.Item>
        <Dropdown.Item
          as={ExternalLinkItem}
          href={getRegionalLocaleResource(
            {
              'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/store/store-contact/`,
              'us-w1': 'https://www.reddit.com/r/LeanCloud/',
            },
            'https://forum.leancloud.cn'
          )}
        >
          {t('header.help.forum')}
        </Dropdown.Item>
        <Dropdown.Item
          as={ExternalLinkItem}
          // TODO: use current_support_service from /1.1/payment/money-account
          disabled={false}
          href={getRegionalLocaleResource(
            {
              'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/store/store-contact/`,
              'us-w1': 'https://ticket.leancloud.app/',
            },
            'https://www.leanticket.cn'
          )}
        >
          {t('header.help.ticket')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
});
export const User = memo(({ user }: { user: IUser }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const logout = useCallback(async () => {
    try {
      await request(`/client-center/${CLIENT_CENTER_VERSION}/signout`, {
        method: 'POST',
      });
      resetUser();
      history.push(`/login`);
    } catch (error) {
      return toast.error(t('action.logout.failed'), error);
    }
  }, [history, t]);
  return (
    <Dropdown
      className={styles.menuItem}
      trigger={
        <span
          className={classNames(styles.menuItemTrigger, styles.dropdown)}
          title={`${user.email}${user.internal ? ' 🧡' : ''}`}
        >
          <Avatar email={user.email} size={50} className={styles.avatar} />
          <span className={styles.username}>{user.username}</span>
        </span>
      }
      floating
      icon={false}
      pointing="top right"
    >
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/account">
          {t('account.settings')}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/account/finance">
          {t('finance')}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={logout}>{t('action.logout')}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
});
export { AppSelect };
export const Header: FunctionComponent<{ className?: string; homeLink?: ReactNode }> = ({
  className,
  homeLink,
  children,
}) => {
  return (
    <div className={classNames(className, styles.header)}>
      {homeLink}
      <div className={classNames(styles.content, 'fill-space')}>{children}</div>
    </div>
  );
};
export const MenuItem: FunctionComponent<{
  className?: string;
  onClick?: DOMAttributes<HTMLDivElement>['onClick'];
}> = ({ className, onClick, children }) => {
  return (
    <div className={classNames(styles.menuItem, className)} onClick={onClick}>
      <span className={styles.menuItemTrigger}>{children}</span>
    </div>
  );
};
