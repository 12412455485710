import { useMemo, useCallback } from 'react';
import { serverUtcOffset } from 'config';
import { mapValues } from 'lodash';
import moment from 'moment';
import { YAxis } from 'recharts';
import { TimeSerialData, DualYAxis, X_DATA_KEY } from '.';

// 2020-08-11 -> 2020-08-11T00:00:00+08
const fixAmbiguousDate = (date: string) =>
  `${date}T00:00:00${serverUtcOffset > 0 ? '+' : '-'}${serverUtcOffset
    .toString()
    .padStart(2, '0')}`;

// We accept:
// 1597104000000
// "2020-08-11"
// "2020-08-11T00:00:00Z"
const parseDate = (date: TimeSerialData[0]) => {
  switch (typeof date) {
    case 'number':
      return date;
    case 'string':
      const isAmbiguouse = date.length === 10;
      return moment(isAmbiguouse ? fixAmbiguousDate(date) : date).valueOf();
    default:
      return moment(date).valueOf();
  }
};

export function useChartData(data: TimeSerialData[]) {
  return useMemo(
    () =>
      data.map(([date, payload]) => ({
        ...payload,
        [X_DATA_KEY]: parseDate(date),
      })),
    [data]
  );
}

export function useNames(
  namesConfig: { [key: string]: string } | ((key: string) => string),
  data: TimeSerialData[]
) {
  return useMemo(
    () =>
      typeof namesConfig === 'function'
        ? mapValues(data[0][1], (value, key) => namesConfig(key))
        : namesConfig,
    [data, namesConfig]
  );
}

export function useDualYAxis({
  relatedkeys,
  unit,
  tickFormatter,
  formatters,
  yAxisId = 'right',
  ...rest
}: DualYAxis) {
  const unitWithSpace = unit ? ` ${unit}` : unit;

  const getSerieYAxisId = useCallback(
    (key: string, defaultYAxisId) => {
      return relatedkeys?.includes(key) ? yAxisId : defaultYAxisId;
    },
    [relatedkeys, yAxisId]
  );

  const node = useMemo(() => {
    return (
      <YAxis
        {...rest}
        tickFormatter={formatters?.yAxisTick || tickFormatter}
        orientation="right"
        unit={unitWithSpace}
        yAxisId={yAxisId}
      />
    );
  }, [formatters?.yAxisTick, rest, tickFormatter, unitWithSpace, yAxisId]);

  return [node, { getSerieYAxisId }] as const;
}
