import { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';

export const useApplyChange = () => {
  const history = useHistory();
  const location = useLocation();
  return (...changeDescriptors: Array<(search?: string) => string>) =>
    history.push({
      search: compose<string>(...changeDescriptors)(location.search),
    });
};

export const useApplyChanges = () => {
  const history = useHistory();
  const location = useLocation();
  return useCallback(
    (changeDescriptors: Array<(search?: string) => string>, replace: boolean = false) =>
      history[replace ? 'replace' : 'push']({
        search: compose<string>(...changeDescriptors)(location.search),
      }),
    [history, location.search]
  );
};

export const generateChange =
  (searchKey: string, searchValue?: string) => (searchString?: string) => {
    const search = new URLSearchParams(searchString);
    if (typeof searchValue === 'undefined') {
      search.delete(searchKey);
    } else {
      search.set(searchKey, searchValue);
    }
    return search.toString();
  };

export default (searchKey: string) => {
  const location = useLocation();
  const applyChanges = useApplyChanges();

  const value = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const keyValue = urlParams.get(searchKey);
    return keyValue !== null ? keyValue : undefined;
  }, [location.search, searchKey]);

  const change = useCallback(
    (searchValue?: string) => generateChange(searchKey, searchValue),
    [searchKey]
  );

  const setValue = useCallback(
    (searchValue?: string, replace?: boolean) => applyChanges([change(searchValue)], replace),
    [applyChanges, change]
  );

  return [value, setValue, change] as [typeof value, typeof setValue, typeof change];
};
