import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { createNanoEvents } from 'nanoevents';
import { Noop } from 'utils';
import { Button, Form, Input, LinkButton, Modal } from 'components/semantic';
import { toast } from 'components/Toast';
import { consoleAPIServer } from 'config';
import request, {
  enhancedFetch,
  ExtendedFetchOptions,
  CLIENT_CENTER_VERSION,
  API_VERSION,
} from 'utils/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Result = [Response, any];

interface FetchOptions {
  url: string;
  options: ExtendedFetchOptions;
  init: RequestInit;
}

const clientEE = createNanoEvents<{
  fetchOptions: (props: FetchOptions) => void;
  promise: (promise: [(result: Result) => void, Noop]) => void;
}>();

export const verifyClient2FA = (fetchOptions: FetchOptions) =>
  new Promise<Result>((resolve, reject) => {
    clientEE.emit('fetchOptions', fetchOptions);
    clientEE.emit('promise', [resolve, reject]);
  });

export const ClientTwoFAContainer = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [fetchOptions, setFetchOptions] = useState<FetchOptions>();
  const [[resolve, reject], setPromiseCallbacks] = useState<[(result: Result) => void, Noop]>([
    _.noop,
    _.noop,
  ]);
  useEffect(() => clientEE.on('fetchOptions', setFetchOptions), []);
  useEffect(() => clientEE.on('promise', setPromiseCallbacks), []);

  const close = () => {
    setFetchOptions(undefined);
    reject(new Error(t('2FA.canceled')));
  };

  const commit2FA = async () => {
    if (!fetchOptions) {
      return;
    }
    try {
      await request(`/client-center/${CLIENT_CENTER_VERSION}/do2fa`, {
        method: 'POST',
        body: {
          code,
        },
      });

      const { url, options, init } = fetchOptions;
      const res = await enhancedFetch(url, options, init);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let json: any = {};
      try {
        json = await res.json();
      } catch (error) {}
      resolve([res, json]);
      setFetchOptions(undefined);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal open={fetchOptions !== undefined} onClose={close}>
      <Modal.Header>{t('2FA')}</Modal.Header>
      <Modal.Content>
        <Form id="2fa" onSubmit={commit2FA}>
          <Form.Field required>
            <label htmlFor="code">{t('2FA.verificationCode')}</label>
            <Input
              id="code"
              value={code}
              required
              autoFocus
              placeholder="123456"
              pattern="\d{6}"
              title={t('validation.digits', { number: 6 })}
              onChange={(e, data) => {
                setCode(data.value);
              }}
            />
            <div className="help-block">
              <Trans
                i18nKey="2FA.tipInfo"
                components={{ Recovery: <Link to="/2fa-recovery" target="_blank" /> }}
              />
            </div>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton onClick={close} content={t('action.cancel')} />
        <Button type="submit" form="2fa" content={t('action.confirm')} primary />
      </Modal.Actions>
    </Modal>
  );
};

const ee = createNanoEvents<{
  token: (token: string) => void;
  promise: (promise: [(result: Result) => void, Noop]) => void;
}>();

export const verify2FA = (token: string) =>
  new Promise<Result>((resolve, reject) => {
    ee.emit('token', token);
    ee.emit('promise', [resolve, reject]);
  });

export const TwoFAContainer = () => {
  const { t } = useTranslation();
  const [token, setToken] = useState<string>();
  const [[resolve, reject], setPromiseCallbacks] = useState<[(result: Result) => void, Noop]>([
    _.noop,
    _.noop,
  ]);
  useEffect(() => ee.on('token', setToken), []);
  useEffect(() => ee.on('promise', setPromiseCallbacks), []);

  const close = () => {
    setToken(undefined);
    reject(new Error(t('2FA.canceled')));
  };

  const [code, setCode] = useState('');

  const commit2FA = () => {
    enhancedFetch(`${consoleAPIServer}/${API_VERSION}/do2fa`, {
      method: 'POST',
      body: {
        token,
        code: Number(code),
      },
    })
      .then(async (response) => {
        const json = await response.json();

        if (json && json.code && json.code !== 0 && json.token) {
          setToken(json.token);
          throw new Error(json.error);
        }
        resolve([response, json]);
        setToken(undefined);
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <Modal open={token !== undefined} onClose={close}>
      <Modal.Header>{t('2FA')}</Modal.Header>
      <Modal.Content>
        <Form id="2fa" onSubmit={commit2FA}>
          <Form.Field required>
            <label htmlFor="code">{t('2FA.verificationCode')}</label>
            <Input
              id="code"
              value={code}
              required
              autoFocus
              placeholder="123456"
              pattern="\d{6}"
              title={t('validation.digits', { number: 6 })}
              onChange={(e, data) => {
                setCode(data.value);
              }}
            />
            <div className="help-block">
              <Trans
                i18nKey="2FA.tipInfo"
                components={{ Recovery: <Link to="/2fa-recovery" target="_blank" /> }}
              />
            </div>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton onClick={close} content={t('action.cancel')} />
        <Button type="submit" form="2fa" content={t('action.confirm')} primary />
      </Modal.Actions>
    </Modal>
  );
};
