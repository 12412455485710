import React, { memo, useMemo, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { formatBytesStructured, getRegionalLocaleResource } from 'utils';
import ExternalLink from 'components/ExternalLink';
import Hint, { HintedContent } from 'components/Hint';
import Page, { PageContainer } from 'components/Page';
import Panel from 'components/Panel';
import Placeholder from 'components/Placeholder';
import { Button, Card, Icon, Message, Loader } from 'components/semantic';
import { Item } from 'components/Summary';
import { withTracker } from 'utils/tracker';
import { APIError, useTransform } from 'utils/use-api';
import { useAppId, useCurrentApp } from 'App/Application';
import { useRuntime, useClusters } from '..';
import InstanceCard from '../InstanceCard';
import { ClusterInfo, isType } from '../types';
import { useTheLatestMetric } from '../utils';
import InstanceModal from './InstanceModal';
import RedisStats, { RedisMetric } from './InstanceStats';
import ScaleModal from '../ScaleModal';

export type RedisClusterInfo = ClusterInfo<'redis'>;
export const useRedis = () =>
  useTransform(
    useClusters(),
    useCallback((clusters) => (clusters ? clusters.filter(isType('redis')) : []), [])
  );

export const useInstanceId = () => {
  return useParams<{ id: string }>().id;
};

const CacheInstanceCard = memo(
  ({
    instance,
    onDeleted,
    onUpdated,
  }: {
    instance: RedisClusterInfo;
    onDeleted: (id: number) => void;
    onUpdated: (id: number, payload: Partial<RedisClusterInfo>) => void;
  }) => {
    const { t } = useTranslation();
    const { name, nodeQuota: nodeQuotaId, maxMemoryPolicy, id } = instance;
    const [runtimeInfo] = useRuntime('redis');
    // const [usedMemory, usedMemoryUnit] = info ? formatBytesStructured(info.used_memory) : [];
    const nodeQuota = runtimeInfo?.nodeQuotaMap[nodeQuotaId];
    const [memoryCapacity, capacityUnit, level] = nodeQuota
      ? formatBytesStructured(nodeQuota.memory * 1024 * 1024)
      : ['?', undefined, 2];
    const [usedMemoryBytes, { loading: loadingUsedMemory }] = useTheLatestMetric<RedisMetric>(
      id.toString(),
      'redis/memory',
      'used_memory'
    );
    const [usedMemory] = formatBytesStructured(usedMemoryBytes ?? 0, level);
    const [connections, { loading: loadingConnections }] = useTheLatestMetric<RedisMetric>(
      id.toString(),
      'redis/connections',
      'clients'
    );
    const [currentApp] = useCurrentApp();
    const shared = currentApp && instance.appId !== currentApp.appId;
    return (
      <InstanceCard
        onDeleted={onDeleted}
        instance={instance}
        extraInfo={
          <>
            <p>
              <Icon name="info" color="green" />
              {t('db.url')}: <span>REDIS_URL_{name}</span>
              <Hint
                content={t('db.url.hint', {
                  db: 'Redis',
                })}
              />
            </p>
            <p>
              <Icon name="info" color="green" />
              {t('db.redis.evictionStrategy')}: {maxMemoryPolicy}
              <Hint content={t(`db.redis.${maxMemoryPolicy}`)} />
            </p>
          </>
        }
      >
        <Item
          name={t('db.redis.currentConnection')}
          value={loadingConnections ? <Loader active inline size="tiny" /> : connections}
        />
        <Item
          name={t('db.redis.usage')}
          value={
            <>
              {loadingUsedMemory ? <Loader active inline size="tiny" /> : usedMemory.toFixed(2)} /{' '}
              {memoryCapacity}
            </>
          }
          suffix={
            <>
              {capacityUnit}{' '}
              {!shared && <ScaleModal instanceData={instance} onUpdated={onUpdated} />}
            </>
          }
        />
      </InstanceCard>
    );
  }
);

const RedisList = withTracker(
  memo(() => {
    const appId = useAppId();
    const { t } = useTranslation();
    const [currentApp] = useCurrentApp();
    const [instances, { error, loading, reload, delete: _delete, update, add }] = useRedis();
    const currentAppInstance = useMemo(
      () => instances.filter((instance) => instance.appId === appId),
      [appId, instances]
    );

    const otherAppInstance = useMemo(
      () => instances.filter((instance) => instance.appId !== appId),
      [appId, instances]
    );
    return (
      <Page title={[t('db.redis'), t('engine')]}>
        <PageContainer>
          <APIError message={t('db.redis.fetchError')} error={error} retry={reload} />
          <Panel title={t('db.instance.currentAppInstance')}>
            {loading ? (
              <Placeholder line={4} />
            ) : (
              <>
                {currentAppInstance.length > 0 && (
                  <Card.Group>
                    {currentAppInstance.map((instance) => (
                      <CacheInstanceCard
                        instance={instance}
                        key={instance.id}
                        onDeleted={_delete}
                        onUpdated={update}
                      />
                    ))}
                  </Card.Group>
                )}
                {currentAppInstance.length === 0 && <p>{t('label.none')}</p>}
                <p>
                  {currentApp?.isOwner ? (
                    <InstanceModal
                      onCreated={add}
                      modalProps={{
                        trigger: <Button content={t('db.instance.create')} />,
                      }}
                    />
                  ) : (
                    <HintedContent
                      inline
                      position="top left"
                      content={t('label.onlyOwner.create')}
                      trigger={<Button disabled content={t('db.instance.create')} />}
                    />
                  )}
                </p>
              </>
            )}
          </Panel>

          <Panel title={t('db.instance.otherAppInstance')}>
            {loading ? (
              <Placeholder line={4} />
            ) : otherAppInstance.length === 0 ? (
              <p>{t('label.none')}</p>
            ) : (
              <Card.Group>
                {otherAppInstance.map((instance) => (
                  <CacheInstanceCard
                    instance={instance}
                    key={instance.id}
                    onDeleted={_delete}
                    onUpdated={update}
                  />
                ))}
              </Card.Group>
            )}
          </Panel>
          <Message
            info
            content={
              <Trans i18nKey="db.redis.hint">
                LeanCache uses Redis to provide high-performance and highly-available key-value
                store, mainly used as caches. See the
                <ExternalLink
                  href={getRegionalLocaleResource(
                    {
                      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/sdk/engine/database/redis/`,
                      'ap-sg': `${process.env.REACT_APP_TDS_DOMAIN}/docs/sdk/engine/database/redis/`,
                      'us-w1': {
                        zh: 'https://docs.leancloud.cn/sdk/engine/database/redis/',
                        en: 'https://docs.leancloud.cn/en/sdk/engine/database/redis/',
                      },
                    },
                    'https://docs.leancloud.cn/sdk/engine/database/redis/'
                  )}
                >
                  documentation
                </ExternalLink>
                for details.
              </Trans>
            }
          />
        </PageContainer>
      </Page>
    );
  })
);

export default () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:id`} component={RedisStats} exact />
      <Route component={RedisList} exact />
    </Switch>
  );
};
