import React, { memo } from 'react';
import classNames from 'classnames';
import { MultiplayerServerEnabled } from 'config/feature-flags';
import { useMenu } from 'components/Menu';
import { styles } from 'components/Menu';
import { Loader } from 'components/semantic';
import { useMultiplayerServerGroup } from '../MultiplayerServerGroup';

const stats = {
  path: 'stats',
  i18nKey: 'label.stat',
};
const settings = {
  path: 'settings',
  i18nKey: 'label.settings',
};
const deploy = {
  path: 'deploy',
  i18nKey: 'action.deploy',
};
const logs = {
  path: 'logs',
  i18nKey: 'label.logs',
};
const server = {
  path: 'server',
  i18nKey: 'multiplayer.dedicatedServer',
};

export default ({ baseUrl }: { baseUrl: string }) => {
  const { render } = useMenu();

  if (MultiplayerServerEnabled) {
    return <MultiplayerServerMenu baseUrl={baseUrl} />;
  }

  return render([stats], { baseUrl });
};

const MultiplayerServerMenu = memo(({ baseUrl }: { baseUrl: string }) => {
  const [group, { loading }] = useMultiplayerServerGroup();
  const { render } = useMenu();

  if (loading) {
    return (
      <div className={classNames(styles.secondary, styles.link)}>
        <Loader active inline size="tiny" />
      </div>
    );
  }

  return render(group?.enabled ? [deploy, stats, logs, settings] : [stats, server], {
    baseUrl,
  });
});
