import React, { ErrorInfo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Message } from 'components/semantic';

export class ErrorBoundary extends React.Component<{
  errorMessageComponent?: React.FunctionComponent<{ error: Error }>;
}> {
  public state: {
    error?: Error;
  } = {};

  public static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    Sentry.captureException(error);
  }

  public render() {
    const { error } = this.state;
    const { errorMessageComponent: ErrorMessageComponent = ErrorMessage } = this.props;
    if (error) {
      return <ErrorMessageComponent error={error} />;
    }
    return this.props.children;
  }
}

export const ErrorMessage: React.FunctionComponent<{ error: Error }> = ({ error }) => {
  const { t } = useTranslation();
  return (
    <div className="splash">
      <Message error icon="dizzy outline" header={t('component.error')} content={error.message} />
    </div>
  );
};
