import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { loadPerEnvMap } from 'perenv.macro';

import App from './App';
import './i18n';
import { region } from './env';
import * as serviceWorker from './serviceWorker';

loadPerEnvMap(
  {
    true: '@leancloud/styles/dist-tds/semantic.css',
    false: '@leancloud/styles/dist/semantic.css',
  },
  'TDS_STYLE'
);

if (process.env.NODE_ENV === 'production') {
  // Sentry SDK should be inited as soon as possible
  Sentry.init({
    dsn: 'https://c6f64714847a4b0190aeef6099c7ab95@sentry.gametaptap.com/94',
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_VERSION,
    initialScope: {
      tags: { region },
    },
    // https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
    denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
    beforeSend(event) {
      // https://sentry.tapsvc.com/organizations/taptap/issues/5280898/?project=94&query=_gauges+is+not+defined&referrer=issue-stream&sort=new&statsPeriod=14d
      if (event.request?.headers?.['User-Agent']?.includes('Beijing Threatbook Technology Co')) {
        return null;
      }
      return event;
    },
    ignoreErrors: ['TypeError: Failed to fetch'],
  });

  const trackingID = process.env.REACT_APP_GA_TRACKING_ID;
  if (trackingID) {
    ReactGA.initialize(trackingID);
  } else {
    console.error('Missing tracking ID.');
  }
}

const ROOT_NODE = document.getElementById('root');

ReactDOM.render(<App />, ROOT_NODE);

serviceWorker.unregister();
