import React, { useState, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMountedState } from 'react-use';
import { uniqueId } from 'lodash';
import { Noop } from 'utils';
import {
  Button,
  Confirm as SUIConfirm,
  Form,
  Input,
  LinkButton,
  Modal,
  ModalContentProps,
  ModalHeaderProps,
  SemanticShorthandItem,
  ModalProps,
  StrictConfirmProps as SUIConfirmProps,
} from 'components/semantic';
import { toast } from 'components/Toast';

export interface ConfirmProps extends Omit<SUIConfirmProps, 'onClose' | 'header' | 'content'> {
  confirmButtonText?: string;
  danger?: boolean;
  header: SemanticShorthandItem<ModalHeaderProps>;
  content?: SemanticShorthandItem<ModalContentProps>;
  onConfirm?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: SUIConfirmProps
  ) => unknown | Promise<unknown>;
  onConfirmError?: (error: Error, hide: Noop) => unknown;
}

export const ControlledConfirm = (props: ConfirmProps) => {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const {
    onCancel,
    confirmButtonText = t('action.confirm'),
    danger = true,
    header,
    content,
    onConfirm,
    ...rest
  } = props;
  const [loading, setLoading] = useState(false);
  return (
    <SUIConfirm
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
      }}
      cancelButton={
        danger ? (
          <Button autoFocus content={t('action.cancel')} />
        ) : (
          <LinkButton content={t('action.cancel')} />
        )
      }
      confirmButton={
        <Button
          autoFocus={!danger}
          negative={danger}
          content={confirmButtonText}
          loading={loading}
          disabled={loading}
        />
      }
      centered={false}
      onConfirm={
        onConfirm
          ? (e, data) => {
              const result = onConfirm(e, data);
              if (result) {
                setLoading(true);
                Promise.resolve(result).finally(() => {
                  if (isMounted()) {
                    setLoading(false);
                  }
                });
              }
            }
          : undefined
      }
      {...rest}
      header={header ? <Modal.Header>{header}</Modal.Header> : undefined}
      content={
        content ? <div className="content">{content}</div> : <div style={{ marginTop: -1 }} />
      }
      onCancel={onCancel}
      // onClose 与 onCancel 分开并无意义，这里我们只支持 onCancel
      onClose={(e, data) => {
        if (onCancel) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onCancel(e as any, data);
        }
      }}
    />
  );
};

const Confirm = (props: ConfirmProps) => {
  const {
    onCancel,
    onConfirm,
    onConfirmError = (error: Error) => toast.error('Operation failed', error),
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const isMounted = useMountedState();
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };
  return (
    <ControlledConfirm
      open={open}
      onOpen={show}
      {...rest}
      onConfirm={(e, data) => {
        if (!onConfirm) {
          hide();
          return;
        }
        return new Promise((resolve) => resolve(onConfirm(e, data))).then(
          () => {
            if (isMounted()) {
              hide();
            }
          },
          (error) => onConfirmError(error, hide)
        );
      }}
      onCancel={(e, data) => {
        hide();
        if (onCancel) {
          onCancel(e, data);
        }
      }}
    />
  );
};

export default Confirm;

interface ConfirmWithPasswordProps {
  onConfirm: (password: string, close: () => void) => unknown | Promise<unknown>;
  confirmButtonText?: string;
  danger?: boolean;
  header: SemanticShorthandItem<ModalHeaderProps>;
  content?: SemanticShorthandItem<ModalContentProps>;
}

const ConfirmWithPasswordModal = memo<
  ConfirmWithPasswordProps & {
    close: () => void;
  }
>(({ close, header, onConfirm, content, danger, confirmButtonText }) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [password, setPassword] = useState('');
  const [formId] = useState(uniqueId);
  const isMounted = useMountedState();
  const reClose = useCallback(() => {
    if (isMounted()) {
      close();
    }
  }, [close, isMounted]);
  return (
    <>
      {header && <Modal.Header content={header} />}
      <Modal.Content>
        {content && <p>{content}</p>}
        <Form
          id={formId}
          onSubmit={async () => {
            if (onConfirm) {
              setSubmitting(true);
              Promise.resolve(onConfirm(password, reClose)).finally(() => {
                if (isMounted()) {
                  setSubmitting(false);
                }
              });
            }
          }}
        >
          <Form.Field required>
            <label htmlFor="password">{t('label.password')}</label>
            <Input
              id="password"
              type="password"
              required
              autoFocus
              value={password}
              onChange={(e, data) => setPassword(data.value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton onClick={close} content={t('action.cancel')} />
        <Button
          type="submit"
          loading={submitting}
          disabled={submitting}
          form={formId}
          negative={danger}
          content={confirmButtonText || t('action.confirm')}
        />
      </Modal.Actions>
    </>
  );
});

export const ConfirmWithPassword = memo<ConfirmWithPasswordProps & ModalProps>(
  ({ onConfirm, confirmButtonText, danger, header, content, ...rest }) => {
    return (
      <Modal size="small" {...rest}>
        {({ close }) => (
          <ConfirmWithPasswordModal
            close={close}
            onConfirm={onConfirm}
            confirmButtonText={confirmButtonText}
            danger={danger}
            header={header}
            content={content}
          />
        )}
      </Modal>
    );
  }
);
