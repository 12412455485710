import { useEffect, useCallback, useState } from 'react';
import { createLazyResourceStore, createResourceStore } from '@leancloud/use-resource';
import { platformAPIServer } from 'config';
import { TDS } from 'env';
import * as LC from 'open-leancloud-storage';
import { initialResource, useTransform, Resource, useAPI } from 'utils/use-api';
import { useApps, useLRUApps, parseAppData } from 'App/Apps';
import { Application, PushConfigs } from 'App/types';
import { useUser } from 'App/User';
import { CLIENT_CENTER_VERSION, API_VERSION } from 'utils/request';

let SDKApp: LC.App | undefined;
const useInitSDK = ([originalApp, extra]: Resource<Application | undefined>): Resource<
  Application | undefined
> => {
  // 在这一层额外维护一个 app 状态是因为由于 useEffect 的异步特性，
  // 当 originalApp 变化的时候，其他组件调用 SDK 可能会在重新初始化 SDK 之前
  // 这里保证了 SDK 完成重新初始化之前其他组件看到的 currentApp 为 undefined
  const [app, setApp] = useState(originalApp);
  useEffect(() => {
    if (originalApp) {
      SDKApp = LC.init({
        appId: originalApp.appId,
        appKey: originalApp.appKey,
        masterKey: originalApp.masterKey,
        hookKey: originalApp.hookKey,
        serverURL: platformAPIServer,
        useMasterKey: true,
      });
    }
    setApp(originalApp);
  }, [originalApp]);
  if (originalApp === app || (originalApp && app && originalApp.appId === app.appId)) {
    return [app, extra] as const;
  }
  SDKApp = undefined;
  return [undefined, { ...extra, loading: true }] as const;
};

const useAddToRecentlyUsedApps = (
  resource: Resource<Application | undefined>
): Resource<Application | undefined> => {
  const [app] = resource;
  const [LRUAppIds, setLRUAppIds] = useLRUApps();
  useEffect(() => {
    if (app) {
      setLRUAppIds([app.id, ...LRUAppIds.filter((id) => id !== app.id)].slice(0, 6));
    }
  }, [LRUAppIds, app, setLRUAppIds]);
  return resource;
};

const { useResource, Provider: CurrentAppProvider } = createLazyResourceStore(
  ({ useTriggered }) =>
    (props?: { appId: string }) =>
      useInitSDK(
        useAddToRecentlyUsedApps(
          useTransform(
            useApps(useTriggered()),
            useCallback((apps) => apps?.find((app) => app.appId === props?.appId), [props])
          )
        )
      ),
  initialResource
);
const parseApp = (app?: Application) => (app ? parseAppData(app) : undefined);
const { useResource: useTDSCurrentApp, Provider: TDSCurrentAppProvider } = createResourceStore(
  ({ appId }: { appId: string }) => {
    const [user] = useUser();
    return useInitSDK(
      useTransform(
        useAPI<Application>(
          `/client-center/${CLIENT_CENTER_VERSION}/clients/self/apps/${appId}`,
          undefined,
          [user],
          user !== undefined
        ),
        parseApp
      )
    );
  },
  initialResource
);
const useCurrentApp = TDS ? useTDSCurrentApp : useResource;
const Provider = TDS ? TDSCurrentAppProvider : CurrentAppProvider;
export { useCurrentApp, Provider };

export const usePushConfigs = () => {
  const [app] = useCurrentApp();
  const { appId } = app || {};

  return useAPI<PushConfigs>(`/${API_VERSION}/clients/self/apps/${appId}`, {}, [app], !!appId);
};

export const useSDKApp = (): Resource<LC.App | undefined> => {
  const [, extra] = useCurrentApp();
  return [SDKApp, extra];
};
export const mustGetSDKApp = () => {
  if (!SDKApp) {
    throw new Error('Application not initialized');
  }
  return SDKApp;
};
