import React from 'react';
import { Placeholder } from '../semantic';
export default ({
  line = 2,
  headerLine,
  image,
  inverted,
}: {
  line?: number;
  headerLine?: number;
  image?: boolean;
  inverted?: boolean;
}) => (
  <Placeholder inverted={inverted}>
    {typeof headerLine === 'number' && (
      <Placeholder.Header image={image}>
        {Array(headerLine)
          .fill(0)
          .map((v, index) => (
            <Placeholder.Line key={index} />
          ))}
      </Placeholder.Header>
    )}
    <Placeholder.Paragraph>
      {Array(line)
        .fill(0)
        .map((v, index) => (
          <Placeholder.Line key={index} />
        ))}
    </Placeholder.Paragraph>
  </Placeholder>
);
