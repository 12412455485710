import { createLazyResourceStore, initialResource } from 'utils/use-api';
import { API_VERSION } from 'utils/request';
import { EngineGroup } from './Engine/types';
import { useEngineAPI } from './Engine/utils';

const { useResource: useMultiplayerServerGroup, Provider } = createLazyResourceStore(
  ({ useTriggered }) =>
    () =>
      useEngineAPI<EngineGroup & { enabled: boolean }>(
        useTriggered() ? `/${API_VERSION}/engine/multiplayer-server'` : undefined
      ),
  initialResource
);

export { useMultiplayerServerGroup, Provider };
