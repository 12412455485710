import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { useLocalStorage } from 'utils';
import Hint from 'components/Hint';
import {
  Button,
  Checkbox,
  Form,
  NativeSelectOption,
  NativeSelectProps,
  NativeSelect,
} from 'components/semantic';
import {
  AreaView,
  bytesFormatters,
  COLORS,
  durationFormatters,
  LineView,
  TimeSerialData,
} from 'components/StatisticView';
import { useIsTablet } from 'utils/media';
import useURLSearchParam from 'utils/use-url-search-param';
import { useCurrentGroup } from 'App/Application/Groups';
import styles from './index.module.scss';
import { MetricData, transformMetricData, useMetric } from './utils';

enum groupedHTTPStatus {
  OK = 'OK (1XX-4XX)',
  FAILED = 'FAILED (5XX)',
}
const GROUPED_COLORS = [COLORS.noEmotion, COLORS.danger];
type DPS = MetricData['dps'];
const useSum = (data: MetricData[] | undefined, overview: boolean) =>
  useMemo(() => {
    if (!overview) {
      return data;
    }
    const groupedDps = (data || []).reduce<[DPS, DPS]>(
      ([OKDps, FailedDps], { tags: { status }, dps }) => {
        if (!status) {
          return [OKDps, FailedDps];
        }
        const merge = (target: DPS) => _.mergeWith(target, dps, _.add);
        if (status[0] === '5') {
          return [OKDps, merge(FailedDps)];
        } else {
          return [merge(OKDps), FailedDps];
        }
      },
      [{}, {}]
    );
    return [
      {
        tags: {
          status: groupedHTTPStatus.OK,
        },
        dps: groupedDps[0],
      },
      {
        tags: {
          status: groupedHTTPStatus.FAILED,
        },
        dps: groupedDps[1],
      },
    ];
  }, [data, overview]);
const useWeightedAverage = (
  data: MetricData[] | undefined,
  weight: MetricData[] | undefined,
  sum: MetricData[] | undefined,
  overview: boolean
) =>
  useMemo(() => {
    if (!overview) {
      return data;
    }
    if (!(data && weight && sum)) {
      return data;
    }
    const groupedDps = data.reduce<[DPS, DPS]>(
      ([OKDps, FailedDps], { tags: { status }, dps }, index) => {
        if (!status) {
          return [OKDps, FailedDps];
        }

        const merge = (target: DPS) =>
          _.mergeWith(target, dps, (objValue, srcValue, key) => {
            let result: null | number = null;
            if (_.isNumber(objValue)) {
              result = objValue;
            }
            if (_.isNumber(srcValue)) {
              result = (result || 0) + _.multiply(srcValue, weight[index]?.dps[key] || 0);
            }
            return result;
          });

        if (status[0] === '5') {
          return [OKDps, merge(FailedDps)];
        } else {
          return [merge(OKDps), FailedDps];
        }
      },
      [{}, {}]
    );
    return [
      {
        tags: {
          status: groupedHTTPStatus.OK,
        },
        dps: _.mapValues(groupedDps[0], (dps, key) =>
          _.isNumber(dps) ? (dps || 0) / (sum[0].dps[key] || 1) : dps
        ),
      },
      {
        tags: {
          status: groupedHTTPStatus.FAILED,
        },
        dps: _.mapValues(groupedDps[1], (dps, key) =>
          _.isNumber(dps) ? (dps || 0) / (sum[0].dps[key] || 1) : dps
        ),
      },
    ];
  }, [data, overview, sum, weight]);

const ALL_KEY = '__ALL';
const OVERVIEW_KEY = '__OVERVIEW';
const DETAIL_KEY = '*';
const WEBHOSTING_KEY = '-';
const GROUPED_BY_FUNCTION_QUERY = {
  functionName: DETAIL_KEY,
};
export const useHttpMetrics = (start?: Date, end?: Date) => {
  const { t } = useTranslation();
  const [{ groupName }] = useCurrentGroup();
  const [functionName = ALL_KEY, setFunctionName] = useURLSearchParam('function');
  const [functionGroupedData] = useMetric({
    groupName,
    start,
    end,
    metric: 'respTime',
    query: GROUPED_BY_FUNCTION_QUERY,
  });

  const functionNameOptions: NativeSelectProps['options'] = useMemo(() => {
    const functionNames: NativeSelectOption[] = (functionGroupedData || [])
      .map((data) => data.tags.functionName)
      .filter(_.isString)
      .filter((name) => name !== WEBHOSTING_KEY)
      .sort()
      .map((name) => [name, name, { group: name.indexOf('/') !== -1 ? 'Hooks' : 'Functions' }]);
    return [
      [ALL_KEY, t('label.allRequests')],
      [WEBHOSTING_KEY, t('engine.stats.webhosting')],
      ...functionNames,
    ];
  }, [functionGroupedData, t]);

  const [statusGroupedData, { loading: loadingStatusGroupedData }] = useMetric({
    groupName,
    start,
    end,
    metric: 'respTime',
    query: useMemo(
      () => ({
        status: DETAIL_KEY,
        functionName: functionName === ALL_KEY ? undefined : functionName,
      }),
      [functionName]
    ),
  });
  const HTTPStatuses = useMemo(
    () =>
      (statusGroupedData || [])
        .map((data) => data.tags.status)
        .filter(_.isString)
        .sort(),
    [statusGroupedData]
  );
  const HTTPStatusOptions: NativeSelectProps['options'] = useMemo(() => {
    const status: NativeSelectOption[] = HTTPStatuses.map((name) => [
      name,
      name,
      { group: t('engine.stats.statusDetails') },
    ]);
    return [
      [OVERVIEW_KEY, t('engine.stats.overview')],
      [DETAIL_KEY, t('engine.stats.details')],
      [ALL_KEY, t('label.allStatus'), { group: t('engine.stats.statusDetails') }],
      ...status,
    ];
  }, [HTTPStatuses, t]);
  const [HTTPStatus, setHTTPStatus] = useState(OVERVIEW_KEY);
  const overview = HTTPStatus === OVERVIEW_KEY;
  const specific = HTTPStatus !== OVERVIEW_KEY && HTTPStatus !== DETAIL_KEY;
  useEffect(() => {
    setHTTPStatus(OVERVIEW_KEY);
  }, [functionName]);

  const status = useMemo(
    () => (specific && !loadingStatusGroupedData ? HTTPStatus : DETAIL_KEY),
    [HTTPStatus, loadingStatusGroupedData, specific]
  );
  const query = useMemo(
    () => ({
      status: status === ALL_KEY ? undefined : status,
      functionName: functionName === ALL_KEY ? undefined : functionName,
      instance: specific ? DETAIL_KEY : undefined,
    }),
    [functionName, specific, status]
  );
  const [responseTimeData, { loading: loadingResponseTime, reload: reloadResponseTime }] =
    useMetric({
      groupName,
      start,
      end,
      query,
      metric: 'respTime',
    });
  const [RPMData, { loading: loadingRPM, reload: reloadRPM }] = useMetric({
    groupName,
    start,
    end,
    query,
    metric: 'rpm',
  });
  const regroupedRPMData = useSum(RPMData, overview);
  const regroupedResponseTimeData = useWeightedAverage(
    responseTimeData,
    RPMData,
    regroupedRPMData,
    overview
  );
  const responseTimeChartData = useMemo<TimeSerialData[]>(
    () =>
      transformMetricData(
        regroupedResponseTimeData,
        (value) => (value ? Math.round(value * 1000) : value),
        specific ? 'instanceName' : 'status'
      ),
    [regroupedResponseTimeData, specific]
  );
  const RPMChartData = useMemo<TimeSerialData[]>(
    () => transformMetricData(regroupedRPMData, undefined, specific ? 'instanceName' : 'status'),
    [regroupedRPMData, specific]
  );

  const node = (
    <div className={styles.detailedMetricsArea}>
      <div className={classNames(styles.detailedMetricsToolbar)}>
        <NativeSelect
          value={functionName}
          options={functionNameOptions}
          onChange={(e, { value }) => setFunctionName(value === ALL_KEY ? undefined : value)}
        />{' '}
        <NativeSelect
          value={HTTPStatus}
          options={HTTPStatusOptions}
          onChange={(e, { value }) => setHTTPStatus(value)}
        />
      </div>
      <div className={styles.item}>
        <h4>{t('label.qpm')}</h4>
        <LineView
          data={RPMChartData}
          loading={loadingStatusGroupedData || loadingRPM}
          colors={overview ? GROUPED_COLORS : undefined}
          omitDataKey={false}
          syncId="engine"
          height={280}
        />
      </div>
      <div className={styles.item}>
        <h4>
          {t('storage.stat.performance.du')}
          {overview && <Hint content={t('storage.stat.performance.du.notes')} />}
        </h4>
        <LineView
          data={responseTimeChartData}
          loading={loadingStatusGroupedData || loadingResponseTime}
          colors={overview ? GROUPED_COLORS : undefined}
          omitDataKey={false}
          formatters={durationFormatters}
          syncId="engine"
          height={280}
        />
      </div>
      {(RPMChartData[0]?.[1]?.['499'] !== undefined || HTTPStatus === '499') && (
        <p className="help-block">{t('engine.stats.http.help.499')}</p>
      )}
      {RPMChartData[0]?.[1]?.['unknown'] !== undefined && (
        <>
          <p className="help-block">{t('engine.stats.http.help.unknown')}</p>
          {HTTPStatus === '502' && (
            <p className="help-block">{t('engine.stats.http.help.unknown.502')}</p>
          )}
          {HTTPStatus === '504' && (
            <p className="help-block">{t('engine.stats.http.help.unknown.504')}</p>
          )}
        </>
      )}
    </div>
  );
  const reload = useCallback(() => {
    reloadResponseTime();
    reloadRPM();
  }, [reloadRPM, reloadResponseTime]);

  return [node, reload] as const;
};

const CPUFormatters = {
  yAxisDisplay: (value: number) => value.toFixed(1),
};

export const useResouceMetrics = (start?: Date, end?: Date) => {
  const [{ groupName }] = useCurrentGroup();
  const [detailed, setDetailed] = useState(false);
  const query = useMemo(
    () =>
      detailed
        ? {
            instanceName: '*',
          }
        : {},
    [detailed]
  );
  const { t } = useTranslation();

  const [CPUData, { loading: loadingCPU, reload: reloadCPU }] = useMetric({
    groupName,
    start,
    end,
    query,
    metric: 'cpu',
  });
  const CPUChartData = useMemo<TimeSerialData[]>(
    () => transformMetricData(CPUData, (value) => value, 'instanceName'),
    [CPUData]
  );

  const [MemoryData, { loading: loadingMemory, reload: reloadMemory }] = useMetric({
    groupName,
    start,
    end,
    query,
    metric: 'memoryRss',
  });
  const MemoryChartData = useMemo<TimeSerialData[]>(
    () =>
      transformMetricData(MemoryData, (value) => (value ? value * 1024 : value), 'instanceName'),
    [MemoryData]
  );

  const View = detailed ? LineView : AreaView;
  const node = (
    <div className={styles.detailedMetricsArea}>
      <div className={classNames(styles.detailedMetricsToolbar)}>
        <Form>
          <Checkbox
            label={t('engine.stats.instanceDetails')}
            checked={detailed}
            onChange={(e, { checked }) => setDetailed(!!checked)}
          />
        </Form>
      </div>
      <div className={styles.item}>
        <h4>CPU</h4>
        <View
          data={CPUChartData}
          loading={loadingCPU}
          unit="%"
          formatters={CPUFormatters}
          stack={false}
          syncId="engine"
          height={260}
        />
      </div>
      <div className={styles.item}>
        <h4>{t('label.ram')}</h4>
        <View
          data={MemoryChartData}
          loading={loadingMemory}
          formatters={bytesFormatters}
          stack={false}
          syncId="engine"
          height={260}
        />
      </div>
    </div>
  );

  const reload = useCallback(() => {
    reloadMemory();
    reloadCPU();
  }, [reloadCPU, reloadMemory]);

  return [node, reload] as const;
};

export enum DISPLAY_MODE {
  grid = 'grid',
  list = 'list',
}

export const useDisplayMode = () => {
  const [persistedMode, setMode] = useLocalStorage('preferance/stats/displayMode');
  const displayMode = useMemo(
    () => (persistedMode === DISPLAY_MODE.list ? persistedMode : DISPLAY_MODE.grid),
    [persistedMode]
  );
  const setGridMode = useCallback(() => setMode(DISPLAY_MODE.grid), [setMode]);
  const setListMode = useCallback(() => setMode(DISPLAY_MODE.list), [setMode]);

  const isTablet = useIsTablet();

  const switchNode = isTablet ? (
    <Button.Group className={styles.displayModeSwitch}>
      <Button active={displayMode === DISPLAY_MODE.grid} onClick={setGridMode} icon="border all" />
      <Button active={displayMode === DISPLAY_MODE.list} onClick={setListMode} icon="bars" />
    </Button.Group>
  ) : null;

  const containerClassName = displayMode === DISPLAY_MODE.grid ? styles.grid : styles.list;

  return [displayMode, switchNode, containerClassName] as const;
};

export { styles };
