export const CLASS_HOOKS = [
  'afterDelete',
  'afterSave',
  'afterUpdate',
  'beforeDelete',
  'beforeSave',
  'beforeUpdate',
];
export const VERIFY_HOOKS = ['onVerified'];
export const VERIFY_HOOKS_TYPE = ['sms', 'email'];
export const USER_HOOKS = ['onLogin', 'onAuthData'];
export const IM_HOOKS = [
  '_messageReceived',
  '_messageSent',
  '_receiversOffline',
  '_conversationStart',
  '_conversationStarted',
  '_conversationAdd',
  '_conversationAdded',
  '_conversationRemove',
  '_conversationRemoved',
  '_conversationUpdate',
  '_clientOnline',
  '_clientOffline',
].sort();
