import React, { useCallback, useState, useMemo, memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Noop } from 'utils';
import Confirm from 'components/Confirm';
import NoData from 'components/NoData';
import Placeholder from 'components/Placeholder';
import { Button, Modal, withModal, LinkButton, Form, Table } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { API_VERSION } from 'utils/request';
import { APIError, useAPI } from 'utils/use-api';
import { useAppId, useCurrentApp } from 'App/Application';
import { useApps } from 'App/Apps';
import styles from './index.module.scss';
import { ClusterInfo } from './types';

const useStopSharing = () => {
  const { t } = useTranslation();
  return useCallback(
    async (appId: string, clusterId: number) => {
      try {
        await request<void>(`/${API_VERSION}/leandb/apps/${appId}/attached/${clusterId}`, {
          method: 'DELETE',
        });
      } catch (err) {
        toast.error(t('db.share.stop.failed'), err);
      }
    },
    [t]
  );
};
export const useSharedByApps = (id: number) =>
  useAPI<{ appName: string; appId: string }[]>(`/${API_VERSION}/leandb/clusters/${id}/attached-by`);

export const ShareManager = withModal<{ cluster: ClusterInfo }>()(({ cluster, close }) => {
  const { t } = useTranslation();
  const [attachedByApps, { error, loading, reload }] = useSharedByApps(cluster.id);
  const stopSharing = useStopSharing();
  return (
    <>
      <Modal.Header>
        <Trans i18nKey={'db.share.manage.header'} values={{ name: cluster.name }}>
          Apps sharing <code>Name</code>
        </Trans>
      </Modal.Header>
      <Modal.Content>
        {error && <APIError message={t('db.share.fetchFailed')} error={error} retry={reload} />}
        {loading && <Placeholder line={4} />}
        {attachedByApps &&
          (attachedByApps.length ? (
            <Table borderless>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content={t('label.app')} />
                  <Table.HeaderCell content={t('label.operation')} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {attachedByApps.map((app) => (
                  <Table.Row key={app.appId}>
                    <Table.Cell>
                      <div className={styles.appName}>{app.appName}</div>
                      <div className="help-block">{app.appId}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <Confirm
                        trigger={
                          <Button size="small" basic negative content={t('db.share.stop')} />
                        }
                        header={
                          <Trans
                            i18nKey={'db.share.stop.header'}
                            values={{ name: cluster.name, app: app.appName }}
                          >
                            Stop sharing <code>InstanceName</code> with <code>App</code>
                          </Trans>
                        }
                        onConfirm={() => stopSharing(app.appId, cluster.id).then(reload)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <NoData
              icon="share alternate"
              header={t('db.share.empty')}
              extra={
                <AttachModal
                  cluster={cluster}
                  onSucceeded={reload}
                  modalProps={{
                    trigger: <Button content={t('db.share.add')} primary />,
                  }}
                />
              }
            />
          ))}
      </Modal.Content>
      <Modal.Actions>
        {attachedByApps && attachedByApps.length > 0 && (
          <AttachModal
            attachApps={attachedByApps.map((app) => app.appId)}
            cluster={cluster}
            onSucceeded={reload}
            modalProps={{
              trigger: <Button content={t('db.share.add')} floated="left" />,
            }}
          />
        )}
        <Button type="button" content={t('action.close')} onClick={close} />
      </Modal.Actions>
    </>
  );
});

const FORM_ID = 'SHARE_CLUSTER';
const AttachModal = withModal<{
  cluster: ClusterInfo;
  onSucceeded?: Noop;
  attachApps?: string[];
}>()(({ cluster, onSucceeded, close, attachApps }) => {
  const { t } = useTranslation();
  const [apps] = useApps();
  const appId = useAppId();
  const [selectedAppId, setSelectedAppId] = useState<string>();
  const options = useMemo(() => {
    if (!apps) {
      return;
    }
    return apps
      .filter((app) => app.appId !== appId && !attachApps?.includes(app.appId))
      .map((app) => [app.appId, app.appName] as const);
  }, [apps, appId, attachApps]);
  const share = useCallback(async () => {
    try {
      await request<void>(`/${API_VERSION}/leandb/apps/${selectedAppId}/attached/${cluster.id}`, {
        method: 'PUT',
      });
    } catch (error) {
      toast.error(t('db.share.add.failed'), error);
    }
    onSucceeded?.();
    close();
  }, [close, cluster.id, onSucceeded, selectedAppId, t]);
  return (
    <>
      <Modal.Header>
        <Trans i18nKey={'db.share.add.header'} values={{ name: cluster.name }}>
          Share <code>Name</code> with
        </Trans>
      </Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} onSubmit={share}>
          {options && (
            <Form.NativeSelect
              options={options}
              value={selectedAppId}
              onChange={(e, { value }) => setSelectedAppId(value)}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton content={t('action.close')} onClick={close} />
        <Button
          primary
          type="submit"
          form={FORM_ID}
          content={t('action.share')}
          disabled={selectedAppId === undefined}
        />
      </Modal.Actions>
    </>
  );
});

export const ShareInfo = memo<{
  instance: ClusterInfo;
  onDeleted: (id: number) => void;
}>(({ instance, onDeleted }) => {
  const { t } = useTranslation();
  const appId = useAppId();
  const [apps] = useApps();
  const [currentApp] = useCurrentApp();
  const stopSharing = useStopSharing();
  const appName = useMemo(() => {
    const app = apps?.find((_) => _.appId === instance.appId);
    return app?.appName || instance.appId;
  }, [apps, instance]);
  return (
    <p>
      {t('db.sharedBy', { app: appName })}{' '}
      <Confirm
        trigger={<Button size="mini" basic negative content={t('db.share.stop')} />}
        header={
          <Trans
            i18nKey={'db.share.stop.header'}
            values={{ name: instance.name, app: currentApp?.appName }}
          >
            Stop sharing <code>InstanceName</code> with <code>App</code>
          </Trans>
        }
        onConfirm={() => stopSharing(appId, instance.id).then(() => onDeleted(instance.id))}
      />
    </p>
  );
});
