import React from 'react';
import { subDays } from 'date-fns';
import { DateTimeRangeSelect, useSearchDateTimeRange, Range } from 'components/DateRangeSelect';
import { endOf } from 'utils';

const RANGE_OPTIONS: Range[] = ['Today', 'Yesterday', 'Last 7 days so far'];
const MAX_DATE = endOf(new Date(), 'd');
const MIN_DATE = subDays(MAX_DATE, 6);
export const useDateTimeRangeAndSelect = () => {
  const [from, to, options] = useSearchDateTimeRange(RANGE_OPTIONS);
  const node = (
    <DateTimeRangeSelect float="right" maxDate={MAX_DATE} minDate={MIN_DATE} {...options} />
  );
  return [from, to, node] as const;
};
export const useDateTimeRange = () => useSearchDateTimeRange(RANGE_OPTIONS);
