import { parseISO, isValid, add, sub, Duration } from 'date-fns';
import { trimEnd } from 'lodash';
import { endOf, getCurrentUtcOffset, startOf } from 'utils';
import { Range } from './type';

export const defaultRanges: Range[] = [
  'Today',
  'Yesterday',
  'Last 7 day so far',
  'Last 30 day so far',
  'Previous month',
];

const UNIT_ABBR_MAP = {
  minutes: 'm',
  hours: 'h',
  hour: 'h',
  day: 'd',
  days: 'd',
  week: 'w',
  month: 'M',
  months: 'M',
  year: 'y',
  years: 'y',
};

export const getRangeContent = (range: Range): [string, string] => {
  switch (range) {
    case 'Today':
      return ['now/d', 'now/d'];
    case 'Yesterday':
      return ['now-1d/d', 'now-1d/d'];
    default:
      const soFar = range.includes('so far');
      const [dateADJ, num, unit] = range.split(' ');
      const unitABBR = UNIT_ABBR_MAP[num] || UNIT_ABBR_MAP[unit];
      if (dateADJ === 'Last' && unitABBR) {
        // soFar 情况下 day 的开始日期和结束日期
        // 或许也需要处理  month 和 year
        // 具体遇见在说
        let lastNum = Number(num);
        if (unitABBR === 'd') {
          if (!soFar) {
            return [`now-${lastNum}d/d`, 'now-1d/d'];
          }
          lastNum = lastNum - 1;
          return lastNum !== 0 ? [`now-${lastNum}d/d`, 'now/d'] : ['now/d', 'now/d'];
        }
        return [`now-${lastNum}${unitABBR}`, 'now'];
      }
      if (dateADJ === 'This' && unitABBR) {
        return [`now/${unitABBR}`, soFar ? 'now' : `now/${unitABBR}`];
      }
      if (dateADJ === 'Previous' && unitABBR) {
        return [`now-1${unitABBR}/${unitABBR}`, `now-1${unitABBR}/${unitABBR}`];
      }
      return ['', ''];
  }
};

export const getI18nOptionsByRange = (range: Range, prefix = 'dateRange') => {
  switch (range) {
    case 'Today':
      return { key: `${prefix}.today` };
    case 'Yesterday':
      return { key: `${prefix}.yesterday` };
    default:
      const soFar = range.includes('so far');
      const [dateADJ, num, unit] = range.split(' ');
      if (dateADJ === 'Last') {
        const midKey = soFar ? 'lastSofar' : 'last';
        return {
          key: `${prefix}.${midKey}.${trimEnd(unit, 's')}`,
          count: Number(num),
        };
      }
      if (dateADJ === 'This') {
        return { key: `${prefix}.this.${num}` };
      }
      if (dateADJ === 'Previous') {
        return { key: `${prefix}.previous.${num}` };
      }
      return {
        key: `${prefix}`,
      };
  }
};

export const isDateExpr = (str: string) => str.includes('now');

type DurationKey = keyof Duration;
const DURATION_MAP: {
  [key in string]: DurationKey;
} = {
  d: 'days',
  w: 'weeks',
  M: 'months',
  y: 'years',
  h: 'hours',
  m: 'minutes',
};

export function getDateByExpression(
  expr: string,
  type: 'from' | 'to',
  targetUtcOffset: number = getCurrentUtcOffset()
) {
  const [content, unit] = expr.split('/');
  let date = _getDateByExpression(content);
  if (!date) {
    return;
  }
  if (unit) {
    date =
      type === 'from' ? startOf(date, unit, targetUtcOffset) : endOf(date, unit, targetUtcOffset);
  }
  return date;
}

function _getDateByExpression(expr: string) {
  if (!isDateExpr(expr)) {
    const date = parseISO(expr);
    if (!isValid(date)) {
      return;
    } else {
      return date;
    }
  }
  const isLast = expr.indexOf('+') !== 0;
  if (expr.indexOf('now') === -1) {
    expr = (isLast ? 'now-' : 'now') + expr;
  }
  const parts = /^now([-+])(\d+)(\w)/.exec(expr);
  let dateTime = new Date();
  if (parts) {
    const durationKey = DURATION_MAP[parts[3]];
    if (parts[1] === '+') {
      dateTime = add(dateTime, {
        [durationKey]: Number(parts[2]),
      });
    } else {
      dateTime = sub(dateTime, {
        [durationKey]: Number(parts[2]),
      });
    }
  }
  return dateTime;
}
