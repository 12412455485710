import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import moment from 'moment';
import { serverUtcOffset } from 'config';
import { startOfDay, endOfDay, isSameYear } from 'date-fns';
import {
  addUTCOffset,
  formatTime,
  formatTimeZone,
  getCurrentUtcOffset,
  localUtcOffset,
  startOf,
  subUTCOffset,
  TimeFormat,
} from 'utils';
import { TimeInput } from 'components/DateTime';
import { Popup, Icon, Menu, Button } from 'components/semantic';
import { DateRange } from './DateRange';
import styles from './index.module.scss';
import { Range, RangeItem, RangeValue } from './type';
import {
  getRangeContent,
  getDateByExpression,
  getI18nOptionsByRange,
  defaultRanges,
} from './utils';

export interface RangeSelectProps {
  value?: RangeValue;
  ranges?: Range[];
  scroll?: boolean;
  absoluteDate?: boolean;
  onChange?: (value: RangeValue) => void;
  maxDate?: Date;
  minDate?: Date;
  float?: 'right' | 'left';
  className?: string;
}

export interface BaseRangeSelectProps extends RangeSelectProps {
  utcOffset?: number;
  showTime?: boolean;
}

type DateAction = 'fromFocus' | 'toFocus' | 'valueChange';
const closeActions = ['valueChange-toFocus-valueChange', 'valueChange-fromFocus'];
const CustomDates = React.memo<
  Omit<BaseRangeSelectProps, 'onChange' | 'ranges' | 'absoluteDate' | 'utcOffset'> & {
    onChange?: (from: Date, to: Date) => void;
    utcOffset: number;
    rightAligned?: boolean;
  }
>(({ scroll, showTime, value, onChange, maxDate, minDate, utcOffset, rightAligned }) => {
  const actions = useRef<DateAction[]>([]);
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [focusedRange, setFocusedRange] = useState<[number, number]>([0, 0]);
  useEffect(() => {
    if (!value) {
      setFromDate(startOf(new Date(), 'd'));
      setToDate(new Date());
    } else {
      const from = getDateByExpression(value.from, 'from', utcOffset);
      const to = getDateByExpression(value.to, 'to', utcOffset);
      setFromDate(from);
      setToDate(to);
    }
  }, [value, utcOffset]);

  useEffect(() => {
    return () => {
      actions.current = [];
    };
  }, []);

  const actionChange = useCallback(() => {
    if (showTime) {
      return;
    }
    const actionStr = actions.current.join('-');
    if (closeActions.some((str) => actionStr.includes(str))) {
      onChange?.(fromDate!, toDate!);
    }
  }, [fromDate, toDate, onChange, showTime]);

  const showToday = useMemo(() => {
    if (utcOffset === undefined) {
      return true;
    }
    return utcOffset === getCurrentUtcOffset();
  }, [utcOffset]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(actionChange, [focusedRange]);

  return (
    <div className={styles.rangeContainer}>
      <DateRange
        showToday={showToday}
        maxDate={maxDate}
        minDate={minDate}
        months={scroll ? 1 : undefined}
        direction="vertical"
        scroll={{ enabled: !!scroll }}
        ranges={[
          {
            startDate: moment(
              formatTime(fromDate!, TimeFormat.EDITABLE_DATETIME_WITH_SECOND, utcOffset)
            ).toDate(),
            endDate: moment(
              formatTime(toDate!, TimeFormat.EDITABLE_DATETIME_WITH_SECOND, utcOffset)
            ).toDate(),
          },
        ]}
        focusedRange={focusedRange}
        onRangeFocusChange={(nextFoucedRange) => {
          if (!showTime) {
            actions.current = [
              ...actions.current,
              nextFoucedRange[1] === 1 ? 'toFocus' : 'fromFocus',
            ];
          }
          setFocusedRange(nextFoucedRange);
        }}
        onChange={(range) => {
          let { startDate, endDate } = range.range1;
          startDate = startDate ? subUTCOffset(startOfDay(startDate), utcOffset) : startDate;
          endDate = endDate ? subUTCOffset(endOfDay(endDate), utcOffset) : endDate;
          setFromDate(startDate);
          setToDate(endDate);
          if (showTime || !startDate || !endDate) {
            return;
          }
          if (!showTime) {
            actions.current = [...actions.current, 'valueChange'];
          }
        }}
      />
      {showTime && (
        <div className={styles.timeInputGroups}>
          <TimeInput
            className={styles.timeInput}
            value={fromDate}
            rightAligned={rightAligned}
            onChange={(data) => setFromDate(data.value)}
          />
          <TimeInput
            className={styles.timeInput}
            value={toDate}
            rightAligned={rightAligned}
            onChange={(data) => {
              setToDate(data.value);
            }}
          />
          <Button
            type="button"
            className={styles.submitButton}
            content={t('action.apply')}
            disabled={!fromDate || !toDate}
            primary
            size="small"
            onClick={() => {
              if (onChange && fromDate && toDate) {
                onChange(fromDate, toDate);
              }
            }}
          />
        </div>
      )}
    </div>
  );
});

const TimeZone = ({ utcOffset, className }: { className?: string; utcOffset?: number }) => {
  if (utcOffset !== undefined) {
    return <span className={className}>({formatTimeZone(utcOffset)})</span>;
  }
  return null;
};

export const BaseRangeSelect = React.memo<BaseRangeSelectProps>(
  ({
    ranges = defaultRanges,
    utcOffset = getCurrentUtcOffset(),
    onChange: propOnChange,
    value,
    showTime = true,
    absoluteDate = true,
    float = 'right',
    className,
    ...rest
  }) => {
    const { t } = useTranslation();
    const node = useRef<HTMLSpanElement>(null);
    const [panelStatus, setPanelStatus] = useState(false);
    const [datePanelStatus, setDatePanelStatus] = useState(false);
    const extendRanges = useMemo(
      () =>
        ranges.map((range) => {
          const [from, to] = getRangeContent(range);
          const i18nOptions = getI18nOptionsByRange(range);
          return {
            from,
            to,
            display: t(i18nOptions.key, {
              count: i18nOptions.count,
            }),
          } as RangeItem;
        }),
      [ranges, t]
    );
    const matchRange = useMemo(() => {
      if (!value) {
        return;
      }
      const matchRanges = extendRanges.filter((range) => {
        return range.from === value.from && range.to === value.to;
      });
      if (matchRanges[0]) {
        return matchRanges[0];
      }
      return;
    }, [value, extendRanges]);

    const format = useCallback(
      (date: Date | string, displayFullYear?: boolean) => {
        const format = displayFullYear
          ? showTime
            ? TimeFormat.DISPLAY_DATETIME_FULL
            : TimeFormat.DISPLAY_FULL
          : showTime
          ? TimeFormat.DISPLAY_DATETIME
          : TimeFormat.DISPLAY;
        return formatTime(date, format, utcOffset);
      },
      [showTime, utcOffset]
    );

    const displayText = useMemo(() => {
      if (!value) {
        return extendRanges[0].display;
      }
      if (matchRange) {
        return matchRange.display;
      }
      if (value.from === value.to) {
        return format(value.from);
      }
      const displayFullYear = !isSameYear(
        addUTCOffset(new Date(value.from), utcOffset),
        addUTCOffset(new Date(value.to), utcOffset)
      );
      return `${format(value.from, displayFullYear)} ${t('label.to')} ${format(
        value.to,
        displayFullYear
      )}`;
    }, [value, matchRange, utcOffset, format, t, extendRanges]);

    const popupStr = useMemo(() => {
      if (value && !matchRange) {
        return;
      }
      const selectedRange = value || extendRanges[0];
      const fromDate = getDateByExpression(selectedRange.from, 'from', utcOffset);
      const toDate = getDateByExpression(selectedRange.to, 'to', utcOffset);
      const displayFullYear =
        fromDate &&
        toDate &&
        !isSameYear(
          addUTCOffset(new Date(fromDate), utcOffset),
          addUTCOffset(new Date(toDate), utcOffset)
        );
      const fromStr = fromDate ? format(fromDate, displayFullYear) : selectedRange.from;
      const toStr = toDate ? format(toDate, displayFullYear) : selectedRange.to;
      if (fromStr === toStr) {
        return fromStr;
      }
      return `${fromStr} ${t('label.to')} ${toStr}`;
    }, [matchRange, format, t, extendRanges, value, utcOffset]);

    const onChange = useCallback(
      (from: string | Date, to: string | Date) => {
        if (propOnChange) {
          propOnChange({
            from: typeof from === 'string' ? from : from.toISOString(),
            to: typeof to === 'string' ? to : to.toISOString(),
          });
        }
        setPanelStatus(false);
      },
      [propOnChange]
    );

    const rightAligned = float === 'right';
    return (
      <div
        className={classnames(
          styles.container,
          rightAligned ? styles.right : styles.left,
          className
        )}
      >
        <Popup
          position={rightAligned ? 'top right' : 'top left'}
          wide
          disabled={!matchRange}
          content={
            <div className={styles.popContent}>
              {popupStr} {utcOffset !== localUtcOffset && <TimeZone utcOffset={utcOffset} />}
            </div>
          }
          hoverable
          trigger={
            <span
              onClick={() => setPanelStatus((pre) => !pre)}
              ref={node}
              className={styles.selected}
            >
              <Icon name="calendar alternate" />
              {displayText}{' '}
              {utcOffset !== localUtcOffset && (
                <TimeZone utcOffset={utcOffset} className="text-muted" />
              )}
              <Icon name="dropdown" />
            </span>
          }
        />
        <Popup
          wide="very"
          on="click"
          onMount={() => {
            if (matchRange) {
              setDatePanelStatus(false);
            }
          }}
          hoverable
          position={rightAligned ? 'bottom right' : 'bottom left'}
          inverted={false}
          context={node}
          open={panelStatus}
          onClose={(e) => {
            // only click
            if (e.type === 'click') {
              if (e.target === node.current) {
                return;
              }
              if (node.current && node.current.contains(e.target as Node)) {
                return;
              }
              setPanelStatus(false);
            }
          }}
          content={
            <div className={classnames(styles.panel, rightAligned ? styles.right : styles.left)}>
              <Menu vertical className={styles.dateMenu}>
                {extendRanges.map((range) => {
                  const { from, to, display } = range;
                  const key = `${from}-${to}`;
                  const isActive = value && value.from === from && value.to === to;
                  return (
                    <Menu.Item
                      key={key}
                      name={key}
                      active={!datePanelStatus && isActive}
                      content={display}
                      onClick={() => {
                        onChange(from, to);
                      }}
                    />
                  );
                })}
                {absoluteDate && (
                  <Menu.Item
                    name="$$_absoulteDate"
                    active={datePanelStatus}
                    content={t(showTime ? 'dateRange.absoluteDateTime' : 'dateRange.absoluteDate')}
                    onClick={() => setDatePanelStatus(true)}
                  />
                )}
              </Menu>
              {datePanelStatus && (
                <CustomDates
                  value={value}
                  onChange={onChange}
                  showTime={showTime}
                  utcOffset={utcOffset}
                  rightAligned={rightAligned}
                  {...rest}
                />
              )}
            </div>
          }
          style={{
            padding: '0.5em 0',
          }}
        />
      </div>
    );
  }
);

export const ServerDateRangeSelect = (props: RangeSelectProps) => (
  <BaseRangeSelect showTime={false} utcOffset={serverUtcOffset} {...props} />
);
export const DateTimeRangeSelect = (props: RangeSelectProps) => (
  <BaseRangeSelect showTime={true} {...props} />
);

export * from './hooks';
export * from './type';
