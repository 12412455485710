import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Hint from 'components/Hint';
import Page, { PageContainer } from 'components/Page';
import { Breadcrumb, Button } from 'components/semantic';
import { LineView, bytesFormatters, AreaView } from 'components/StatisticView';
import { withTracker } from 'utils/tracker';
import { useTransform } from 'utils/use-api';
import { useDisplayMode } from '../../Stats/parts';
import { useDateTimeRange, useDateTimeRangeAndSelect } from '../../use-date-range';
import { StatContainer, transformMetricData, useMetrics } from '../utils';
import { useInstanceId, useMongo } from './';
import ChartContainer from '../ChartContainer';
import styles from './index.module.scss';

type Metric =
  | 'connections'
  | 'objects'
  | 'queries'
  | 'latency'
  | 'cache'
  | 'queue'
  | 'net'
  | 'storage';
const useMetricsForSelectedRange = (type: string) => {
  const id = useInstanceId();
  const [from, to] = useDateTimeRange();
  return useMetrics<Metric>(id, type, from, to);
};
const commonChartProps = {
  omitDataKey: false,
  dot: false,
  height: 280,
  syncId: 'leanRDB',
};

const useNet = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('mongo/net'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.mongo.stat.net')}>
      <LineView
        data={chartData}
        names={(key) => t(`db.mongo.stat.${key}`)}
        loading={loading}
        formatters={bytesFormatters}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useConnections = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('mongo/connections'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.mongo.stat.open_connections')}>
      <LineView
        data={chartData}
        names={(key) => t(`db.mongo.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
        omitDataKey={true}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};
const useStorage = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('mongo/storage'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.mongo.stat.storage')}>
      <AreaView
        stack={false}
        data={chartData}
        formatters={bytesFormatters}
        names={(key) => t(`db.mongo.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useQueue = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('mongo/queue'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.mongo.stat.queue')}>
      <LineView
        data={chartData}
        formatters={bytesFormatters}
        names={(key) => t(`db.mongo.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useCache = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('mongo/cache'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.mongo.stat.wtcache_current_bytes')}>
      <AreaView
        data={chartData}
        formatters={bytesFormatters}
        names={(key) => t(`db.mongo.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useLatency = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('mongo/latency'),
    transformMetricData
  );
  const node = (
    <ChartContainer
      title={
        <>
          {t('db.mongo.stat.latency')} <Hint content={t('db.mongo.stat.latencyHint')} />
        </>
      }
    >
      <LineView
        data={chartData}
        unit={'ms'}
        names={(key) => t(`db.mongo.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useQueries = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('mongo/queries'),
    transformMetricData
  );
  const node = (
    <ChartContainer
      title={
        <>
          {t('db.mongo.stat.queries')} <Hint content={t('db.mongo.stat.queriesHint')} />
        </>
      }
    >
      <LineView data={chartData} loading={loading} {...commonChartProps} />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useObjects = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('mongo/objects'),
    (data) => transformMetricData(data, undefined, 'db_name')
  );
  const node = (
    <ChartContainer title={t('db.mongo.stat.objects')}>
      <LineView data={chartData} loading={loading} {...commonChartProps} />
    </ChartContainer>
  );
  return [node, reload] as const;
};

export default withTracker(() => {
  const { t } = useTranslation();
  const id = useInstanceId();
  const [appInstances] = useMongo();
  const [displayMode, displayModeSwitch] = useDisplayMode();
  const [netNode, netReload] = useNet();
  const [queueNode, queueReload] = useQueue();
  const [cacheNode, cacheReload] = useCache();
  const [connectionsNode, connectionsReload] = useConnections();
  const [storageNode, storageReload] = useStorage();
  const [latencyNode, latencyReload] = useLatency();
  const [queriesNode, queriesReload] = useQueries();
  const [objectsNode, objectsReload] = useObjects();
  const [, , selectNode] = useDateTimeRangeAndSelect();

  const instanceContent = useMemo(() => {
    const currentInstance = appInstances.find((instance) => instance.id === Number(id));
    return currentInstance ? currentInstance.name : id;
  }, [appInstances, id]);

  const reload = useCallback(() => {
    netReload();
    cacheReload();
    queueReload();
    connectionsReload();
    storageReload();
    latencyReload();
    queriesReload();
    objectsReload();
  }, [
    connectionsReload,
    netReload,
    storageReload,
    queueReload,
    cacheReload,
    latencyReload,
    queriesReload,
    objectsReload,
  ]);
  return (
    <Page title={[t('label.stat'), t('db.mongo'), t('engine')]}>
      <PageContainer>
        <Breadcrumb
          className={styles.breadcrumb}
          sections={[
            { key: 'MongoDB', content: <Link to="../mongo">MongoDB</Link> },
            { key: 'instance', content: instanceContent, active: true },
          ]}
        />
        <div className={classnames('fill-space', styles.statToolbar)}>
          <Button onClick={reload} icon="refresh" />
          {displayModeSwitch}
          <span className="space" />
          {selectNode}
        </div>
        <StatContainer displayMode={displayMode}>
          {queriesNode}
          {queueNode}
          {latencyNode}

          {connectionsNode}
          {objectsNode}

          {netNode}
          {cacheNode}
          {storageNode}
        </StatContainer>
      </PageContainer>
    </Page>
  );
});
