import React, { useCallback, useMemo, memo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch, Switch, Route, useParams } from 'react-router-dom';
import { useTransform } from '@leancloud/use-resource';
import { leanDBAdminerURL } from 'config';
import { getRegionalLocaleResource } from 'utils';
import Hint, { HintedContent } from 'components/Hint';
import Page, { PageContainer } from 'components/Page';
import { MySqlMemoryBase } from 'config/feature-flags';
import Panel from 'components/Panel';
import { Placeholder, Card, Button, List, Icon } from 'components/semantic';
import { Item } from 'components/Summary';
import { withTracker } from 'utils/tracker';
import { APIError } from 'utils/use-api';
import { useCurrentApp, useAppId } from 'App/Application';
import { useClusters, useRuntime } from '..';
import InstanceCard from '../InstanceCard';
import { isTypes, ClusterInfo } from '../types';
import styles from './index.module.scss';
import InstanceModal from './InstanceModal';
import MySQLStats from './InstanceStats';

export type MySQLClusterInfo = ClusterInfo<'mysql'>;

export const useInstanceId = () => {
  return useParams<{ id: string }>().id;
};

const AdminerForm = memo(
  React.forwardRef<HTMLFormElement, { instance: MySQLClusterInfo }>(({ instance }, ref) => {
    return (
      <form action={leanDBAdminerURL} method="post" target="_blank" ref={ref}>
        <input type="hidden" name="auth[username]" value={instance.authUser} />
        <input type="hidden" name="auth[password]" value={instance.authPassword} />
        <input
          type="hidden"
          name="auth[server]"
          value={instance.proxyHost + ':' + instance.proxyPort}
        />
        <input type="hidden" name="auth[driver]" value="server" />
      </form>
    );
  })
);

export const useMySQL = () =>
  useTransform(
    useClusters(),
    useCallback((clusters) => (clusters ? clusters.filter(isTypes(['mysql'])) : []), [])
  );

const MySQLDBInstanceCard = memo(
  ({
    instance,
    onDeleted,
    onUpdated,
  }: {
    instance: MySQLClusterInfo;
    onDeleted: (id: number) => void;
    onUpdated: (id: number, payload: Partial<MySQLClusterInfo>) => void;
  }) => {
    const { t } = useTranslation();
    const [currentApp] = useCurrentApp();
    const formRef = useRef<HTMLFormElement>(null);
    const { name, nodeQuota, storageQuota } = instance;
    const [runtimeInfo] = useRuntime('mysql');
    const { memory, storage } = useMemo(() => {
      if (!runtimeInfo) {
        return {};
      }
      return {
        memory: runtimeInfo.nodeQuotaMap[nodeQuota].memory,
        storage: runtimeInfo.storageQuotaMap[storageQuota].storage,
      };
    }, [runtimeInfo, nodeQuota, storageQuota]);

    const shared = useMemo(
      () => currentApp && instance.appId !== currentApp.appId,
      [currentApp, instance]
    );

    const suffix = useMemo(
      () => (
        <>
          GB{' '}
          {!shared && (
            <InstanceModal
              instanceData={instance}
              onUpdated={onUpdated}
              modalProps={{
                trigger: (
                  <Button
                    disabled={instance.status !== 'running'}
                    content={t('action.scale')}
                    size="mini"
                  />
                ),
              }}
            />
          )}
        </>
      ),
      [instance, onUpdated, shared, t]
    );

    return (
      <InstanceCard
        onDeleted={onDeleted}
        instance={instance}
        extraInfo={
          <>
            <p>
              <Icon name="info" color="green" />
              {t('label.envVariable')}{' '}
              <Hint
                content={
                  <Trans i18nKey="db.udb.env.hint">
                    Some environment variables for connecting to MySQL will be injected when a
                    deployment is performed on the LeanEngine instances used for LeanDB.
                    <a
                      href={getRegionalLocaleResource(
                        {
                          // eslint-disable-next-line i18n/no-chinese-character
                          'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/sdk/engine/database/mysql/#在云引擎中使用`,
                          'us-w1': {
                            // eslint-disable-next-line i18n/no-chinese-character
                            zh: 'https://docs.leancloud.cn/sdk/engine/database/mysql/#在云引擎中使用',
                            en: 'https://docs.leancloud.cn/en/sdk/engine/database/mysql/#accessing-from-cloud-engine',
                          },
                        },
                        // eslint-disable-next-line i18n/no-chinese-character
                        'https://docs.leancloud.cn/sdk/engine/database/mysql/#在云引擎中使用'
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Doc)
                    </a>
                  </Trans>
                }
                hoverable
              />
            </p>
            <List bulleted className={styles.envList}>
              <List.Item>MYSQL_PORT_{name}</List.Item>
              <List.Item>MYSQL_HOST_{name}</List.Item>
              <List.Item>MYSQL_ADMIN_USER_{name}</List.Item>
              <List.Item>MYSQL_ADMIN_PASSWORD_{name}</List.Item>
            </List>
          </>
        }
        extraOperations={
          <Button size="small" onClick={() => formRef.current?.submit()}>
            <Icon name="user cog" />
            {t('db.udb.adminPanel')}
            <AdminerForm ref={formRef} instance={instance} />
          </Button>
        }
      >
        <Item
          name={t('db.quota.memory')}
          value={memory ? memory / MySqlMemoryBase : undefined}
          suffix={suffix}
        />
        <Item name={t('db.storage')} value={storage} suffix={suffix} />
      </InstanceCard>
    );
  }
);

const MySQLList = withTracker(() => {
  const { t } = useTranslation();
  const appId = useAppId();
  const [currentApp] = useCurrentApp();
  const [instances, { error, loading, reload, delete: _delete, update }] = useMySQL();

  const currentAppInstance = useMemo(
    () => instances.filter((instance) => instance.appId === appId),
    [appId, instances]
  );
  const otherAppInstance = useMemo(
    () => instances.filter((instance) => instance.appId !== appId),
    [appId, instances]
  );

  return (
    <Page title={[t('db.udb'), t('engine')]}>
      <PageContainer>
        <APIError message={t('db.udb.fetchError')} error={error} retry={reload} />
        <Panel title={t('db.instance.currentAppInstance')}>
          {loading ? (
            <Placeholder line={4} />
          ) : (
            <>
              {currentAppInstance.length > 0 && (
                <Card.Group>
                  {currentAppInstance.map((instance) => (
                    <MySQLDBInstanceCard
                      instance={instance}
                      key={instance.id}
                      onDeleted={_delete}
                      onUpdated={update}
                    />
                  ))}
                </Card.Group>
              )}
              {currentAppInstance.length === 0 && <p>{t('label.none')}</p>}
              <p>
                {currentApp?.isOwner ? (
                  <InstanceModal
                    onCreated={reload}
                    modalProps={{
                      trigger: <Button content={t('db.instance.create')} />,
                    }}
                  />
                ) : (
                  <HintedContent
                    inline
                    position="top left"
                    content={t('label.onlyOwner.create')}
                    trigger={<Button disabled content={t('db.instance.create')} />}
                  />
                )}
              </p>
            </>
          )}
        </Panel>
        <Panel title={t('db.instance.otherAppInstance')}>
          {loading ? (
            <Placeholder line={4} />
          ) : otherAppInstance.length === 0 ? (
            <p>{t('label.none')}</p>
          ) : (
            <Card.Group>
              {otherAppInstance.map((instance) => (
                <MySQLDBInstanceCard
                  instance={instance}
                  key={instance.id}
                  onDeleted={_delete}
                  onUpdated={update}
                />
              ))}
            </Card.Group>
          )}
        </Panel>
      </PageContainer>
    </Page>
  );
});

export default () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:id`} component={MySQLStats} exact />
      <Route component={MySQLList} exact />
    </Switch>
  );
};
