import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Popup, PopupProps } from 'components/semantic';
import styles from './index.module.scss';

type RadioValue = string | number | undefined;

export interface RadioProps<T extends RadioValue> {
  name?: string;
  value: T;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  children?: ReactNode;
  label?: ReactNode;
  hint?: PopupProps['content'];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, payload: { value: T }) => void;
  className?: string;
}

const { disabled: disabledClassName, checked: checkedClassName } = styles;
export { disabledClassName, checkedClassName };

export default function Radio<T extends RadioValue>({
  name,
  value,
  checked,
  disabled,
  required,
  onChange,
  label: labalContent,
  hint,
  className,
  children,
}: RadioProps<T>) {
  const content = (
    <span
      className={classnames(styles.label, {
        [disabledClassName]: disabled,
        [checkedClassName]: checked,
      })}
    >
      {labalContent || children || value}
    </span>
  );
  return (
    <label className={classnames(styles.container, className)}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        required={required}
        className={styles.radioInput}
        onChange={(e) => {
          if (onChange) {
            onChange(e, { value });
          }
        }}
      />
      {hint ? <Popup trigger={content} content={hint} key={value} hoverable /> : content}
    </label>
  );
}

type RadioGroupItem<T extends RadioValue> = Pick<
  RadioProps<T>,
  'value' | 'label' | 'hint' | 'disabled'
>;
export interface RadioGroupProps<T extends RadioValue> {
  name: string;
  value?: T;
  radios: Array<RadioGroupItem<T>>;
  required?: boolean;
  onChange?: RadioProps<T>['onChange'];
  className?: string;
  vertical?: boolean;
}
export function RadioGroup<T extends RadioValue>({
  name,
  value: activeValue,
  radios,
  required,
  onChange,
  className,
  vertical,
}: RadioGroupProps<T>) {
  return (
    <div
      className={classnames(styles.groupContainer, {
        [styles.vertical]: vertical,
      })}
    >
      {radios.map(({ value, label: labelContent, hint, disabled }) => (
        <Radio
          name={name}
          key={value}
          value={value}
          required={required}
          disabled={disabled}
          checked={value === activeValue}
          label={labelContent}
          hint={hint}
          onChange={onChange}
          className={className}
        />
      ))}
    </div>
  );
}
