import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import { isTruthy } from 'utils';
import { TabWithPath } from 'components/Tab';
import config from '../config';
import { TDSEngineMenu } from './Engine';
import { Header } from './Header';
import styles from './index.module.scss';
import {
  DataLakeAllowedAppIds,
  LiveQueryAllowedAppIds,
  SearchAllowedAppIds,
  hideDataLake,
  hideLiveQuery,
  hideSearch,
} from 'config/feature-flags';
import { useAppId } from 'App/Application';

const TDSMenu = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const appId = useAppId();

  const match = useRouteMatch<{ service: string }>(`${props.match.path}/:service`);
  const { service: matchedService } = match?.params || {};
  const currentService = config.find((service) => service.path === matchedService);

  if (!currentService) return null;

  // Trim the trailing slash for some pages (a.g. App Overview)
  const baseUrl = _.trimEnd(props.match.url, '/') + '/' + currentService.path;

  if (currentService.path === 'engine') {
    return <TDSEngineMenu />;
  }

  return (
    <>
      <Header
        title={t(currentService.i18nKey || currentService.path || '')}
        document={currentService.docs}
      />
      {currentService.items && (
        <TabWithPath
          className={styles.tab}
          basePath={baseUrl}
          panes={currentService.items
            .filter(isTruthy)
            .filter(({ path }) => {
              if (
                hideLiveQuery &&
                path === 'live-query' &&
                !LiveQueryAllowedAppIds.includes(appId)
              ) {
                return false;
              }

              if (hideSearch && path === 'app-search' && !SearchAllowedAppIds.includes(appId)) {
                return false;
              }

              if (hideDataLake && path === 'data-lake' && !DataLakeAllowedAppIds.includes(appId)) {
                return false;
              }
              return true;
            })
            .map(({ path, i18nKey }) => ({
              id: path,
              menuItem: i18nKey ? t(i18nKey) : path,
            }))}
          defaultActiveIndex={-1}
        />
      )}
    </>
  );
};

export default withRouter(TDSMenu);
