import { getLocale } from 'i18n';
import moment, { Moment } from 'moment';
import {
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfMonth,
  startOfYear,
  startOfWeek,
  endOfDay,
  endOfWeek,
  endOfMonth,
  endOfYear,
  endOfHour,
  endOfMinute,
  subMinutes,
  addMinutes,
} from 'date-fns';

export const formatTimeZone = (utcOffset: number) => {
  if (utcOffset === 0) return 'UTC';
  const hours = Math.floor(Math.abs(utcOffset / 60));
  const minutes = Math.abs(utcOffset) % 60;
  return `UTC${utcOffset < 0 ? '-' : '+'}${hours}${minutes === 0 ? '' : `:${minutes}`}`;
};
enum TimeAccuracy {
  NONE,
  MINITE,
  SECOND,
}
const dateTimeFormats = new Map<string, Intl.DateTimeFormat>();
const getDateTimeFormat = (locale: string, timeAccuracy: TimeAccuracy, omitYear: boolean) => {
  const key = `${locale}::${omitYear}::${timeAccuracy}`;
  const cachedFormat = dateTimeFormats.get(key);
  if (cachedFormat) {
    return cachedFormat;
  }
  let options: Partial<Intl.DateTimeFormatOptions> = {};
  switch (timeAccuracy) {
    case TimeAccuracy.SECOND:
      options = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
      break;
    case TimeAccuracy.MINITE:
      options = {
        hour: '2-digit',
        minute: '2-digit',
      };
      break;
  }
  const format = new Intl.DateTimeFormat(locale, {
    year: omitYear ? undefined : 'numeric',
    month: 'short',
    day: 'numeric',
    hourCycle: 'h23',
    ...options,
  });
  dateTimeFormats.set(key, format);
  return format;
};
const currentYear = moment().year();

export const localUtcOffset = -new Date().getTimezoneOffset();
let currentUtcOffset: number;
export const setCurrentUtcOffset = (utcOffset: number = localUtcOffset) => {
  currentUtcOffset = utcOffset * (Math.abs(utcOffset) < 16 ? 60 : 1);
};
setCurrentUtcOffset();
export const getCurrentUtcOffset = () => currentUtcOffset;
if (process.env.NODE_ENV !== 'production') {
  window['setCurrentUtcOffset'] = setCurrentUtcOffset;
  window['getCurrentUtcOffset'] = getCurrentUtcOffset;
}

export enum MomentTimeFormat {
  EDITABLE_DATETIME_WITH_SECOND = 'YYYY-MM-DD HH:mm:ss',
  EDITABLE_DATETIME = 'YYYY-MM-DD HH:mm',
  EDITABLE_TIME = 'HH:mm',
  EDITABLE_TIME_WITH_SECOND = 'HH:mm:ss',
  EDITABLE_TIME_WITH_MILLISECOND = 'HH:mm:ss.SSS',
  EDITABLE = 'YYYY-MM-DD',
  DISPLAY_DATETIME_FULL_WITH_SECOND = 'll LTS',
  DISPLAY_MONTH = 'YYYY-MM',
  /**
   * @warning Is the year needed all the time?
   * If not, you should probably use DISPLAY_DATETIME.
   */
  DISPLAY_DATETIME_FULL = 'lll',
  /**
   * @warning Is the year needed all the time?
   * If not, you should probably use DISPLAY.
   */
  DISPLAY_FULL = 'll',
  QUERY = 'YYYYMMDD',
  QUERY_MONTH = 'YYYYMM',
}
enum ExtendTimeFormat {
  DISPLAY_DATETIME_WITH_SECOND,
  DISPLAY_DATETIME,
  DISPLAY,
  TO_NOW,
  FROM_NOW,
}

export const TimeFormat = {
  ...MomentTimeFormat,
  ...ExtendTimeFormat,
};
export const formatTime = (
  date: Date | Moment | string,
  format: MomentTimeFormat | ExtendTimeFormat | string = TimeFormat.DISPLAY,
  // Display as the time at specified tz.
  utcOffset: number = currentUtcOffset
): string => {
  let timeAccuracy = TimeAccuracy.NONE;
  let fallbackFormat: MomentTimeFormat = TimeFormat.DISPLAY_FULL;
  switch (format) {
    case TimeFormat.DISPLAY_DATETIME_WITH_SECOND:
      timeAccuracy = TimeAccuracy.SECOND;
      fallbackFormat = TimeFormat.DISPLAY_DATETIME_FULL_WITH_SECOND;
      break;
    case TimeFormat.DISPLAY_DATETIME:
      timeAccuracy = TimeAccuracy.MINITE;
      fallbackFormat = TimeFormat.DISPLAY_DATETIME_FULL;
  }

  switch (format) {
    case TimeFormat.TO_NOW:
      return moment(date).toNow();
    case TimeFormat.FROM_NOW:
      return moment(date).fromNow();
  }

  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  momentDate.add(utcOffset - localUtcOffset, 'minute');

  switch (format) {
    case TimeFormat.DISPLAY_DATETIME_WITH_SECOND:
    case TimeFormat.DISPLAY_DATETIME:
    case TimeFormat.DISPLAY: {
      const locale = getLocale();
      // @ts-ignore
      if (Intl && Intl.DateTimeFormat) {
        const isCurrentYear = currentYear === momentDate.year();
        const omitYear = isCurrentYear;
        return getDateTimeFormat(locale, timeAccuracy, omitYear).format(momentDate.toDate());
      }
      return formatTime(date, fallbackFormat, utcOffset);
    }
    default:
      return momentDate.format(format);
  }
};
const formatTimeInt = (num: number) => {
  return num < 10 ? `0${num}` : num;
};

export function subUTCOffset(date: Date, offset: number) {
  return subMinutes(date, offset - localUtcOffset);
}

export function addUTCOffset(date: Date, offset: number) {
  return addMinutes(date, offset - localUtcOffset);
}
export const formatDuration = (...props: Parameters<typeof moment.duration>) => {
  const duration = moment.duration(...props);
  const hours = Math.floor(duration.asHours());
  const minutes = duration.get('minutes');
  let seconds = Math.ceil(duration.asSeconds()) % 60;

  return `${formatTimeInt(hours)}:${formatTimeInt(minutes)}:${formatTimeInt(seconds)}`;
};
type Unit = 'd' | 'w' | 'M' | 'y' | 'h' | 'm';
function _startOf(date: Date, unit: Unit | string) {
  switch (unit) {
    case 'd':
      return startOfDay(date);
    case 'w':
      return startOfWeek(date);
    case 'M':
      return startOfMonth(date);
    case 'y':
      return startOfYear(date);
    case 'h':
      return startOfHour(date);
    case 'm':
      return startOfMinute(date);
    default:
      return date;
  }
}
export function startOf(
  date: Date,
  unit: Unit | string,
  targetUtcOffset: number = getCurrentUtcOffset()
) {
  return subUTCOffset(_startOf(addUTCOffset(date, targetUtcOffset), unit), targetUtcOffset);
}
function _endOf(date: Date, unit: Unit | string) {
  switch (unit) {
    case 'd':
      return endOfDay(date);
    case 'w':
      return endOfWeek(date);
    case 'M':
      return endOfMonth(date);
    case 'y':
      return endOfYear(date);
    case 'h':
      return endOfHour(date);
    case 'm':
      return endOfMinute(date);
    default:
      return date;
  }
}
export function endOf(
  date: Date,
  unit: Unit | string,
  targetUtcOffset: number = getCurrentUtcOffset()
) {
  return subUTCOffset(_endOf(addUTCOffset(date, targetUtcOffset), unit), targetUtcOffset);
}
