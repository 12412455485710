import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Page, { PageContainer } from 'components/Page';
import { Breadcrumb, Button } from 'components/semantic';
import { LineView, bytesFormatters } from 'components/StatisticView';
import { useTransform } from 'utils/use-api';
import { useDisplayMode } from '../../Stats/parts';
import { useDateTimeRange, useDateTimeRangeAndSelect } from '../../use-date-range';
import { StatContainer, transformMetricData, useMetrics } from '../utils';
import { useInstanceId, useRedis } from './';
import styles from './index.module.scss';
import ChartContainer from '../ChartContainer';

export type RedisMetric =
  | 'clients'
  | 'blocked_clients'
  | 'keys'
  | 'expires'
  | 'cpu_usages'
  | 'used_memory'
  | 'total_commands_processed'
  | 'average_qps'
  | 'total_net_input_bytes'
  | 'total_net_output_bytes'
  | 'keyspace_hitrate';

const PercentFormatters = {
  yAxisDisplay: (value: number) => value.toFixed(1),
};
const transformPercent = (value: number | null) => {
  return value ? value * 100 : value;
};

const useMetricsForSelectedRange = (type: string) => {
  const id = useInstanceId();
  const [from, to] = useDateTimeRange();
  return useMetrics<RedisMetric>(id, type, from, to);
};

const commonChartProps = {
  omitDataKey: false,
  dot: false,
  height: 280,
  syncId: 'Redis',
};

const useConnections = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('redis/connections'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.connections')}>
      <LineView
        data={chartData}
        names={(key) => t(`db.udb.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};
// todo
const useKeys = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('redis/keys'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.keys.title')}>
      <LineView
        data={chartData}
        names={(key) => t(`db.udb.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useCPU = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('redis/cpu'),
    useCallback((CPUData) => transformMetricData(CPUData, transformPercent), [])
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.cpu_usages')}>
      <LineView
        unit="%"
        data={chartData}
        formatters={PercentFormatters}
        names={(key) => t(`db.udb.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
        omitDataKey
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useMemory = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('redis/memory'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.memory')}>
      <LineView
        data={chartData}
        names={{
          used_memory: t(`db.udb.stat.memory`),
        }}
        formatters={bytesFormatters}
        loading={loading}
        {...commonChartProps}
        omitDataKey
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};
const useQueries = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('redis/queries'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.total_commands_processed')}>
      <LineView
        data={chartData}
        names={(key) => t(`db.udb.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
        omitDataKey
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};
const useQPS = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('redis/qps'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.average_qps')}>
      <LineView
        data={chartData}
        names={(key) => t(`db.udb.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
        omitDataKey
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};
const useNet = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('redis/net'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.udb.stat.net')}>
      <LineView
        data={chartData}
        names={(key) => t(`db.udb.stat.${key}`)}
        loading={loading}
        formatters={bytesFormatters}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useHitrate = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('redis/hitrate'),
    useCallback((hitrateData) => transformMetricData(hitrateData, transformPercent), [])
  );

  const node = (
    <ChartContainer title={t('db.udb.stat.keyspace_hitrate')}>
      <LineView
        unit="%"
        data={chartData}
        formatters={PercentFormatters}
        names={(key) => t(`db.udb.stat.${key}`)}
        loading={loading}
        {...commonChartProps}
        omitDataKey
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

export default () => {
  const { t } = useTranslation();
  const id = useInstanceId();
  const [appInstances] = useRedis();
  const [displayMode, displayModeSwitch] = useDisplayMode();
  const [, , selectNode] = useDateTimeRangeAndSelect();
  const [connectionsNode, realoadConnections] = useConnections();
  const [keysNode, reloadKeys] = useKeys();
  const [CPUNode, reloadCPU] = useCPU();
  const [MemoryNode, reloadMemory] = useMemory();
  const [QueriesNode, reloadQueries] = useQueries();
  const [QPSNode, reloadQPS] = useQPS();
  const [NetNode, reloadNet] = useNet();
  const [HitrateNode, reloadHitrate] = useHitrate();

  const instanceContent = useMemo(() => {
    const currentInstance = appInstances.find((instance) => instance.id === Number(id));
    return currentInstance ? currentInstance.name : id;
  }, [appInstances, id]);
  const reload = useCallback(() => {
    realoadConnections();
    reloadKeys();
    reloadCPU();
    reloadMemory();
    reloadQPS();
    reloadNet();
    reloadHitrate();
    reloadQueries();
  }, [
    realoadConnections,
    reloadKeys,
    reloadCPU,
    reloadMemory,
    reloadQueries,
    reloadHitrate,
    reloadQPS,
    reloadNet,
  ]);
  return (
    <Page title={[t('label.stat'), t('db.redis'), t('engine')]}>
      <PageContainer>
        <Breadcrumb
          className={styles.breadcrumb}
          sections={[
            { key: 'redis', content: <Link to="../redis">Redis</Link> },
            { key: 'instance', content: instanceContent, active: true },
          ]}
        />
        <div className={classnames('fill-space', styles.statToolbar)}>
          <Button onClick={reload} icon="refresh" />
          {displayModeSwitch}
          <span className="space" />
          {selectNode}
        </div>
        <StatContainer displayMode={displayMode}>
          {connectionsNode}
          {QPSNode}
          {CPUNode}
          {MemoryNode}
          {QueriesNode}
          {HitrateNode}
          {NetNode}
          {keysNode}
        </StatContainer>
      </PageContainer>
    </Page>
  );
};
