import React, { useMemo } from 'react';
import { memoize } from 'lodash';
import {
  Area,
  AreaChart,
  AreaProps,
  CartesianGrid,
  Legend,
  LegendValueFormatter,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styles from '../index.module.scss';
import { TimeSerialDataAreaViewProps, X_DATA_KEY } from '.';
import { useFormatters } from './format';
import useToggleableLegend from './use-toggleable-legend';
import useZoom from './use-zoom';
import { useChartData, useNames, useDualYAxis } from './utils';

const defaultAreaProps: Partial<AreaProps> = {
  isAnimationActive: true,
  animationDuration: 200,
  connectNulls: false,
};

const empty = {};

const yAxisId = 1;

export default (
  props: TimeSerialDataAreaViewProps & {
    stack?: boolean;
  }
) => {
  const {
    data,
    unit,
    names: namesConfig = empty,
    dot = false,
    stack = true,
    colors,
    height,
    syncId,
    omitDataKey = false,
    dualYAxis,
  } = props;

  const names = useNames(namesConfig, data);
  const unitWithSpace = unit ? ` ${unit}` : unit;

  const chartData = useChartData(data);

  const { selectedData, onMouseDown, onMouseMove, onMouseUp, referenceArea, resetButton } =
    useZoom(chartData);
  const { inactiveLines, getInactiveProps, onLegendClick } = useToggleableLegend(data, colors);

  const seriesPropsList = useMemo(() => {
    if (data && data[0]) {
      return Object.keys(data[0][1]).map((key, index) => {
        const displayName = names[key] || key;
        return {
          name: displayName,
          ...getInactiveProps(key, index),
        };
      });
    }
    return [];
  }, [data, getInactiveProps, names]);

  const [{ yAxisTick, xTickFormatter, tooltipLabelFormatter, tooltipFormatter }] = useFormatters({
    ...props,
    omitDataKey,
    names,
    seriesLength: seriesPropsList.length,
  });

  const [rightYAxis, { getSerieYAxisId }] = useDualYAxis({
    tickFormatter: yAxisTick,
    ...dualYAxis,
  });

  const legendFormatter: LegendValueFormatter = memoize((value, entry) => {
    // @ts-ignore
    const disable = entry && inactiveLines[entry.dataKey as string];
    return <span className={disable ? 'disabled' : ''}>{value}</span>;
  });

  return (
    <>
      <ResponsiveContainer debounce={1} height={height} className={styles.chart}>
        <AreaChart
          data={selectedData}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          syncId={syncId}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={X_DATA_KEY} tickFormatter={xTickFormatter} />
          <YAxis yAxisId={yAxisId} unit={unitWithSpace} tickFormatter={yAxisTick} />
          {rightYAxis}
          <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter} />
          {seriesPropsList.length > 1 && (
            <Legend
              formatter={legendFormatter}
              verticalAlign="bottom"
              iconSize={18}
              iconType={dot ? 'line' : 'plainline'}
              onClick={onLegendClick}
            />
          )}
          {seriesPropsList.map((lineProps, index) => (
            <Area
              {...defaultAreaProps}
              {...lineProps}
              key={lineProps.dataKey}
              unit={unitWithSpace}
              dot={dot}
              fillOpacity={0.4}
              yAxisId={getSerieYAxisId(lineProps.dataKey, yAxisId)}
              stackId={stack && seriesPropsList.length > 1 ? 0 : undefined}
            />
          ))}

          {referenceArea}
        </AreaChart>
      </ResponsiveContainer>
      {resetButton}
    </>
  );
};
