import { useCallback } from 'react';
import { createLazyResourceStore } from '@leancloud/use-resource';
import { Country, isCN } from 'env';
import { useAPI, useTransform, initialResource } from 'utils/use-api';
import { BILLING_CENTER_PREFIX } from 'utils/request';

export type PricingServiceItem = {
  API: '10K' | 'STORAGE';
  LeaderBoard: 'Requests' | 'Records';
  MultiPlayer: 'CCU' | 'Flow';
  RTM: 'QPM' | '10KU' | 'Blacklist' | 'ConvMemberInfo';
  Push: 'Delivery' | 'QPM';
  LiveQuery: 'Messages' | 'Subscriptions';
  LeanCache: '128M' | '256M' | '512M' | '1024M' | '2048M' | '4096M' | '8192M';
  LeanDB:
    | 'es-512'
    | 'es-1024'
    | 'es-2048'
    | 'es-4096'
    | 'es-8192'
    | 'es-extra-storage'
    | 'mongo-512'
    | 'mongo-1024'
    | 'mongo-2048'
    | 'mongo-4096'
    | 'mongo-8192'
    | 'mongo-extra-storage'
    | 'redis-128'
    | 'redis-256'
    | 'redis-512'
    | 'redis-1024'
    | 'redis-2048'
    | 'redis-4096'
    | 'redis-8192'
    | 'mysql-500'
    | 'mysql-1000'
    | 'mysql-2000'
    | 'mysql-4000'
    | 'mysql-storage-20'
    | 'mysql-storage-100'
    | 'mysql-storage-500'
    | 'udb-500'
    | 'udb-1000'
    | 'udb-2000'
    | 'udb-4000'
    | 'udb-storage-20'
    | 'udb-storage-100'
    | 'udb-storage-500';

  LeanEngine: 'OutboundFlow' | 'MultiGroup' | '0.5CPU' | '1CPU' | '2CPU' | '4CPU';
  ClientEngine: '100CCU';
  s3: 'SPACE' | 'FLOW';
  Package: 'BIZ' | 'OPEN';
  ETC: 'CAPTCHA';
  // 以下是中国区独有
  SMS: 'CHANNEL';
  qiniu: 'SPACE' | 'FLOW_CN' | 'FLOW_CN_SECURE' | 'FLOW_OVERSEA' | 'FLOW_OVERSEA_SECURE';
  qcloud: 'FLOW' | 'FLOW_CDN' | 'FLOW_ORIGIN';
  RDB: '20G_A' | '20G_B' | '0.5G' | '1G' | '2G' | '4G' | '100GB' | '500GB';
  Search: 'IndexSize' | 'Requests';
};

export type PricingServiceType = keyof PricingServiceItem;

type SKU<T extends PricingServiceType> = {
  service: T;
  item: PricingServiceItem[T];
  price: number;
  off_price: number;
  quota: number;
  per_rate: string;
};
type Pricing = SKU<PricingServiceType>[];

type PricingData = {
  [key in Country]: {
    // just use the static currency from config
    // currency: Currency[key];
    exch_rate: number;
    pricing: Pricing;
  };
};

const { useResource: usePricing, Provider } = createLazyResourceStore(
  ({ useTriggered }) =>
    () => {
      return useTransform(
        useAPI<PricingData>(useTriggered() ? `${BILLING_CENTER_PREFIX}/pricing` : undefined),
        useCallback((data) => {
          const priceContry: Country = isCN ? 'cn' : 'us';
          return data?.[priceContry]?.pricing;
        }, [])
      );
    },
  initialResource
);

export { usePricing, Provider };
