export type Region = 'cn-n1' | 'cn-e1' | 'us-w1' | 'cn-tds1' | 'ap-sg' | 'cn-rnd';
export type Country = 'us' | 'cn';
export const region = (process.env.REACT_APP_REGION || 'cn-n1').toLowerCase() as Region;
export const country = region.split('-')[0] as Country;
/**
 * 你几乎不会在业务代码中直接用该变量
 * 如果你想要控制不同节点或国家在功能上的差异请在 ./config/feature-flags.ts 中定义
 */
export const isCN = country === 'cn';
/**
 * 你几乎不会在业务代码中直接用该变量
 * 如果你想要控制不同节点或国家在功能上的差异请在 ./config/feature-flags.ts 中定义
 */
export const isIntl = country !== 'cn';
/**
 * 你几乎不会在业务代码中直接用该变量
 * 如果你想要控制不同节点或国家在功能上的差异请在 ./config/feature-flags.ts 中定义
 */
export const isCNN1 = region === 'cn-n1';
/**
 * 你几乎不会在业务代码中直接用该变量
 * 如果你想要控制不同节点或国家在功能上的差异请在 ./config/feature-flags.ts 中定义
 */
export const isCNE1 = region === 'cn-e1';

export const isUS = country === 'us';

export const isCNN1Test = region === 'cn-rnd';

export const isTDS1 = region === 'cn-tds1';
export const isAPSG = region === 'ap-sg';
export const TDS = process.env.REACT_APP_TDS === 'true';
export const TDSStyle = process.env.REACT_APP_TDS_STYLE === 'true';
