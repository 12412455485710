import React, { memo, useState, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { bizAppMinCharge, currency } from 'config';
import { getRegionalLocaleResource } from 'utils';
import ExternalLink from 'components/ExternalLink';
import { RadioGroup } from 'components/Radio';
import { withModal, Modal, Form, LinkButton, Button, Checkbox, Icon } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { CLIENT_CENTER_VERSION } from 'utils/request';
import { appNamePattern } from 'config/validations';
import { useApps } from 'App/Apps';
import { Application } from 'App/types';
import styles from './index.module.scss';

const CustomRadio = memo<{
  title: React.ReactNode;
  explaination: React.ReactNode;
  checked: boolean;
}>(({ title, explaination, checked }) => {
  const iconName = checked ? 'check circle outline' : 'circle outline';
  return (
    <div className={styles.radio}>
      <Icon name={iconName} className={styles.checkFlag} size="large" />
      <div>
        <p className={styles.title}>{title}</p>
        <p className="help-block">{explaination}</p>
      </div>
    </div>
  );
});

const formId = 'createApp';
export default withModal()(({ close }) => {
  const { t } = useTranslation();
  const [apps, { reload }] = useApps();
  const appOptions = useMemo(
    () => (apps ? apps.map((app) => [app.appId, app.appName] as const) : []),
    [apps]
  );
  const [name, setName] = useState('');
  const [appType, setAppType] = useState<Application['bizType']>('dev');
  const [createByExist, setCreateByExist] = useState(false);
  const [sourceApp, setSourceApp] = useState('');
  const [description, setDescription] = useState<string>();
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setLoading(true);
      await request(`/client-center/${CLIENT_CENTER_VERSION}/clients/self/apps`, {
        method: 'POST',
        body: {
          appName: name.trim(),
          bizType: appType,
          srcAppId: createByExist && sourceApp ? sourceApp : undefined,
          description:
            description === undefined ? undefined : description.trim() ? description : undefined,
        },
      });
      setLoading(false);
      reload();
      toast.success(t('action.create.successfully'));
      close();
    } catch (error) {
      setLoading(false);
      toast.error(t('action.create.failed'), error);
    }
  };

  return (
    <>
      <Modal.Header content={t('app.createApp')} />
      <Modal.Content>
        <Form id={formId} onSubmit={submit}>
          <Form.Input
            label={t('label.appName')}
            required
            name="name"
            id="name"
            value={name}
            title={t('validation.appName')}
            pattern={appNamePattern}
            autoFocus
            onChange={(e, { value }) => setName(value.trimStart())}
          />
          <Form.Field required>
            <label htmlFor="appType" className={styles.appPrice}>
              <Trans i18nKey="app.createApp.pricePlan">
                App price
                <ExternalLink
                  href={getRegionalLocaleResource(
                    {
                      'us-w1': 'https://www.leancloud.app/pricing/',
                    },
                    'https://www.leancloud.cn/pricing/'
                  )}
                >
                  plan
                </ExternalLink>
              </Trans>
            </label>
            <RadioGroup
              name="appType"
              required
              value={appType}
              onChange={(e, { value }) => {
                if (value === 'dev') {
                  setCreateByExist(false);
                }
                setAppType(value);
              }}
              className={styles.radioContainer}
              radios={[
                {
                  value: 'dev',
                  label: (
                    <CustomRadio
                      title={t('label.plan.developer')}
                      checked={appType === 'dev'}
                      explaination={t('app.createApp.devAdvantage')}
                    />
                  ),
                },
                {
                  value: 'biz',
                  label: (
                    <CustomRadio
                      title={t('app.createApp.biz', {
                        minCharge: bizAppMinCharge,
                        currency,
                      })}
                      checked={appType === 'biz'}
                      explaination={t('app.createApp.bizAdvantage')}
                    />
                  ),
                },
              ]}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label={t('app.createApp.byClass')}
              disabled={appType !== 'biz'}
              name="createByExist"
              id="createByExist"
              checked={createByExist}
              onChange={(e, { checked }) => setCreateByExist(!!checked)}
            />{' '}
            <p className="help-block">{t('label.onlyBiz')}</p>
          </Form.Field>
          {createByExist && (
            <div className="checkbox-sub-content">
              <Form.NativeSelect
                value={sourceApp}
                onChange={(e, { value }) => setSourceApp(value)}
                required
                label={t('app.createApp.sourceApp')}
                options={appOptions}
              />
            </div>
          )}
          <Form.TextArea
            label={t('app.createApp.description')}
            value={description}
            onChange={(e, data) => setDescription(`${data.value}`)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton content={t('action.cancel')} onClick={close} />
        <Button
          type="submit"
          form={formId}
          content={t('action.create')}
          primary
          loading={loading}
          disabled={loading}
        />
      </Modal.Actions>
    </>
  );
});
