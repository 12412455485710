import React, { useState, useMemo, memo, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Form, LinkButton, Button, withModal, Input, Message } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { API_VERSION } from 'utils/request';
import { RadioGroup } from 'components/Radio';
import { useAppId } from 'App/Application';
import { useQuota } from '../quota';
import { newESEnabled } from 'config/feature-flags';
import { useRuntime } from '..';
import { usePricing } from 'App/Pricing';
import { Item, dividedClassName } from 'components/Summary';
import { currency } from 'config';
import { ESClusterInfo } from '.';
import styles from './index.module.scss';
import classNames from 'classnames';

interface Props {
  onCreated?: () => void;
  instanceData?: ESClusterInfo;
  onUpdated?: () => void;
}

const FORM_ID = 'leanDBES';

export const MYSQL_MEMORY_BASE = 1024;

const RadioItem = memo<{ value: number; unit: string; item: string }>(({ value, unit, item }) => {
  const { t } = useTranslation();
  const [SKUs, { loading }] = usePricing();
  const matchedSKU = useMemo(() => {
    //udb-storage-20 由前端提供价格
    if (item === 'udb-storage-20') {
      return {
        price: 0,
      };
    }
    return SKUs?.find((SKU) => SKU.service === 'LeanDB' && SKU.item === item);
  }, [SKUs, item]);
  return (
    <div className={styles.radioItem}>
      <div>
        <span className="spec-value">{value}</span> {unit}
      </div>
      <div>
        <span className="spec-value">{loading ? '?' : matchedSKU?.price ?? 'unknown'}</span>{' '}
        {`${currency} / ${t('label.day')}`}
      </div>
    </div>
  );
});

export default withModal<Props>()(({ close, onCreated }) => {
  const { t } = useTranslation();
  const appId = useAppId();
  const [loading, setLoading] = useState(false);
  const [runtimeInfo] = useRuntime('es');
  const [name, setName] = useState('');
  const [quota, { standardQuotaRadioGroup, testQuotaRadioGroup }] = useQuota('es');
  const [usQuota, setUsQuota] = useState<string>('');
  const [usStorage, setUsStorage] = useState<string>('');
  const [SKUs] = usePricing();

  const { nodeQuotas, storageQuotas } = useMemo(() => {
    return {
      nodeQuotas: runtimeInfo?.nodeQuotas || [],
      storageQuotas: runtimeInfo?.storageQuotas || [],
    };
  }, [runtimeInfo]);

  const getMatchedSKU = useCallback(
    (item: string) => {
      return SKUs?.find((SKU) => SKU.service === 'LeanDB' && SKU.item === item);
    },
    [SKUs]
  );

  const totalPrice = useMemo(() => {
    if (usQuota === undefined || usStorage === undefined) return;
    const quotaPrice = getMatchedSKU(usQuota)?.price;
    if (quotaPrice === undefined) return;
    const storagaPrice = getMatchedSKU(usStorage)?.price ?? 0;
    return quotaPrice + storagaPrice;
  }, [usQuota, usStorage, getMatchedSKU]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await request<ESClusterInfo>(`/${API_VERSION}/leandb/clusters?appId=${appId}`, {
        method: 'POST',
        body: {
          name,
          nodeQuota: newESEnabled ? usQuota : quota,
          storageQuota: newESEnabled ? usStorage : quota,
          runtime: 'es',
        },
      });
      onCreated && onCreated();
      setLoading(false);
      toast.success(t('action.create.successfully'));
      close();
    } catch (error) {
      toast.error(t('action.create.failed'), error);
      setLoading(false);
    }
  };
  return (
    <>
      <Modal.Header
        content={
          <Trans
            values={{
              type: t('db.es'),
            }}
            i18nKey="db.instance.createWithType"
          />
        }
      />
      <Modal.Content>
        <Form id={FORM_ID} onSubmit={onSubmit}>
          <Form.Field required>
            <label htmlFor="instance">{t('label.name')}</label>
            <Input
              name="instance"
              id="instance"
              required
              autoFocus
              pattern="[a-zA-Z][a-zA-Z0-9_]{0,31}$"
              onChange={(e, { value }) => setName(value)}
            />
            <p className="help-block">{t('db.instance.nameHint')}</p>
          </Form.Field>
          {newESEnabled && (
            <>
              <Form.Field required>
                <label htmlFor="quota">{t('db.quota.memory')}</label>
                <RadioGroup
                  required
                  name="quota"
                  value={usQuota}
                  radios={nodeQuotas.map((quotaItem) => {
                    return {
                      value: quotaItem.id,
                      label: (
                        <RadioItem
                          value={quotaItem.memory / MYSQL_MEMORY_BASE}
                          unit={'GB'}
                          item={quotaItem.id}
                        />
                      ),
                    };
                  })}
                  onChange={(e, data) => setUsQuota(data.value)}
                />
              </Form.Field>
              <Form.Field>
                <Form.Field required>
                  <label htmlFor="storage">{t('db.storage')}</label>
                  <RadioGroup
                    required
                    name="storage"
                    value={usStorage}
                    radios={storageQuotas.map((quotaItem) => ({
                      value: quotaItem.id,
                      disabled: !usQuota,
                      label: (
                        <RadioItem value={quotaItem.storage} unit={'GB'} item={quotaItem.id} />
                      ),
                    }))}
                    onChange={(e, data) => setUsStorage(data.value)}
                  />
                </Form.Field>
              </Form.Field>
            </>
          )}
          {!newESEnabled && (
            <>
              <Form.Field>
                <label htmlFor="max_test_memory">{t('db.quota.test')}</label>
                {testQuotaRadioGroup}
              </Form.Field>
              <Form.Field>
                <label htmlFor="max_standard_memory">{t('db.quota.standard')}</label>
                {standardQuotaRadioGroup}
              </Form.Field>
            </>
          )}
        </Form>
        {totalPrice !== undefined && newESEnabled && (
          <div className={classNames(dividedClassName, 'fill-space')}>
            <span className="space" />
            <Item
              name={t('label.totalCost')}
              value={totalPrice}
              suffix={`${currency} / ${t('label.day')}`}
            />
          </div>
        )}
        <Message warning>
          <Message.Content>
            <Message.List>
              <Message.Item content={t('db.scale.hint.es')} />
              <Message.Item content={t('db.instance.quotaHint')} />
            </Message.List>
          </Message.Content>
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton onClick={close} content={t('action.cancel')} />
        <Button
          primary
          type="submit"
          form={FORM_ID}
          content={t(`action.confirmBilling`)}
          loading={loading}
        />
      </Modal.Actions>
    </>
  );
});
