import React, { DependencyList } from 'react';
import { Trans } from 'react-i18next';
import { createResourceHook, Resource } from '@leancloud/use-resource';
import { noop } from 'lodash';
import { Button, Message } from 'components/semantic';
import { abortableRequest, RequestOptions, RequestURL } from './request';
import { ErrorDetails, StructuredError } from './structured-error';

export * from '@leancloud/use-resource';

const useRequest = createResourceHook(abortableRequest);

export function useAPI<T>(
  url?: RequestURL,
  options?: RequestOptions,
  deps: DependencyList = [url],
  condition = url !== undefined
): Resource<T | undefined> {
  return useRequest([url!, options], {
    deps,
    condition,
  });
}

export function APIError({
  error,
  message,
  retry,
  actions = retry ? (
    <Button onClick={retry}>
      <Trans i18nKey="action.retry">Retry</Trans>
    </Button>
  ) : undefined,
  style,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  message: string;
  retry?: typeof noop;
  actions?: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return error ? (
    <Message error style={style}>
      <Message.Header>{message}</Message.Header>
      <p>{error instanceof StructuredError ? error.rawMessage : error.message}</p>
      {actions && <p>{actions}</p>}
      {error instanceof StructuredError && <ErrorDetails error={error} />}
    </Message>
  ) : null;
}
