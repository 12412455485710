import React from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from 'recharts';
import { numberToStr } from 'utils';
import { DEFAULT_COLORS } from '.';
import styles from './index.module.scss';
import { useTooltipLabel } from './use-tooltip-label';

export default function StatisticBarView({
  data,
  maxBarSize,
}: {
  data: [string, number][];
  maxBarSize?: number;
}) {
  const [, sum] = data.reduce(([, accumulator], [, value]) => ['', accumulator + value]);
  const tooltipLabel = useTooltipLabel({ sum });
  return (
    <ResponsiveContainer debounce={1} className={`${styles.chart} ${styles.barChart}`}>
      <BarChart
        data={data.map((item) => ({ name: item[0], value: item[1] }))}
        layout="vertical"
        maxBarSize={maxBarSize}
        margin={{ left: 20, right: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" tickFormatter={numberToStr} />
        <YAxis
          type="category"
          dataKey="name"
          tick={({ x, y, payload }) => (
            <foreignObject x={x - 70} y={y - 10} width={70} height={20}>
              <div className="tick">{payload.value}</div>
            </foreignObject>
          )}
        />
        <Tooltip content={tooltipLabel} />
        <Bar dataKey="value" fill={DEFAULT_COLORS[0]} isAnimationActive={false} />
      </BarChart>
    </ResponsiveContainer>
  );
}
