import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import classNames from 'classnames';
import { TDSStyle } from 'env';
import { PropsType } from 'utils';
import { SemanticICONS } from 'components/semantic/icon-name';
import NoData from 'components/NoData';
import { Loader } from 'components/semantic';
import styles from './index.module.scss';
import StatisticBarView from './StatisticBarView';
import StatisticPieView from './StatisticPieView';
import {
  TimeSerialDataAreaViewProps,
  TimeSerialDataLineViewProps,
  TimeSerialDataBarViewProps,
} from './time-serial';
import TimeSerialAreaView from './time-serial/AreaView';
import TimeSerialBarView, { TinyBarView as TimeSerialTinyBarView } from './time-serial/BarView';
import TimeSerialLineView, { TinyLineView as TimeSerialTinyLineView } from './time-serial/LineView';

export * from './time-serial';

export const COLORS = {
  noEmotion: TDSStyle ? '#4887F6' : '#5d9cec',
  danger: TDSStyle ? '#E2635E' : '#ed5565',
};
export const DEFAULT_COLORS = TDSStyle
  ? [
      COLORS.noEmotion,
      '#00D9C5',
      COLORS.danger,
      '#F1CD49',
      '#E98E39',
      '#6167F0',
      '#9667EF',
      '#58B1EA',
      '#71C77A',
      '#E47B5C',
      '#000000',
      '#4D8753',
    ]
  : [
      COLORS.noEmotion,
      '#ac92ec',
      '#a0d468',
      '#ffce54',
      '#fc6e51',
      '#ec87c0',
      '#48cfad',
      COLORS.danger,
    ];
export const COLOR_DISABLED = '#ddd';

const Container: React.FunctionComponent<{
  loading?: boolean;
  noData?: boolean;
  height: number;
  noDataIcon?: SemanticICONS;
  className?: string;
}> = ({ loading, noData, height, noDataIcon = 'chart line', className, children }) => {
  const { t } = useTranslation();
  const style = { height: `${height}px` };

  if (loading) {
    return (
      <div className={classNames(styles.container, className)} style={style}>
        <Loader active size="tiny" />
      </div>
    );
  }
  if (noData) {
    return (
      <div className={classNames(styles.container, styles.nodata, className)} style={style}>
        <NoData icon={noDataIcon} header={t('statisticView.noData')} />
      </div>
    );
  }
  return (
    <div className={classNames(styles.container, className)} style={style}>
      {children}
    </div>
  );
};

export function LineView({
  loading,
  data,
  height = 400,
  ...rest
}: TimeSerialDataLineViewProps & { loading?: boolean }) {
  return (
    <Container height={height} loading={loading} noData={!data || data.length < 1}>
      <TimeSerialLineView {...rest} data={data} height={height} />
    </Container>
  );
}
export function AreaView({
  loading,
  data,
  height = 400,
  ...rest
}: TimeSerialDataAreaViewProps & { loading?: boolean; stack?: boolean }) {
  return (
    <Container height={height} loading={loading} noData={!data || data.length < 1}>
      <TimeSerialAreaView {...rest} data={data} height={height} />
    </Container>
  );
}

export function TimeSerialBar({
  loading,
  data,
  height = 400,
  ...rest
}: TimeSerialDataBarViewProps & { loading?: boolean }) {
  return (
    <Container height={height} loading={loading} noData={!data || data.length < 1}>
      <TimeSerialBarView {...rest} data={data} height={height} />
    </Container>
  );
}

export function TimeSerialTinyBar({
  loading,
  data,
  height = 30,
  maxBarSize,
  cursor,
  ...rest
}: TimeSerialDataBarViewProps & { loading?: boolean; cursor?: 'pointer' }) {
  return (
    <Container
      height={height}
      loading={loading}
      className={classnames(styles.container, styles.tinyBarContainer, {
        [styles.pointerCursor]: cursor === 'pointer',
      })}
    >
      <TimeSerialTinyBarView {...rest} data={data} height={height} maxBarSize={maxBarSize} />
    </Container>
  );
}

export function TimeSerialTinyLine({
  loading,
  data,
  height = 30,
  cursor,
  className,
  ...rest
}: TimeSerialDataBarViewProps & { loading?: boolean; cursor?: 'pointer'; className?: string }) {
  return (
    <Container
      height={height}
      loading={loading}
      className={classnames(
        styles.container,
        styles.tinyBarContainer,
        {
          [styles.pointerCursor]: cursor === 'pointer',
        },
        className
      )}
    >
      <TimeSerialTinyLineView {...rest} data={data} height={height} />
    </Container>
  );
}

export function SortBarView({
  data,
  loading,
  height = 400,
  order,
  maxBarCount,
  ...rest
}: PropsType<typeof StatisticBarView> & {
  loading?: boolean;
  height?: number;
  order?: 'asc' | 'desc';
  maxBarCount?: number;
}) {
  const { t } = useTranslation();

  const sortedData = useMemo(() => {
    const copiedData = [...data];
    if (order) {
      return copiedData.sort(([, value1], [, value2]) => {
        if (order === 'asc') {
          return value1 - value2;
        } else {
          return value2 - value1;
        }
      });
    }
    return copiedData;
  }, [data, order]);

  const filtedData = useMemo(() => {
    if (maxBarCount && sortedData.length > maxBarCount) {
      const removed = sortedData.splice(maxBarCount);
      const [, sum] = removed.reduce(([, accumulator], [, value]) => ['', accumulator + value]);
      sortedData.push([t('label.other'), sum]);
    }
    return sortedData;
  }, [sortedData, maxBarCount, t]);

  return (
    <Container height={height} loading={loading} noData={!data || data.length < 1}>
      <StatisticBarView {...rest} data={filtedData} />
    </Container>
  );
}

export function SortPieView({
  data,
  loading,
  height = 400,
  order,
  ...rest
}: PropsType<typeof StatisticPieView> & {
  loading?: boolean;
  height?: number;
  order?: 'asc' | 'desc';
}) {
  const sortedData = useMemo(() => {
    if (order) {
      return [...data].sort(([, value1], [, value2]) => {
        if (order === 'asc') {
          return value1 - value2;
        } else {
          return value2 - value1;
        }
      });
    }
    return data;
  }, [data, order]);

  return (
    <Container height={height} loading={loading} noData={!data || data.length < 1}>
      <StatisticPieView {...rest} data={sortedData} />
    </Container>
  );
}
