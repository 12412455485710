import { useMemo, useState, useEffect, useCallback } from 'react';
import { serverUtcOffset } from 'config';
import useURLSearchParam, { useApplyChange } from 'utils/use-url-search-param';
import { RangeValue, Range } from './type';
import { getRangeContent, defaultRanges, getDateByExpression } from './utils';

const useDateTimeRange = (presetRanges = defaultRanges, utcOffset?: number) => {
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  useEffect(() => {
    const [initFrom, initTo] = getRangeContent(presetRanges[0]);
    setFrom(initFrom);
    setTo(initTo);
  }, [presetRanges, utcOffset]);

  const onChange = useCallback((range: RangeValue) => {
    setFrom(range.from);
    setTo(range.to);
  }, []);

  const options = useMemo(() => {
    return {
      ranges: presetRanges,
      onChange,
      value: {
        from,
        to,
      },
    };
  }, [presetRanges, onChange, from, to]);

  const fromDate = useMemo(() => getDateByExpression(from, 'from', utcOffset), [from, utcOffset]);
  const toDate = useMemo(() => getDateByExpression(to, 'to', utcOffset), [to, utcOffset]);
  return [fromDate, toDate, options] as const;
};

const DEFAULT_FROM_KEY = 'from';
const DEFAULT_TO_KEY = 'to';
const useSearchDateTimeRange = (presetRages = defaultRanges, utcOffset?: number) => {
  const applyChange = useApplyChange();
  const [initFrom, initTo] = useMemo(() => getRangeContent(presetRages[0]), [presetRages]);
  const [searchFrom, , changeFrom] = useURLSearchParam(DEFAULT_FROM_KEY);
  const [searchTo, , changeTo] = useURLSearchParam(DEFAULT_TO_KEY);

  const value = useMemo(() => {
    return {
      from: searchFrom || initFrom,
      to: searchTo || initTo,
    } as RangeValue;
  }, [initFrom, initTo, searchFrom, searchTo]);

  const options = useMemo(() => {
    return {
      ranges: presetRages,
      onChange: (range: RangeValue) => {
        applyChange(changeFrom(range.from), changeTo(range.to));
      },
      value,
    };
  }, [changeFrom, changeTo, applyChange, presetRages, value]);

  const fromDate = useMemo(
    () => getDateByExpression(value.from, 'from', utcOffset),
    [value.from, utcOffset]
  );
  const toDate = useMemo(
    () => getDateByExpression(value.to, 'to', utcOffset),
    [value.to, utcOffset]
  );
  return [fromDate, toDate, options] as const;
};

const useServerDateRange = (presetRages?: Range[]) =>
  useDateTimeRange(presetRages, serverUtcOffset);
const useSearchServerDateRange = (presetRages?: Range[]) =>
  useSearchDateTimeRange(presetRages, serverUtcOffset);

export { useServerDateRange, useSearchServerDateRange, useDateTimeRange, useSearchDateTimeRange };
