import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { formatTime, TimeFormat } from 'utils';
import RelativeTime from 'components/RelativeTime';
import { Button, Card, Dropdown, Icon, Label, Popup } from 'components/semantic';
import { useCurrentApp } from 'App/Application';
import { DeleteLeanDBConfirm } from './DeleteInstance';
import { ExportLeanDB } from './ExportInstance';
import styles from './index.module.scss';
import { ShareInfo, ShareManager } from './share';
import { ClusterInfo, CLUSTER_STATUS_COLOR } from './types';

const Operations = ({ children }: React.PropsWithChildren<{}>) => {
  const childArr = React.Children.toArray(children);
  if (childArr.length === 0) {
    return null;
  }
  return (
    <Dropdown
      button
      floating
      className="icon small"
      icon="ellipsis horizontal"
      pointing="top right"
    >
      <Dropdown.Menu size="small">
        {childArr.map((child, i) =>
          i ? (
            <React.Fragment key={i}>
              <Dropdown.Divider />
              {child}
            </React.Fragment>
          ) : (
            child
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const InstanceCard: React.FunctionComponent<{
  quota?: 'test' | 'standard';
  instance: ClusterInfo;
  onDeleted: (id: number) => void;
  extraInfo?: React.ReactNode;
  extraOperations?: React.ReactNode;
  exportEnabled?: boolean;
}> = ({
  instance,
  onDeleted,
  extraInfo,
  extraOperations,
  quota,
  exportEnabled = true,
  children,
}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { name, status, id, createdAt, versionTag, runtime } = instance;
  const [currentApp] = useCurrentApp();
  const shared = useMemo(
    () => currentApp && instance.appId !== currentApp.appId,
    [currentApp, instance]
  );
  return (
    <Card className={styles.instanceCard}>
      <Card.Content>
        <Card.Header className={styles.header}>
          {name}
          {quota && (
            <Label
              className={styles.quota}
              size="small"
              content={t(`db.quota.${quota}`)}
              color={quota === 'test' ? undefined : 'green'}
            />
          )}
          <span className={styles.space} />
          <Label className={styles.state} basic size="large" color={CLUSTER_STATUS_COLOR[status]}>
            {status === 'updating' ? t(`label.state.db.updating`) : t(`label.state.${status}`)}
          </Label>
        </Card.Header>
        {shared && (
          <Card.Description>
            <ShareInfo instance={instance} onDeleted={onDeleted} />
          </Card.Description>
        )}
      </Card.Content>
      <Card.Content className={styles.instanceDetails}>{children}</Card.Content>
      <Card.Content extra>
        {extraInfo}
        <p>
          <Icon name="info" color="green" />
          {t('label.createdAt')}:{' '}
          <Popup
            content={formatTime(createdAt, TimeFormat.DISPLAY_DATETIME_FULL)}
            trigger={
              <span>
                <RelativeTime value={createdAt} />
              </span>
            }
          />
        </p>
        <p>
          <Icon name="info" color="green" />
          Version: {versionTag}
        </p>
      </Card.Content>
      <Card.Content extra className={styles.operations}>
        <Button as={Link} size="small" to={`${match.url}/${id}`}>
          <Icon name="analytics" /> {t('label.stat')}
        </Button>
        {!shared && (
          <>
            <ShareManager
              cluster={instance}
              modalProps={{
                trigger: (
                  <Button size="small">
                    <Icon name="key" /> {t('db.share.manage')}
                  </Button>
                ),
              }}
            />
            {extraOperations}
            <Operations>
              {exportEnabled && (
                <ExportLeanDB
                  instance={instance}
                  modalProps={{
                    trigger: <Dropdown.Item text={t('action.export')} />,
                  }}
                />
              )}
              {currentApp?.isOwner && (
                <DeleteLeanDBConfirm
                  id={instance.id}
                  header={
                    <Trans
                      i18nKey="db.instance.delete"
                      values={{
                        type: t(`db.${runtime}`),
                        name: instance.name,
                      }}
                      components={{
                        code: <code />,
                      }}
                    />
                  }
                  onDeleted={onDeleted}
                  trigger={<Dropdown.Item text={t('action.delete')} />}
                />
              )}
            </Operations>
          </>
        )}
      </Card.Content>
    </Card>
  );
};
export default InstanceCard;
