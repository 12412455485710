import React, { useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Confirm from 'components/Confirm';
import Placeholder from 'components/Placeholder';
import { List } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { API_VERSION } from 'utils/request';
import { APIError } from 'utils/use-api';
import { useSharedByApps } from './share';

const DeleteConfirmContent = memo<{ id: number }>(({ id }) => {
  const { t } = useTranslation();
  const [attachedByApps, { error, loading, reload }] = useSharedByApps(id);
  return (
    <List bulleted>
      <List.Item>{t('db.instance.delete.noAccess')}</List.Item>
      <List.Item>
        {t('db.instance.delete.noSharedAccess')}
        {error && <APIError error={error} retry={reload} message={t('db.share.fetchFailed')} />}
        {loading && <Placeholder />}
        {attachedByApps &&
          (attachedByApps.length ? (
            <List>
              {attachedByApps?.map((app) => (
                <List.Item key={app.appId}>{app.appName}</List.Item>
              ))}
            </List>
          ) : (
            t('db.share.empty')
          ))}
      </List.Item>
      <List.Item>{t('label.actionIrreversible')}</List.Item>
    </List>
  );
});

export const DeleteLeanDBConfirm = memo<{
  id: number;
  onDeleted: (id: number) => void;
  header: React.ReactNode;
  trigger: React.ReactNode;
}>(({ id, onDeleted, header, trigger }) => {
  const { t } = useTranslation();
  const deleteInstance = useCallback(async () => {
    try {
      await request(`/${API_VERSION}/leandb/clusters/${id}`, {
        method: 'DELETE',
      });
      toast.success(t('action.delete.successfully'));
      onDeleted(id);
    } catch (err) {
      toast.error(t('action.delete.failed'), err);
    }
  }, [id, t, onDeleted]);
  return (
    <Confirm
      header={header}
      content={<DeleteConfirmContent id={id} />}
      confirmButtonText={t('action.delete')}
      trigger={trigger}
      onConfirm={deleteInstance}
    />
  );
});
