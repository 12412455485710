import React from 'react';
import { Cell, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer, Tooltip } from 'recharts';
import { DEFAULT_COLORS } from '.';
import styles from './index.module.scss';
import { useTooltipLabel } from './use-tooltip-label';

const RADIAN = Math.PI / 180;

function Label({
  cx = 0,
  cy = 0,
  midAngle = 0,
  outerRadius = 0,
  name,
  fill,
  percent,
}: PieLabelRenderProps) {
  if (!percent || Number(percent) < 0.01) {
    return null;
  }

  const radius = Number(outerRadius) + 25;
  const angle = Number(midAngle);
  const isLeftSide = angle >= 90 && angle < 270;
  let x = Number(cx) + radius * Math.cos(angle * -RADIAN);
  let y = Number(cy) + radius * Math.sin(angle * -RADIAN);

  return (
    <>
      <text
        x={x}
        y={y}
        textAnchor={isLeftSide ? 'end' : 'start'}
        dominantBaseline="central"
        style={{ fontSize: 12 }}
        fill="#666"
      >
        {name}
      </text>
      <path
        stroke={fill}
        d={`M ${x} ${y} L ${Number(cx) + Number(outerRadius) * Math.cos(angle * -RADIAN)} ${
          Number(cy) + Number(outerRadius) * Math.sin(angle * -RADIAN)
        }`}
      />
    </>
  );
}

export default function StatisticPieView({
  data,
  innerRadius,
  tooltipFormat,
}: {
  data: [string, number][];
  innerRadius?: number | string;
  tooltipFormat?: (value: number) => string;
}) {
  const [, sum] = data.reduce(([, accumulator], [, value]) => ['', accumulator + value]);
  const tooltipLabel = useTooltipLabel({ sum, format: tooltipFormat });
  return (
    <ResponsiveContainer debounce={1} className={`${styles.chart} ${styles.pieChart}`}>
      <PieChart>
        <Pie
          dataKey="value"
          cx="50%"
          cy="50%"
          isAnimationActive={false}
          labelLine={false}
          label={Label}
          data={data.map((item) => ({ name: item[0], value: item[1] }))}
          innerRadius={innerRadius}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={DEFAULT_COLORS[index % DEFAULT_COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={tooltipLabel} />
      </PieChart>
    </ResponsiveContainer>
  );
}
