import React, { useEffect, useState } from 'react';
import { RechartsFunction, ReferenceArea } from 'recharts';
import { Button } from 'components/semantic';
import styles from '../index.module.scss';
import { X_DATA_KEY } from '.';

interface ChartData {
  [X_DATA_KEY]: number;
}

export default (data: ChartData[]) => {
  const [selectedData, setSelectedData] = useState(data);
  const [refArea, setRefArea] = useState<{
    left?: number;
    right?: number;
  }>({});
  const [slice, setSlice] = useState<[number, number | undefined]>([0, undefined]);
  const onMouseDown: RechartsFunction = (e) => {
    if (e && e.activeTooltipIndex !== undefined) {
      setRefArea({
        ...refArea,
        left: e.activeTooltipIndex,
      });
    }
  };
  const onMouseMove: RechartsFunction = (e) => {
    if (e && e.activeTooltipIndex !== undefined && refArea.left !== undefined) {
      setRefArea({
        ...refArea,
        right: e.activeTooltipIndex,
      });
    }
  };
  const onMouseUp = () => {
    if (
      refArea.left !== undefined &&
      refArea.right !== undefined &&
      refArea.left !== refArea.right
    ) {
      const [preStart] = slice;
      const [start, end] =
        refArea.left > refArea.right
          ? [refArea.right, refArea.left]
          : [refArea.left, refArea.right];
      setSlice([preStart + start, end - start + 1]);
    }
    setRefArea({});
  };
  const zoomOut = () => {
    setSlice([0, undefined]);
    setRefArea({});
  };
  useEffect(() => {
    const [start, length = data.length] = slice;
    setSelectedData(data.slice(start, start + length));
  }, [data, slice]);
  const referenceArea =
    refArea.left !== undefined && refArea.right !== undefined ? (
      <ReferenceArea
        yAxisId="1"
        x1={data[refArea.left][X_DATA_KEY]}
        x2={data[refArea.right][X_DATA_KEY]}
      />
    ) : null;
  const isZoom = data.length !== selectedData.length;
  const resetButton = isZoom ? (
    <Button basic size="tiny" content="Reset zoom" className={styles.zoomOut} onClick={zoomOut} />
  ) : null;
  return {
    selectedData,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    referenceArea,
    resetButton,
  };
};
