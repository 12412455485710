import React, { useState, useMemo, memo, useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { currency } from 'config';
import { RadioGroup } from 'components/Radio';
import { Modal, Form, LinkButton, Button, withModal, Input, Message } from 'components/semantic';
import { Item, dividedClassName } from 'components/Summary';
import { toast } from 'components/Toast';
import request, { API_VERSION } from 'utils/request';
import { useAppId } from 'App/Application';
import { usePricing } from 'App/Pricing';
import { useRuntime } from '..';
import { MySQLClusterInfo } from '.';
import { isAPSG } from 'env';
import styles from './index.module.scss';
import { isAliyunMysql, MySqlMemoryBase } from 'config/feature-flags';

const FORM_ID = 'RDB_MYSQL';
const RadioItem = memo<{ value: number; unit: string; item: string }>(({ value, unit, item }) => {
  const { t } = useTranslation();
  const [SKUs, { loading }] = usePricing();
  const matchedSKU = useMemo(() => {
    //udb-storage-20 由前端提供价格
    if (item === 'udb-storage-20') {
      return {
        price: 0,
      };
    }
    return SKUs?.find((SKU) => SKU.service === 'LeanDB' && SKU.item === item);
  }, [SKUs, item]);
  return (
    <div className={styles.radioItem}>
      <div>
        <span className="spec-value">{value}</span> {unit}
      </div>
      <div>
        <span className="spec-value">{loading ? '?' : matchedSKU?.price ?? 'N/A'}</span>{' '}
        {`${currency} / ${t('label.day')}`}
      </div>
    </div>
  );
});

export default withModal<{
  instanceData?: MySQLClusterInfo;
  onCreated?: (params: MySQLClusterInfo) => void;
  onUpdated?: (id: number, params: Partial<MySQLClusterInfo>) => void;
}>()(({ close, onCreated, onUpdated, instanceData }) => {
  const { t } = useTranslation();
  const appId = useAppId();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [quota, setQuota] = useState<string>('');
  const [storage, setStorage] = useState<string>('');
  const [runtimeInfo] = useRuntime('mysql');
  const [SKUs] = usePricing();
  const [versionTag, setVersionTag] = useState<string>('');

  useEffect(() => {
    if (instanceData) {
      setName(instanceData.name);
      setQuota(instanceData.nodeQuota);
      setStorage(instanceData.storageQuota);
    }
  }, [instanceData]);

  const getMatchedSKU = useCallback(
    (item: string) => {
      return SKUs?.find((SKU) => SKU.service === 'LeanDB' && SKU.item === item);
    },
    [SKUs]
  );

  const { nodeQuotas, storageQuotas, availableQuotas, availableVersions } = useMemo(() => {
    return {
      nodeQuotas: runtimeInfo?.nodeQuotas || [],
      storageQuotas: runtimeInfo?.storageQuotas || [],
      availableQuotas: runtimeInfo?.availableQuotas || [],
      availableVersions: runtimeInfo?.availableVersions || [],
    };
  }, [runtimeInfo]);

  const totalPrice = useMemo(() => {
    if (quota === undefined || storage === undefined) return;
    const quotaPrice = getMatchedSKU(quota)?.price;
    if (quotaPrice === undefined) return;
    const storagaPrice = getMatchedSKU(storage)?.price ?? 0;
    return quotaPrice + storagaPrice;
  }, [getMatchedSKU, quota, storage]);

  const storageAllowedItems = useMemo(() => {
    if (!runtimeInfo || !quota) {
      return [];
    }
    const { nodeQuotaMap } = runtimeInfo;
    return nodeQuotaMap[quota].availableStorageQuotas;
  }, [runtimeInfo, quota]);

  const isEditing = !!instanceData;

  const create = async () => {
    try {
      setLoading(true);
      const mysql = await request<MySQLClusterInfo>(
        `/${API_VERSION}/leandb/clusters?appId=${appId}`,
        {
          method: 'POST',
          body: {
            name,
            nodeQuota: quota,
            storageQuota: storage,
            runtime: 'mysql',
            versionTag: isAliyunMysql ? versionTag : undefined,
          },
        }
      );
      onCreated && onCreated(mysql);
      setLoading(false);
      toast.success(t('action.create.successfully'));
      close();
    } catch (error) {
      toast.error(t('action.create.failed'), error);
      setLoading(false);
    }
  };

  const update = async () => {
    if (!instanceData) {
      return;
    }

    try {
      setLoading(true);
      const mysql = await request<MySQLClusterInfo>(
        `/${API_VERSION}/leandb/clusters/${instanceData.id}/quota`,
        {
          method: 'PUT',
          body: {
            nodeQuota: quota,
            storageQuota: storage,
          },
        }
      );

      onUpdated && onUpdated(instanceData.id, mysql);

      setLoading(false);
      toast.success(t('action.update.successfully'));
      close();
    } catch (error) {
      toast.error(t('action.update.failed'), error);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal.Header
        content={
          isEditing ? (
            <Trans
              values={{
                type: 'MySQL',
              }}
              i18nKey={'db.redis.resizeCapacity'}
            />
          ) : (
            <Trans
              values={{
                type: t('db.udb'),
              }}
              i18nKey="db.instance.createWithType"
            />
          )
        }
      />
      <Modal.Content scrolling>
        <Form id={FORM_ID} onSubmit={isEditing ? update : create}>
          {!isEditing && (
            <Form.Field required>
              <label htmlFor="name">{t('label.name')}</label>
              <Input
                autoFocus
                required
                name="name"
                value={name}
                id="name"
                pattern="[a-zA-Z0-9]{3,30}$"
                title={t('db.udb.nameHint')}
                onChange={(e, data) => setName(data.value)}
              />
              <p className="help-block">{t('db.udb.nameHint')}</p>
            </Form.Field>
          )}
          {isEditing && (
            <Form.Field>
              <label>{t('label.name')}</label>
              <code className={styles.instanceName}>{instanceData?.name}</code>
            </Form.Field>
          )}
          {isAliyunMysql && !isEditing && (
            <Form.Field required>
              <label htmlFor="version">{t('engine.deploy.version')}</label>
              <RadioGroup
                required
                name="version"
                value={versionTag}
                onChange={(e, data) => setVersionTag(data.value)}
                radios={availableVersions.map((v) => {
                  return {
                    value: v,
                  };
                })}
              />
            </Form.Field>
          )}
          <Form.Field required>
            <label htmlFor="quota">{t('db.quota.memory')}</label>
            <RadioGroup
              required
              name="quota"
              value={quota}
              radios={availableQuotas.map((quota) => {
                const quotaItem = nodeQuotas.find((item) => item.id === quota);
                return {
                  value: quotaItem?.id || 'N/A',
                  label: (
                    <RadioItem
                      value={(quotaItem?.memory || 0) / MySqlMemoryBase}
                      unit={'GB'}
                      item={quotaItem?.id || 'N/A'}
                    />
                  ),
                };
              })}
              onChange={(e, data) => setQuota(data.value)}
            />
          </Form.Field>

          <Form.Field required>
            <label htmlFor="storage">{t('db.storage')}</label>
            <RadioGroup
              required
              name="storage"
              value={storage}
              radios={storageQuotas.map((quotaItem) => ({
                value: quotaItem.id,
                disabled: !storageAllowedItems.includes(quotaItem.id),
                label: <RadioItem value={quotaItem.storage} unit={'GB'} item={quotaItem.id} />,
              }))}
              onChange={(e, data) => setStorage(data.value)}
            />
          </Form.Field>
        </Form>
        {totalPrice !== undefined && (
          <div className={classNames(dividedClassName, 'fill-space')}>
            <span className="space" />
            <Item
              name={t('label.totalCost')}
              value={totalPrice}
              suffix={`${currency} / ${t('label.day')}`}
            />
          </div>
        )}
        {isEditing && (
          <Message warning>
            <Message.Content>
              <Message.List>
                <Message.Item content={t(`db.scaleHint.caution`)} />
                <Message.Item
                  content={t(isAPSG ? `db.scaleHint.mysql.apsg` : `db.scaleHint.mysql`)}
                />
                <Message.Item content={t('db.instance.quotaHint')} />
              </Message.List>
            </Message.Content>
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <LinkButton onClick={close} content={t('action.cancel')} />
        <Button
          primary
          type="submit"
          form={FORM_ID}
          content={t(`action.confirmBilling`)}
          loading={loading}
          disabled={loading}
        />
      </Modal.Actions>
    </>
  );
});
