import React, { useMemo, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IDVerifyEnabled } from 'config/feature-flags';
import { isUS } from 'env';
import { useUser, VerifyState } from './User';
import { CrossStorageClient } from 'cross-storage';
import { getStorage, setStorage } from 'utils';

export const useIDVerify = () => {
  const [user] = useUser();
  return useMemo(() => {
    if (!IDVerifyEnabled) {
      return true;
    }
    if (!user) {
      return true;
    }
    return user.clientVerification === VerifyState.VERIFIED;
  }, [user]);
};

export const withIDVerify =
  <P extends RouteComponentProps>(WrappedComponent: React.ComponentType<P>) =>
  (props: P) => {
    const history = useHistory();
    const verified = useIDVerify();
    useEffect(() => {
      if (!verified) {
        history.push('/account/verify');
      }
    }, [verified, history]);
    return <WrappedComponent {...props} />;
  };

interface SourceData {
  value?: string | null;
  date: number;
}

const REFERRER_KEY = 'referrer';
const LANDING_URL_KEY = 'landingUrl';

export const setSignUpSource = () => {
  // https://github.com/leancloud/uluru-platform/issues/3796
  if (document.referrer && typeof URL === 'function') {
    const { hostname } = new URL(document.referrer);
    if (!hostname) return;
    if (hostname.match(/leancloud.cn$/)) return;
    if (localStorage.getItem('referrer')) return;
    setStorage(
      JSON.stringify({
        value: document.referrer,
        date: Date.now(),
      }),
      REFERRER_KEY
    );
    setStorage(
      JSON.stringify({
        value: document.location.href,
        date: Date.now(),
      }),
      LANDING_URL_KEY
    );
  }
};

const min = (a: SourceData, b: SourceData) => (a.date > b.date ? b : a);
const getNullItem = (date = Date.now()): SourceData => ({
  value: null,
  date,
});

export const getSignUpSource = async () => {
  const homepageHost = isUS ? 'https://leancloud.app' : 'https://www.leancloud.cn';
  const homepageStorage = new CrossStorageClient(homepageHost + '/storage-service.html', {});
  const [homepageReferrer = getNullItem(), homepageLandingUrl = getNullItem()] =
    await homepageStorage
      .onConnect()
      .then(() => homepageStorage.get('referrer', 'landingUrl'))
      .then((values) => {
        return values.map((value) => (value ? JSON.parse(value) : undefined));
      })
      .catch((e) => {
        console.warn('get analytics values error: ' + e.message);
        return [];
      });

  try {
    const localReferrer = JSON.parse(
      getStorage(REFERRER_KEY) || `{"value":null,"date":${Date.now()}}`
    );
    const localLandingUrl = JSON.parse(
      getStorage(LANDING_URL_KEY) || `{"value":null,"date":${Date.now()}}`
    );

    return {
      [REFERRER_KEY]: min(homepageReferrer, localReferrer).value,
      [LANDING_URL_KEY]: min(homepageLandingUrl, localLandingUrl).value,
    };
  } catch (error) {
    return {
      [REFERRER_KEY]: null,
      [LANDING_URL_KEY]: null,
    };
  }
};
