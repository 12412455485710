import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Form, LinkButton, Button, withModal, Input } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { API_VERSION } from 'utils/request';
import { useAppId } from 'App/Application';
import { InstanceHint } from '..';
import { useQuota } from '../quota';
import { MongoDBClusterInfo } from '.';

interface Props {
  onCreated?: () => void;
  onUpdated?: () => void;
}

const FORM_ID = 'Mongo';
export default withModal<Props>()(({ close, onCreated }) => {
  const { t } = useTranslation();
  const appId = useAppId();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [quota, { standardQuotaRadioGroup, testQuotaRadioGroup }] = useQuota('mongo');
  const onSubmit = async () => {
    try {
      if (quota === undefined) {
        throw new Error('nodeQuota is required');
      }
      setLoading(true);
      await request<MongoDBClusterInfo>(`/${API_VERSION}/leandb/clusters?appId=${appId}`, {
        method: 'POST',
        body: {
          name,
          nodeQuota: quota,
          storageQuota: quota,
          runtime: 'mongo',
        },
      });
      onCreated && onCreated();
      setLoading(false);
      toast.success(t('action.create.successfully'));
      close();
    } catch (error) {
      toast.error(t('action.create.failed'), error);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal.Header
        content={
          <Trans
            values={{
              type: t('db.mongo'),
            }}
            i18nKey="db.instance.createWithType"
          />
        }
      />
      <Modal.Content scrolling>
        <Form id={FORM_ID} onSubmit={onSubmit}>
          <Form.Field required>
            <label htmlFor="instance">{t('label.name')}</label>
            <Input
              name="instance"
              id="instance"
              required
              autoFocus
              pattern="[a-zA-Z][a-zA-Z0-9_]{0,31}$"
              onChange={(e, { value }) => setName(value)}
            />
            <p className="help-block">{t('db.instance.nameHint')}</p>
          </Form.Field>

          <Form.Field>
            <label htmlFor="max_test_memory">{t('db.quota.test')}</label>
            {testQuotaRadioGroup}
          </Form.Field>

          <Form.Field>
            <label htmlFor="max_standard_memory">{t('db.quota.standard')}</label>
            {standardQuotaRadioGroup}
          </Form.Field>
        </Form>
        <InstanceHint />
      </Modal.Content>
      <Modal.Actions>
        <LinkButton onClick={close} content={t('action.cancel')} />
        <Button
          primary
          type="submit"
          form={FORM_ID}
          content={t(`action.confirmBilling`)}
          loading={loading}
        />
      </Modal.Actions>
    </>
  );
});
