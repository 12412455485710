import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useLocalStorage } from 'utils';
import { Sidebar } from 'components/semantic';
import { useIsTablet } from 'utils/media';
import styles from './index.module.scss';

export default (foldEnabled?: boolean) => {
  const isTablet = useIsTablet();
  const foldable = isTablet;
  const [presistedFolded, setFolded] = useLocalStorage('PREF:foldMene');
  const folded = !!foldEnabled && Boolean(presistedFolded);
  const toggleFolded = useCallback(() => {
    if (foldEnabled) {
      setFolded(folded ? undefined : '1');
    }
  }, [foldEnabled, folded, setFolded]);
  const alwaysVisible = isTablet && !folded;
  const [visible, setVisible] = useState(alwaysVisible);

  let location = useLocation();
  useEffect(() => {
    if (!alwaysVisible) {
      setVisible(false);
    }
  }, [alwaysVisible, location.pathname]);

  const withSidebar = (content: ReactNode) => (children: ReactNode) => {
    return (
      <Sidebar.Pushable>
        <Sidebar
          visible={visible || alwaysVisible}
          onHide={alwaysVisible ? undefined : () => setVisible(false)}
          animation="overlay"
          className={styles.sidebar}
        >
          {content}
        </Sidebar>
        <Sidebar.Pusher
          className={classNames(styles.main, { [styles.alwaysVisible]: alwaysVisible })}
          dimmed={visible && !alwaysVisible}
        >
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  };

  return [
    withSidebar,
    {
      visible,
      setVisible,
      alwaysVisible,
      foldable,
      folded,
      toggleFolded,
    },
  ] as const;
};
