import { API_VERSION } from 'utils/request';
import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Hint from 'components/Hint';
import Page, { PageContainer } from 'components/Page';
import { Breadcrumb, Button, Form, Checkbox } from 'components/semantic';
import { LineView, bytesFormatters, AreaView } from 'components/StatisticView';
import { withTracker } from 'utils/tracker';
import { useTransform, useAPI } from 'utils/use-api';
import { useDisplayMode, DISPLAY_MODE } from '../../Stats/parts';
import { useDateTimeRange, useDateTimeRangeAndSelect } from '../../use-date-range';
import { MetricData } from '../types';
import { StatContainer, transformMetricData } from '../utils';
import { useInstanceId, useElasticsearch } from './';
import ChartContainer from '../ChartContainer';
import styles from './index.module.scss';

const useMetricsForSelectedRange = (type: string, nodeName?: '*') => {
  const id = useInstanceId();
  const [from, to] = useDateTimeRange();
  return useAPI<MetricData[]>(
    `/${API_VERSION}/leandb/metrics/${id}/${type}`,
    {
      query: {
        from,
        to,
        node_name: nodeName,
      },
    },
    [from, to, nodeName],
    !!from && !!to
  );
};

const commonChartProps = {
  dot: false,
  height: 280,
  syncId: 'es',
};

const useShards = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('es/shards'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.es.stat.shards')}>
      <LineView
        data={chartData}
        names={{
          active_primary_shards: 'Primary shards',
          active_shards: 'Shards',
        }}
        loading={loading}
        allowDecimals={false}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useTasks = () => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('es/tasks'),
    transformMetricData
  );
  const node = (
    <ChartContainer title={t('db.es.stat.tasks')}>
      <LineView
        data={chartData}
        names={(key) => t(`db.es.stat.${key}`)}
        loading={loading}
        allowDecimals={false}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useMetricsForDetail = (type: string, detail?: boolean) =>
  useTransform(
    useMetricsForSelectedRange(type, detail ? '*' : undefined),
    useCallback(
      (data) => transformMetricData(data, undefined, detail ? 'node_name' : undefined),
      [detail]
    )
  );

const useCpu = (detail?: boolean) => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useMetricsForDetail('es/cpu', detail);
  const node = (
    <ChartContainer title={t('db.es.stat.cpu')}>
      <AreaView data={chartData} unit={'%'} stack={false} loading={loading} {...commonChartProps} />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useMemory = (detail?: boolean) => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useMetricsForDetail('es/memory', detail);
  const node = (
    <ChartContainer title={t('db.es.stat.memory')}>
      <AreaView
        data={chartData}
        formatters={bytesFormatters}
        loading={loading}
        stack={false}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useStorage = (detail?: boolean) => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useMetricsForDetail('es/storage', detail);
  const node = (
    <ChartContainer title={t('db.es.stat.storage')}>
      <AreaView
        data={chartData}
        formatters={bytesFormatters}
        loading={loading}
        stack={false}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useNet = (detail?: boolean) => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useTransform(
    useMetricsForSelectedRange('es/net', detail ? '*' : undefined),
    useCallback(
      (data) => {
        if (data) {
          data = data.map((item, index) => {
            if (detail) {
              item.tags.metric = item.tags.metric + '&' + item.tags['node_name'];
            }
            return item;
          });
        }
        return transformMetricData(data);
      },
      [detail]
    )
  );
  const node = (
    <ChartContainer
      title={
        <>
          {t('db.es.stat.net')} <Hint content={t('db.es.stat.netHint')} />
        </>
      }
    >
      <AreaView
        data={chartData}
        formatters={bytesFormatters}
        loading={loading}
        names={(key) => {
          if (detail) {
            const keys = key.split('&');
            const type = t(`db.es.stat.${keys[0]}`);
            return `${keys[1]} (${type})`;
          } else {
            return t(`db.es.stat.${key}`);
          }
        }}
        omitDataKey={false}
        stack={false}
        {...commonChartProps}
      />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useHttp = (detail?: boolean) => {
  const { t } = useTranslation();
  const [chartData, { loading, reload }] = useMetricsForDetail('es/http', detail);
  const node = (
    <ChartContainer title={t('db.es.stat.http')}>
      <LineView allowDecimals={false} data={chartData} loading={loading} {...commonChartProps} />
    </ChartContainer>
  );
  return [node, reload] as const;
};

const useResouceMetrics = (displayMode: DISPLAY_MODE) => {
  const { t } = useTranslation();
  const [detailed, setDetailed] = useState(false);
  const [cpuNode, cpuReload] = useCpu(detailed);
  const [memoryNode, memoryReload] = useMemory(detailed);
  const [storageNode, storageReload] = useStorage(detailed);
  const [netNode, netReload] = useNet(detailed);
  const [httpNode, httpReload] = useHttp(detailed);

  const node = (
    <div className={styles.detailedMetricsArea}>
      <div className={styles.detailedMetricsToolbar}>
        <Form>
          <Checkbox
            label={t('db.es.stat.nodeDetails')}
            checked={detailed}
            onChange={(e, { checked }) => setDetailed(!!checked)}
          />
        </Form>
      </div>
      <StatContainer displayMode={displayMode}>
        {cpuNode}
        {memoryNode}
        {storageNode}
        {netNode}
        {httpNode}
      </StatContainer>
    </div>
  );

  const reload = useCallback(() => {
    cpuReload();
    memoryReload();
    storageReload();
    netReload();
    httpReload();
  }, [cpuReload, memoryReload, storageReload, netReload, httpReload]);

  return [node, reload] as const;
};

export default withTracker(() => {
  const { t } = useTranslation();
  const id = useInstanceId();
  const [appInstances] = useElasticsearch();
  const [displayMode, displayModeSwitch, containerClassName] = useDisplayMode();
  const [shardsNode, shardsReload] = useShards();
  const [tasksNode, tasksReload] = useTasks();
  const [resourceMetricsNode, reloadResourceMetrics] = useResouceMetrics(displayMode);
  const [, , selectNode] = useDateTimeRangeAndSelect();

  const instanceContent = useMemo(() => {
    const currentInstance = appInstances.find((instance) => instance.id === Number(id));
    return currentInstance ? currentInstance.name : id;
  }, [appInstances, id]);

  const reload = useCallback(() => {
    shardsReload();
    tasksReload();
    reloadResourceMetrics();
  }, [tasksReload, shardsReload, reloadResourceMetrics]);

  return (
    <Page title={[t('label.stat'), t('db.es'), t('engine')]}>
      <PageContainer>
        <Breadcrumb
          className={styles.breadcrumb}
          sections={[
            { key: 'Elasticsearch', content: <Link to="../es">Elasticsearch</Link> },
            { key: 'instance', content: instanceContent, active: true },
          ]}
        />
        <div className={classnames('fill-space', styles.statToolbar)}>
          <Button onClick={reload} icon="refresh" />
          {displayModeSwitch}
          <span className="space" />
          {selectNode}
        </div>
        <div className={containerClassName}>
          <div className={styles.detailedMetricsArea}>
            <StatContainer displayMode={displayMode}>
              {shardsNode}
              {tasksNode}
            </StatContainer>
          </div>
          {resourceMetricsNode}
        </div>
      </PageContainer>
    </Page>
  );
});
