import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from 'components/semantic';
import styles from './index.module.scss';

export const Header = ({ title, document }: { title: React.ReactNode; document?: string }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.header, 'fill-space')}>
      <h3 className={styles.title}>{title}</h3>
      <span className="space" />
      {document && (
        <Button as="a" href={document} target="_blank" rel="noopener noreferrer">
          {t('label.integrationDocument')}
        </Button>
      )}
    </div>
  );
};
