import request, { API_VERSION } from 'utils/request';
import { useAPI } from 'utils/use-api';
import { useAppId } from '..';
import { EngineGroup } from './types';

export function useEngineAPI<T>(...[url, options, deps = [url], init]: Parameters<typeof useAPI>) {
  const appId = useAppId();
  const newOptions = {
    appId,
    ...options,
  };
  const newDeps = [appId, ...deps];
  return useAPI<T>(url, newOptions, newDeps, init);
}

export const updateGroup = (appId: string, groupName: string, data: Partial<EngineGroup>) => {
  return request(`/${API_VERSION}/engine/groups/${groupName}`, {
    method: 'PATCH',
    body: data,
    appId,
  });
};
