import { isCN, Region, region, TDS } from 'env';
export const getGlobalConfig = <T>(
  configs: {
    [key in 'cn' | 'intl']: T;
  }
) => configs[isCN ? 'cn' : 'intl'];

export const currency = getGlobalConfig({
  cn: 'CNY',
  intl: 'USD',
});
export const currencyIconName = getGlobalConfig({
  cn: 'rmb',
  intl: 'dollar sign',
});

export function _getRegionalConfig<T>(
  configs: {
    [key in Region]?: T;
  },
  defaultValue?: T
): T {
  const value = configs[region];
  if (defaultValue === undefined) {
    return value as T;
  }
  return value ?? defaultValue;
}

export function getRegionalConfig<T>(
  configs: {
    [key in Region]: T;
  }
): T;
export function getRegionalConfig<T>(
  configs: {
    [key in Region]?: T;
  },
  defaultValue: T
): T;
export function getRegionalConfig<T>(
  configs: {
    [key in Region]?: T;
  },
  defaultValue?: T
): T {
  return _getRegionalConfig(configs, defaultValue);
}

export const consoleAPIServer = process.env.REACT_APP_USE_PROXIED_API
  ? window.location.origin
  : process.env.REACT_APP_API_SERVER;

// 之前使用的共享域名（如 [e1-]api.leancloud.cn）将会退役，且不再会有替代
// 按照设计 console API 将包含所有用户 API 的内容
export const platformAPIServer = consoleAPIServer;

export const sharedAPIDomain = getRegionalConfig({
  'cn-n1': 'lc-cn-n1-shared.com',
  'cn-rnd': 'uc-test1.lc-cn-n1-shared.com',
  'cn-e1': 'lc-cn-e1-shared.com',
  'cn-tds1': 'cloud.tds1.tapapis.cn',
  'ap-sg': 'cloud.ap-sg.tapapis.com',
  'us-w1': 'api.lncldglobal.com',
});

export const sharedBillBoardDomain = getRegionalConfig({
  'cn-n1': undefined,
  'cn-rnd': undefined,
  'cn-e1': undefined,
  'cn-tds1': undefined,
  'ap-sg': 'billboard.ap-sg.tapapis.com',
  'us-w1': undefined,
});

export const engineDomain = getRegionalConfig({
  'cn-n1': 'leanapp.cn',
  'cn-rnd': 'uc-test1.leanapp.cn',
  'cn-e1': 'cn-e1.leanapp.cn',
  'cn-tds1': 'tds1.tdsapps.cn',
  'ap-sg': 'ap-sg.tdsapps.com',
  'us-w1': 'avosapps.us',
});

export const sharedFileDomain = getRegionalConfig({
  'cn-n1': 'cn-n1.lcfile.com',
  'cn-rnd': 'cn-n1.lcfile.com',
  'cn-e1': 'cn-e1.lcfile.com',
  'cn-tds1': 'tds1.tapfiles.cn',
  'ap-sg': 'ap-sg.tdsfiles.com',
  'us-w1': undefined,
});

export const sharedSocketDomain = getRegionalConfig(
  {
    'cn-n1': 'im.cn-n1.lncldapi.com',
    'cn-e1': 'im.cn-e1.lncldapi.com',
    'us-w1': 'im.us-w1.lncldglobal.com',
    'cn-rnd': 'im.wc-test1.lncldapi.com',
  },
  undefined
);

export const sharedMultipleDomain = getRegionalConfig(
  {
    'us-w1': 'api.lncldglobal.com',
    'cn-tds1': 'cloud.tds1.tapapis.cn',
    'ap-sg': 'im.ap-sg.tapapis.com',
  },
  undefined
);

export const serverUtcOffset = isCN ? 480 : 0;
export const serverTimeZone = `UTC${serverUtcOffset < 0 ? '' : '+'}${serverUtcOffset / 60}`;

export const CLIName = TDS ? 'tds' : 'lean';
export const CLIRegion = region;

export const leanDBAdminerURL = getRegionalConfig(
  {
    'cn-n1': 'https://adminer.leanapp.cn/',
    'cn-tds1': 'https://adminer.tds1.tdsapps.cn/',
    'ap-sg': 'https://adminer.ap-sg.tdsapps.com/',
  },
  undefined
);

export const RTMHistoryStoringPricing = getGlobalConfig({
  cn: '1500',
  intl: '500',
});

export const IPPricing = getGlobalConfig({
  cn: 50,
  intl: NaN,
});

export const SMSBalanceMin = isCN ? 200 : 0;

export const gravatarPrefix = 'https://gravatar.tapglb.com/avatar';

export const bizAppMinCharge = getGlobalConfig({
  cn: 30,
  intl: 5,
});

export const switchBizMinBalance = getGlobalConfig({
  cn: 900,
  intl: 150,
});

export const appTheme = getRegionalConfig(
  {
    'cn-tds1': {
      primary: '#00CDBA',
    },
  },
  {
    primary: '#2c97e8',
  }
);
