import React, { memo } from 'react';
import md5 from 'blueimp-md5';
import classnames from 'classnames';
import { gravatarPrefix } from 'config';
import { Label, SemanticSIZES, LabelProps } from 'components/semantic';
import styles from './index.module.scss';

export const getAvatarURL = (email: string, size: number, isEmailMD5 = false) => {
  const hash = isEmailMD5 ? email : md5(email);
  const url = `${gravatarPrefix}/${hash}?s=${size}&d=retro`;
  return url;
};

export const Avatar = memo(
  ({
    email,
    size = 52,
    className,
    isEmailMD5 = false,
  }: {
    email: string;
    size?: number;
    className?: string;
    isEmailMD5?: boolean;
  }) => {
    return (
      <img
        src={getAvatarURL(email, size, isEmailMD5)}
        alt={email}
        width={size / 2}
        height={size / 2}
        className={classnames(styles.avatar, className)}
      />
    );
  }
);

const avatarSize: {
  [key in SemanticSIZES]: number;
} = {
  mini: 52,
  tiny: 52,
  small: 52,
  medium: 60,
  large: 60,
  big: 78,
  huge: 86,
  massive: 104,
};

export const AvatarLabel = memo(
  ({
    email,
    size = 'small',
    className,
    ...labelProps
  }: {
    email: string;
  } & LabelProps) => (
    <Label image size={size} className={classnames(className, styles.label)} {...labelProps}>
      <Avatar email={email} size={avatarSize[size]} />
      {email}
    </Label>
  )
);
