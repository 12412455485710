import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useParams, Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import { TDS } from 'env';
import { Compose } from 'components/Compose';
import {
  User,
  RegionSelect,
  AppSelect,
  LocaleSelect,
  Help,
  Header,
  MenuItem,
} from 'components/Header';
import { HomeLink } from 'components/HomeLink';
import * as layoutStyles from 'components/Layout';
import Loadable from 'components/Loadable';
import { ErrorPage } from 'components/Page';
import { Icon, Button, List } from 'components/semantic';
import useSidebar from 'components/use-sidebar';
import { useApps } from 'App/Apps';
import NotFound from 'App/Others/NotFound';
import { AppStatus } from 'App/types';
import { useUser } from 'App/User';
import { Provider as ClassListProvider } from './ClassList';
import { useCurrentApp, usePushConfigs, Provider as CurrentAppProvider } from './CurrentApp';
import { Provider as DomainBindingProvider } from './DomainBindings';
import { RuntimesProvider } from './Engine/LeanDB';
import { Provider as EngineHooksProvider } from './EngineHooks';
import { Provider as EngineGroupsProvider } from './Groups';
import styles from './index.module.scss';
import AppMenu from './Menu';
import TDSMenu from './Menu/TDS';
import { Provider as MultiplayerServerGroupProvider } from './MultiplayerServerGroup';
import { Provider as FlagsProvider } from './use-flags';

const Overview = Loadable(() => import('./Overview'));
const Storage = Loadable(() => import('./Storage'));
const Auth = Loadable(() => import('./Storage/User'));
const Friendship = Loadable(() => import('./Storage/Friendship'));
const Engine = Loadable(() => import('./Engine'));
const ClientEngine = Loadable(() => import('./Play/ClientEngine'));
const Multiplayer = Loadable(() => import('./Play/Multiplayer'));
const Leaderboard = Loadable(() => import('./Play/Leaderboard'));
const GameSave = Loadable(() => import('./Play/GameSave'));
const Settings = Loadable(() => import('./Settings'));
const IM = Loadable(() => import('./IM'));
const Push = Loadable(() => import('./Push'));
const SMS = Loadable(() => import('./SMS'));
const RTC = Loadable(() => import('./RTC'));
const TDSDomains = Loadable(() => import('./Settings/Domains/TDSDomains'));

export const useAppId = () => {
  return useParams<{ appId: string }>().appId;
};
export { useCurrentApp, usePushConfigs };

const AppTitle = () => {
  const [currentApp] = useCurrentApp();
  if (currentApp) {
    return (
      <Helmet
        titleTemplate={`%s · ${currentApp.appName}`}
        defaultTitle={`${currentApp.appName} · LeanCloud`}
      />
    );
  }
  return null;
};

const TDSContent = () => {
  const [app, { loading }] = useCurrentApp();
  const appId = useAppId();
  const match = useRouteMatch();
  const { t } = useTranslation();

  const content = (() => {
    if (!loading && app === undefined) {
      return (
        <ErrorPage
          icon={<Icon fitted size="huge" color="orange" name="exclamation triangle" />}
          message={t('app.error.notFound')}
          explaination={t('app.error.notFound.noAccess', { appId })}
        />
      );
    }
    if (app) {
      if (app.appStatus === AppStatus.suspendedDueToInsufficientFunds) {
        return (
          <ErrorPage
            icon={<Icon fitted size="huge" color="orange" name="exclamation triangle" />}
            message={t(t('app.error.suspended.noFunds.tds'))}
            explaination={t('app.error.suspended.noFunds.tds.guide')}
          />
        );
      }
      if (app.appStatus === AppStatus.banned) {
        return (
          <ErrorPage
            icon={<Icon fitted size="huge" color="orange" name="ban" />}
            message={t('app.error.banned.tds', { appName: app.appName })}
            explaination={t('app.error.banned.tds.guide')}
          />
        );
      }
    }
    return (
      <div className={classNames(styles.TDSContainer, { [styles.loading]: loading })}>
        <TDSMenu />
        <div className={styles.TDScontent}>
          <Switch>
            <Route path={`${match.path}/storage`} component={Storage} />
            <Route path={`${match.path}/auth`} component={Auth} />
            <Route path={`${match.path}/friendship`} component={Friendship} />
            <Route path={`${match.path}/engine`} component={Engine} />
            <Route path={`${match.path}/im`} component={IM} />
            <Route path={`${match.path}/rtc`} component={RTC} />
            <Route path={`${match.path}/push`} component={Push} />
            <Route path={`${match.path}/leaderboard`} component={Leaderboard} />
            <Route path={`${match.path}/game-save`} component={GameSave} />
            <Route path={`${match.path}/multiplayer`} component={Multiplayer} />
            <Route path={`${match.path}/client-engine`} component={ClientEngine} />
            {/* No TDSMenu for routes under /embed */}
            <Route path={`${match.path}/embed/domains`} component={TDSDomains} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  })();

  return <div className={layoutStyles.main}>{content}</div>;
};

const Content = () => {
  const [apps] = useApps();
  const [app, { loading }] = useCurrentApp();
  const appId = useAppId();
  const match = useRouteMatch();
  const [withSidebar, { setVisible, alwaysVisible, foldable, folded, toggleFolded }] =
    useSidebar(true);
  const [user] = useUser();
  const { t } = useTranslation();

  const header = (
    <Header
      homeLink={
        foldable && folded ? <HomeLink className={styles.homeLink} content="L" /> : undefined
      }
    >
      {!alwaysVisible && (
        <MenuItem onClick={() => setVisible((v) => !v)}>
          <Icon name="bars" fitted />
        </MenuItem>
      )}
      <RegionSelect />
      <AppSelect />
      <span className="space" />
      <LocaleSelect />
      <Help />
      {user && <User user={user} />}
    </Header>
  );

  const content = (() => {
    if (apps && !loading && app === undefined) {
      return (
        <ErrorPage
          code="404"
          message={t('app.error.notFound')}
          explaination={t('app.error.notFound.noAccess', { appId })}
          operations={
            <Button as={Link} content={t('app.error.backToApps')} primary to="/apps" size="big" />
          }
        />
      );
    }
    if (app) {
      if (app.appStatus === AppStatus.suspendedDueToInsufficientFunds) {
        return (
          <ErrorPage
            icon={<Icon fitted size="huge" color="orange" name="exclamation triangle" />}
            message={t('app.error.suspended', { appName: app.appName })}
            explaination={
              app.isOwner
                ? t('app.error.suspended.noFunds')
                : t('app.error.suspended.noFunds.shared', { owner: app.clientUsername })
            }
            operations={
              <List relaxed>
                {app.isOwner && (
                  <List.Item>
                    <Button
                      as={Link}
                      content={t('app.error.suspended.charge')}
                      primary
                      to="/account/finance"
                      size="big"
                    />
                  </List.Item>
                )}
                <List.Item>
                  <Link to="/apps">{t('app.error.backToApps')}</Link>
                </List.Item>
              </List>
            }
          />
        );
      }
      if (app.appStatus === AppStatus.banned) {
        return (
          <ErrorPage
            icon={<Icon fitted size="huge" color="orange" name="ban" />}
            message={t('app.error.banned', { appName: app.appName })}
            explaination={t('app.error.banned.guide')}
          />
        );
      }
    }

    return (
      // Instead of display a loader, we render the content and hide it via opacity when the app is loading.
      // This can help to make the content available to interact faster.
      <div className={classNames(layoutStyles.content, { [styles.loading]: loading })}>
        <Switch>
          <Redirect push={false} path={match.path} exact strict to={`${match.path}/`} />
          <Route path={`${match.path}/`} exact component={Overview} />
          <Route path={`${match.path}/storage`} component={Storage} />
          <Route path={`${match.path}/auth`} component={Auth} />
          <Route path={`${match.path}/friendship`} component={Friendship} />
          <Route path={`${match.path}/engine`} component={Engine} />
          <Route path={`${match.path}/im`} component={IM} />
          <Route path={`${match.path}/push`} component={Push} />
          <Route path={`${match.path}/sms`} component={SMS} />
          <Route path={`${match.path}/rtc`} component={RTC} />
          <Route path={`${match.path}/play/client-engine`} component={ClientEngine} />
          <Route path={`${match.path}/play/multiplayer`} component={Multiplayer} />
          <Route path={`${match.path}/play/leaderboard`} component={Leaderboard} />
          <Route path={`${match.path}/leaderboard`} component={Leaderboard} />
          <Route path={`${match.path}/play/game-save`} component={GameSave} />
          <Route path={`${match.path}/game-save`} component={GameSave} />
          <Route path={`${match.path}/settings`} component={Settings} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  })();

  return (
    <div className={layoutStyles.main}>
      {withSidebar(<AppMenu folded={folded} foldable={foldable} toggleFolded={toggleFolded} />)(
        <>
          {header}
          {content}
        </>
      )}
    </div>
  );
};

export default function Application() {
  const appId = useAppId();
  return (
    <Compose
      components={[
        [CurrentAppProvider, { appId }],
        [FlagsProvider, { appId }],
        ClassListProvider,
        DomainBindingProvider,
        EngineGroupsProvider,
        EngineHooksProvider,
        RuntimesProvider,
        MultiplayerServerGroupProvider,
      ]}
    >
      <AppTitle />
      {TDS ? <TDSContent /> : <Content />}
    </Compose>
  );
}
