import { FC } from 'react';
import styles from './index.module.scss';

const ChartContainer: FC<{ title: JSX.Element | string }> = ({ title, children }) => {
  return (
    <div className={styles.chartContainer}>
      {title && <h4>{title}</h4>}
      {children}
    </div>
  );
};

export default ChartContainer;
