import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import styles from './index.module.scss';

export interface HomeLinkProps {
  transparent?: boolean;
  content?: React.ReactNode;
  className?: string;
  onClick?: LinkProps['onClick'];
}

export const HomeLink = ({
  transparent,
  className,
  content = 'LeanCloud',
  onClick,
}: HomeLinkProps) => {
  return (
    <Link
      to={'/apps'}
      className={classNames(
        'font-logo',
        styles.logo,
        {
          [styles.transparent]: transparent,
        },
        className
      )}
      onClick={onClick}
    >
      {content}
    </Link>
  );
};
