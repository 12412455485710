import React, { FC, useState, useEffect, createContext, useContext } from 'react';
import { Moment } from 'moment';
import { formatTime, TimeFormat } from 'utils';

interface Props {
  value: Date | Moment | string;
  format?: 'FROM_NOW' | 'TO_NOW';
}

const Context = createContext({ now: new Date() });

export const Provider: FC<{ interval?: number }> = ({ interval = 1000 * 60, children }) => {
  const [visible, setVisible] = useState(document.visibilityState === 'visible');
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const visibilitychange = () => {
      setVisible(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', visibilitychange);
    return () => {
      document.removeEventListener('visibilitychange', visibilitychange);
    };
  }, []);

  useEffect(() => {
    if (!visible) {
      return;
    }
    setNow(new Date());
    const timer = setInterval(() => {
      setNow(new Date());
    }, interval);
    return () => {
      clearInterval(timer);
    };
  }, [visible, interval]);

  return <Context.Provider value={{ now }}>{children}</Context.Provider>;
};

export const useRelativeTime = ({ value, format = 'FROM_NOW' }: Props) => {
  const { now } = useContext(Context);
  const time = formatTime(value, TimeFormat[format]);
  return [time, { now }] as const;
};

const RelativeTime: FC<Props> = (props) => {
  const [time] = useRelativeTime(props);
  return <>{time}</>;
};

export default RelativeTime;
