import React from 'react';
import { DateRange as RDateRange, DateRangeProps } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import 'react-date-range/dist/styles.css';
import { appTheme } from 'config/index';
import en from 'date-fns/locale/en-US';
import zh from 'date-fns/locale/zh-CN';
import { Locale } from 'i18n';
import styles from './index.module.scss';

const localeMap: {
  [key in Locale]: DateRangeProps['locale'];
} = {
  zh,
  en,
};

interface Props extends Omit<DateRangeProps, 'locale'> {
  className?: string;
  showToday?: boolean;
}

const rangeColors = [appTheme.primary];
const DateRange: React.FunctionComponent<Props> = ({ className, showToday = true, ...rest }) => {
  const { i18n } = useTranslation();
  return (
    <div className={classNames(styles.container, className, { [styles.showToday]: showToday })}>
      <RDateRange {...rest} locale={localeMap[i18n.language]} rangeColors={rangeColors} />
    </div>
  );
};

export { DateRange };
