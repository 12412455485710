import { useMemo, useCallback } from 'react';
import { identity } from 'lodash';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import {
  formatBytes,
  formatNumber,
  formatTime,
  numberToStr,
  numberToPrecnetage,
  TimeFormat,
} from 'utils';
import { serverUtcOffset } from 'config';
import { LabelFormatter, TooltipFormatter } from 'recharts';
import { TimeSerialData, TimeSerialDataViewProps } from '.';
import styles from '../index.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Formatter<T extends Array<any>> = (...v: T) => React.ReactNode;
export interface Formatters {
  yAxisTick?: Formatter<[number]>;
  xAxisTick?: Formatter<[Moment, number?]>;
  yAxisDisplay?: Formatter<[number]>;
  xAxisDisplay?: Formatter<[Moment, number?]>;
}

export const datetimeFormats: Formatters = {
  xAxisTick: (date, utcOffset) => formatTime(date, 'MM-DD HH:mm', utcOffset),
  xAxisDisplay: (date, utcOffset) => formatTime(date, TimeFormat.DISPLAY_DATETIME_FULL, utcOffset),
};
export const dateFormats: Formatters = {
  xAxisTick: (date, utcOffset) => formatTime(date, 'MM-DD', utcOffset),
  xAxisDisplay: (date, utcOffset) => formatTime(date, TimeFormat.DISPLAY_FULL, utcOffset),
};
export const numberForamtters: Formatters = {
  yAxisTick: numberToStr,
  yAxisDisplay: formatNumber,
};
export const identityFormatters = {
  yAxisTick: identity,
  yAxisDisplay: identity,
};
export const bytesFormatters = {
  yAxisTick: _.bind(formatBytes, null, _, 2),
  yAxisDisplay: formatBytes,
};
const durationFormatter = (num: number) => `${formatNumber(num)} ms`;
export const durationFormatters: Formatters = {
  yAxisTick: durationFormatter,
  yAxisDisplay: durationFormatter,
};

export const percentFormatters: Formatters = {
  yAxisTick: _.bind(numberToPrecnetage, null, _, 0, 2),
  yAxisDisplay: _.bind(numberToPrecnetage, null, _, 0, 2),
};

export const useIsDailyData = (data: TimeSerialData[]) =>
  useMemo(() => data[0] && data[1] && moment(data[1][0]).diff(data[0][0]) % 86400000 === 0, [data]);

export const useDefaultFormatters = (daily: boolean) =>
  useMemo(
    () => ({
      ...(daily ? dateFormats : datetimeFormats),
      ...numberForamtters,
    }),
    [daily]
  );

const empty = {};

export const useFormatters = ({
  data,
  formatters = empty,
  daily,
  omitDataKey,
  dualYAxis,
  names,
  seriesLength,
}: TimeSerialDataViewProps & { seriesLength: number }) => {
  const inferredDaily = useIsDailyData(data);
  const isDaily = daily === undefined ? inferredDaily : daily;
  const defaultFormatters = useDefaultFormatters(isDaily);
  const utcOffset = isDaily ? serverUtcOffset : undefined;

  const { xAxisTick, yAxisTick, xAxisDisplay, yAxisDisplay } = useMemo(() => {
    return {
      ...defaultFormatters,
      ...formatters,
    };
  }, [defaultFormatters, formatters]);

  const xTickFormatter = useMemo(
    () =>
      xAxisTick
        ? (value: string) => {
            const date = moment(value);
            return xAxisTick(date, utcOffset);
          }
        : undefined,
    [utcOffset, xAxisTick]
  );

  const tooltipLabelFormatter: LabelFormatter | undefined = useMemo(
    () =>
      xAxisDisplay
        ? (value) => {
            const date = moment(value);
            return xAxisDisplay(date, utcOffset);
          }
        : undefined,
    [utcOffset, xAxisDisplay]
  );

  const relatedNames = useMemo(() => {
    if (!names || !dualYAxis?.relatedkeys) {
      return [];
    }
    return dualYAxis.relatedkeys.map((item) => names[item]);
  }, [dualYAxis?.relatedkeys, names]);

  const tooltipFormatter: TooltipFormatter = useCallback(
    (value, name) => {
      const isDualData = relatedNames.includes(name);
      const yAxisFormat = isDualData ? dualYAxis?.formatters?.yAxisDisplay : yAxisDisplay;
      const displayValue = yAxisFormat && _.isNumber(value) ? yAxisFormat(value) : value;
      if (!omitDataKey || seriesLength > 1) {
        return displayValue;
      } else {
        const valueNode = <span className={styles.largeValue}>{displayValue}</span>;
        return [valueNode, null];
      }
    },
    [dualYAxis?.formatters?.yAxisDisplay, relatedNames, omitDataKey, seriesLength, yAxisDisplay]
  );

  return [
    {
      xAxisTick,
      yAxisTick,
      xAxisDisplay,
      yAxisDisplay,
      xTickFormatter,
      tooltipLabelFormatter,
      tooltipFormatter,
    },
  ];
};
