import React, { useMemo, useCallback } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LegendValueFormatter,
  Bar,
  BarProps,
} from 'recharts';
import { DEFAULT_COLORS } from '..';
import styles from '../index.module.scss';
import { TimeSerialDataBarViewProps, X_DATA_KEY, useFormatters } from '.';
import useToggleableLegend from './use-toggleable-legend';
import useZoom from './use-zoom';
import { useNames, useChartData, useDualYAxis } from './utils';

const yAxisId = 1;

const emptyNames: {
  [key: string]: string;
} = {};
const minPointSize = 1;
const defaultBarProps: Partial<BarProps> = {
  isAnimationActive: true,
  animationDuration: 200,
};
const BarView = (props: TimeSerialDataBarViewProps) => {
  const {
    data,
    unit,
    names: namesConfig = emptyNames,
    colors,
    height,
    syncId,
    omitDataKey = true,
    stackId,
    maxBarSize,
    dualYAxis,
  } = props;

  const names = useNames(namesConfig, data);
  const unitWithSpace = unit ? ` ${unit}` : unit;
  const chartData = useChartData(data);

  const { selectedData, onMouseDown, onMouseMove, onMouseUp, referenceArea, resetButton } =
    useZoom(chartData);
  const { inactiveLines, getInactiveProps, onLegendClick } = useToggleableLegend(data, colors);
  const seriesPropsList = useMemo(() => {
    if (data && data[0]) {
      return Object.keys(data[0][1]).map((key, index) => {
        const displayName = names[key] || key;
        return {
          name: displayName,
          ...getInactiveProps(key, index),
        };
      });
    }
    return [];
  }, [data, getInactiveProps, names]);

  const [{ yAxisTick, xTickFormatter, tooltipLabelFormatter, tooltipFormatter }] = useFormatters({
    ...props,
    omitDataKey,
    names,
    seriesLength: seriesPropsList.length,
  });

  const [rightYAxis, { getSerieYAxisId }] = useDualYAxis({
    tickFormatter: yAxisTick,
    ...dualYAxis,
  });

  const legendFormatter: LegendValueFormatter = useCallback(
    (value, entry) => {
      // @ts-ignore
      const disable = entry && inactiveLines[entry.dataKey as string];
      return <span className={disable ? 'disabled' : ''}>{value}</span>;
    },
    [inactiveLines]
  );

  return (
    <>
      <ResponsiveContainer debounce={1} height={height} className={styles.chart}>
        <BarChart
          data={selectedData}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          syncId={syncId}
          maxBarSize={maxBarSize}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={X_DATA_KEY} tickFormatter={xTickFormatter} />
          <YAxis yAxisId={yAxisId} unit={unitWithSpace} tickFormatter={yAxisTick} />
          {rightYAxis}
          <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter} />
          {(!omitDataKey || seriesPropsList.length > 1) && (
            <Legend
              formatter={legendFormatter}
              verticalAlign="bottom"
              iconSize={18}
              onClick={onLegendClick}
            />
          )}
          {seriesPropsList.map((barProps) => (
            <Bar
              {...defaultBarProps}
              {...barProps}
              key={barProps.dataKey}
              unit={unitWithSpace}
              yAxisId={getSerieYAxisId(barProps.dataKey, yAxisId)}
              stackId={stackId}
              minPointSize={minPointSize}
            />
          ))}
          {referenceArea}
        </BarChart>
      </ResponsiveContainer>
      {resetButton}
    </>
  );
};

const NO_MARGIN = { top: 0, right: 0, bottom: 0, left: 0 };

const TinyBarView = (props: TimeSerialDataBarViewProps) => {
  const {
    data,
    names: namesConfig = emptyNames,
    colors = DEFAULT_COLORS,
    height,
    syncId,
    unit,
  } = props;

  const names = useNames(namesConfig, data);
  const chartData = useChartData(data);
  const unitWithSpace = unit ? ` ${unit}` : unit;

  const seriesPropsList = useMemo(() => {
    if (data && data[0]) {
      return Object.keys(data[0][1]).map((key, index) => {
        const displayName = names[key] || key;
        return {
          name: displayName,
          dataKey: key,
          fill: colors[index],
        };
      });
    }
    return [];
  }, [data, names, colors]);

  const [{ tooltipLabelFormatter, tooltipFormatter }] = useFormatters({
    ...props,
    names,
    omitDataKey: true,
    seriesLength: seriesPropsList.length,
  });

  return (
    <ResponsiveContainer debounce={1} height={height} className={styles.chart}>
      <BarChart
        data={chartData}
        syncId={syncId}
        margin={NO_MARGIN}
        className={`${styles.chart} ${styles.tinyBar}`}
      >
        <XAxis dataKey={X_DATA_KEY} hide />
        <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter} />
        {seriesPropsList.map((barProps) => {
          return (
            <Bar
              {...defaultBarProps}
              {...barProps}
              unit={unitWithSpace}
              key={barProps.dataKey}
              minPointSize={minPointSize}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarView;
export { TinyBarView };
