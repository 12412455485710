import { isAPSG, isCN, isCNE1, isCNN1, isIntl, isTDS1, TDS, isCNN1Test, isUS } from 'env';

// example:
// export const someFeatureEnabled = Date.now() >= 1577836800000;

export const isMultiNodeRegion = false;

export const authModulesHoisted = true;
export const friendshipModulesHoisted = TDS;
export const leaderboardModuleHoisted = TDS;
export const gameSaveModuleHoisted = TDS;
export const multiplayerModuleHoisted = TDS;
export const clientEngineModuleHoisted = TDS;

export const createAppEnabled = !isCNE1;

// RTC
export const RTCModuleEnabled = TDS;

// Storage
export const logTableEnabled = true;
export const APIAccessLogEnabled = true;
export const domainSettingsEnabled = !isIntl; // for File
export const uploadPolicyEnabled = isCN;
export const refreshFileCDNEnabled = isCN;
export const classBindingEnabled = !TDS;
export const emailEnabled = !TDS;
export const hideInternalClasses = TDS && !localStorage.getItem('Aparecium');
export const dataLakeEnabled = true;
export const fileCensorEnabled = isCN;
export const dataExportEnabled = !TDS;
export const appSearchEnabled = true;

// TDS 内嵌在 iframe 里，无法做到真正的全屏，先隐藏此功能
export const dataViewFullWindowToggle = !isTDS1;

// Storage
// https://xindong.atlassian.net/wiki/spaces/TDS/pages/967128326
export const hideLiveQuery = TDS;
export const LiveQueryAllowedAppIds = [
  'xnou5y2iwwn59xt45i',
  'lwbo7he5yqnf3hzesj',
  'fy3gjdacc1keyumygc',
  'lpyG2vRqA9rQpgqdam', // RND
];
export const hideDataLake = TDS;
export const DataLakeAllowedAppIds = [
  'fsgwfuraclruo4h7ge',
  'Z6y2nKRZ6vzP4BRU5U',
  'llnjmglkbbxiwt85ef',
  'swlJ2EcNDqZr4ODv1J',
  'xnou5y2iwwn59xt45i',
  'ckxg8cfpybyumhhnuj',
  'wsrB3Q5BGkizY7xyvC',
  'zbzTZiID9Nv94LWZio',
  'kijdgvphy3xsh3swaj',
  'wfesughupswdhdrsmf',
  'sK1tHXWob582VVEbbY',
  '8CdD2zgJERKusymbhk',
  'r5og6twvkezukbcixb',
  'FwFdCIr6u71WQDQwQN',
  'nxrbooubsmqa1b1c0z',
  '66APkVbfleUKVOQsqr',
  'VBvR5IDylV2gVzvSKP',
  'rcop9bqwkistq0yvat',
  'wcwbjraufnjtfwbzlu',
  'b9dylpoon9tn2kd4vn',
  'n0bnxy5dfvuq0opmnd',
  'xexrxlev9wsqybo0dg',
  'JdXmhMxKsSiLTQ3vHk',
  'lpyG2vRqA9rQpgqdam', // RND
];
export const hideSearch = TDS;
export const SearchAllowedAppIds = [
  'lwbo7he5yqnf3hzesj',
  'lpyG2vRqA9rQpgqdam', // RND
];

// Engine
export const primaryGroupManageable = false;
export const gatewayMetricEnabled = TDS;
export const newESEnabled = isUS || isAPSG;

// DomainBinding
export const domainBindingRequired = isCN && !isTDS1;

export const platformDomainBindingEnabled = !isAPSG;
export const platformDomainAllowAdd = !isAPSG;

export const engineDomainBindingEnabled = true;
export const engineDomainUnified = isCN;

export const fileDomainBindingEnabled = isCN;

export const billBoardBindingEnabled = isTDS1;
export const sharedBillBoardDomainEnabled = isAPSG;
export const sharedEngineDomainEnabled =
  (!isTDS1 && !isAPSG) || (isAPSG && localStorage.getItem('Aparecium'));

export const hybridPushFCMEnabled = !isCN || isCNN1Test;

export const hybridPushCallbackConfigEnabled = isTDS1 || isCNN1Test;

export const SMSEnabled = !TDS;

export const isAliyunMysql = isAPSG || isUS;

export const MySqlMemoryBase = isAPSG || isUS ? 1024 : 1000;

export const gamingScenarioEnabled = TDS;

// 短信每日消费限制
export const SMSDailyExpenseLimit = !isCNE1;
// 国际短信
export const i18nSMSEnabled = isCN;
// 只有中国节点可以设置营销
export const SMSMarketing = isCN;
export const SMSSignValidation = isCN;
// 国际节点不需要短信签名
export const SMSSignRequired = true;
// 短信资质文件
export const SMSQualificationRequired = isCN;

export const twilioEnabled = isAPSG || isUS;

export const MultiplayerServerEnabled = false;

export const SMSBalanceLimited = !isTDS1;

export const IDVerifyEnabled = isCN;
export const ICPBeianEnabled = isCN;
export const ICPBeianConfigEnabled = isCNN1;

export const InvoiceAvailable = isCN;

export const DedicatedIPEnabled = isCNN1 || isTDS1;

// 只有国内节点需要实名认证
export const verifiedClientEnabled = isCN;
export const internalUserPrivilegeEnabled = isCN;
export const phoneNumberRequired = isCN;
export const internalUserGiftEnbaled = isCNN1;

export const printInvoiceEnabled = isIntl;

export const friendExtraConfigsEnabled = isTDS1 || isAPSG;

// billing-center
export const newBillingCenterEnabled = !isTDS1;

// signup
export const signUpCNTermsEnabled = isCN;
export const signUpPhoneRequired = isCN;

export const checkAppOwnerByFlag = isAPSG || isTDS1;

export const storageCsfrTokenEnabled = isTDS1 || isAPSG;
